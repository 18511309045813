/**
 * see TestFormData.java
 */
export class TestFormData {

  gen: string[];            // gender code

  age: string[] = [''];

  lang: string[];

  country: string[];

  worklev: string[];        // level of work

  workexp: string[];        // work experience, career

  efield: string[];         // Educational field

  elev: string[];           // Educational level

  dis: string;              // Disability
  disability: string[];

  esight: string;           // Eye sight
  eyesight: string[];

  maritalstatus: string[];  // Marital status

}
