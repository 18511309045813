import {Directive, HostBinding, Input, TemplateRef, ViewContainerRef} from '@angular/core'
import {InvalidmessageDirective} from "./invalid.message.directive";

@Directive({
  selector: '[invalidType]'
})

export class InvalidTypeDirective {

  // tslint:disable-next-line:no-input-rename
  @Input('invalidType') type: string;
  hasView = false;

  @HostBinding('class')
  className = 'alert-danger';

  //see https://alligator.io/angular/using-renderer2/
  constructor(private invalidMessage: InvalidmessageDirective, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {
  }

  ngOnInit() {
    this.invalidMessage.controlValue$.subscribe(() => {
      this.setVisible();
    });

  }

  setVisible() {
    if (this.invalidMessage.match(this.type)) {
      if (!this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      }
    } else {
      if (this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      }
    }
  }

}
