import {HttpClientModule} from '@angular/common/http';
import {BrowserModule, DOCUMENT, Title} from "@angular/platform-browser";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, ApplicationRef, Inject, Injector, NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BusinessApiResource} from "./api/api.business.service";
import {BusinessComponent} from "./business.component";
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {BatteryListComponent} from "./comp/battery/battery.list";
import {BatteryEditComponent} from "./comp/battery/battery.edit";
import {BatteryDetailsComponent} from "./comp/battery/battery.details";
import {BatteryCreateComponent} from "./comp/battery/battery.create";
import {CandidateModalComponent} from "./comp/candidate/candidate.modal.component";
import {ModalService} from "../shared/modal/modal.service";
import {AdminApiResource} from "../admin/api/admin.api.service";
import {AccordionModule, BsModalRef, PopoverModule, TabsModule} from 'ngx-bootstrap';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BatteryTestsAdd} from "./comp/battery/battery.tests.add";
import {BatteryCandidatesComponent} from "./comp/battery/battery.candidates";
import {BusinessRoutes} from "./business.routes";
import {RouterModule} from "@angular/router";
import {BusinessMenuComponent} from "./comp/menu/business.menu";
import {WindowRef} from "../shared/window.ref";
import {ChemAPIJs} from "../shared/service/native/chem.api.js.js";
import {InviteDetailsComponent} from "./comp/invite/invite.details";
import {InvitesPageComponent} from "./comp/invite/invites.page";
import {BatteriesPageComponent} from "./comp/battery/batteries.page";
import {InviteListComponent} from "./comp/invite/invite.list";
import {PrettyJsonModule} from "angular2-prettyjson";
import {CompanySelect} from "./comp/service/company.select";
import {CompanyService} from "./comp/service/company.service";
import {ServiceLocator} from "../shared/service/native/service.locator";
import {DebugModalComponent} from "./comp/debug/debug.modal.component";
import {DebugViewComponent} from "./comp/debug/debug.view";
import {InviteDetailsModalComponent} from "./comp/invite/invitedetails.modal.component";
import {CustomFormsModule} from 'ng4-validators'
import {LaddaModule} from 'angular2-ladda';
import {BatteryScoresComponent} from "./comp/battery/battery.scores";
import {BatteryDetailsModalComponent} from "./comp/battery/battery.details.modal.component";
import {BatteryScoresModalComponent} from "./comp/battery/battery.scores.modal.component";
import {BatteryAddModalComponent} from "./comp/battery/battery.add.modal.component";
import {BatteryReports} from "./comp/battery/battery.reports";
import {DownloadService} from "./service/download.service";
import {ServiceList} from "../shared/service/native/service.list.injectable";
import {NativeIncludeService} from "../shared/service/native/native.include.service";
import {BatteryTestBadgesComponent} from "./comp/battery/battery.test.badges";
import {SessionService} from "../shared/service/session/session.service";
import {SessionFactory} from "../shared/service/session/session.factory";
import {ChemApiFactory} from "../shared/service/native/chem.api.factory";
import {NgSelectModule} from '@ng-select/ng-select';
import {InviteFilterComponent} from "./comp/invite/invite.filter";
import {Angular2FontawesomeModule} from 'angular2-fontawesome/angular2-fontawesome'
import {CompanyProfileComponent} from "./comp/profile/company.profile";
import {ModalConfirmComponent} from "../shared/modal/modal.confirm";
import {SearchPageComponent} from "./comp/search/search.page";
import {SearchSliderComponent} from "./comp/search/search.slider";
import {NouisliderModule} from 'ng2-nouislider';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {SearchResultItemComponent} from "./comp/search/search.result.item";
import {PapaParseModule} from 'ngx-papaparse';
import {SearchCandidateDetailsModal} from "./comp/search/search.candidate.details.modal";
import {SearchCriteriaIcon} from "./comp/search/search.criteria.icon";
import {GroupsPageComponent} from "./comp/group/groups.page";
import {GroupListComponent} from "./comp/group/group.list";
import {GroupModalComponent} from "./comp/group/group.modal.component";
import {GroupCandidatesComponent} from "./comp/group/group.candidates";
import {GroupCandidateAdd} from "./comp/group/group.candidate.add";
import {SearchResultAddProject} from "./comp/search/search.result.add.project";
import {SearchFavoritesComponent} from "./comp/search/search.favorites";
import {SharedApiResource} from "../shared/api/api.shared.service";
import {AppConfig} from "../admin/app.config";
import {IntercomModule} from 'ng-intercom';
import {CompanyApplicationComponent} from "./comp/application/company.application";
import {RoutesCanAccess} from "./business.routes.can.access";
import {BusinessLoginService} from "./service/business.login.service";
import {BatteryEmbedButtonModalComponent} from "./comp/battery/battery.embed.button.modal.component";
import {SharedModule} from "../shared/SharedModule";
import {SelectProvider} from "../shared/service/select.provider";
import {BusinessLoginComponent} from "./comp/login/business.login.component";
import {CandidateInviteGraph} from "./comp/invite/candidate.invite.graph";
import {BatteryTestBadgeComponent} from "./comp/battery/battery.test.badge";
import {TalentPoolListComponent} from "./comp/talent/talent.pool.list";
import {SearchCriteriaComponent} from "./comp/search/search.criteria.component";
import {TalentPageComponent} from "./comp/talent/talent.pool.page";
import {TalentPoolCandidatesComponent} from "./comp/talent/talent.pool.candidates";
import {SearchResultsComponent} from "./comp/search/search.results.component";
import {TalentPoolModalEditComponent} from "./comp/talent/talent.pool.modal.edit";
import {TalentPoolScoresComponent} from "./comp/talent/talent.pool.scores";
import {TalentPoolScoresModalComponent} from "./comp/talent/talent.pool.scores.modal.component";
import {DataExportComponent} from './comp/dataexport/data.export';
import {BatteryRenameModalComponent} from "./comp/battery/battery.rename.modal.component";
import {AgGridModule} from 'ag-grid-angular';
import {SearchResultGridComponent} from "./comp/search/search.result.grid";
import {CellButtonComponent} from "../shared/comp/ag-grid/grid.cellbutton.component";
import {CellLinkComponent} from "../shared/comp/ag-grid/grid.celllink.component";
import {CellProgressbarComponent} from "../shared/comp/ag-grid/grid.progressbar.component";
import {SearchInviteCandidatesModal} from "./comp/search/search.invite.candidates.modal";
import {CellSearchResultIconsComponent} from "../shared/comp/ag-grid/grid.cell.searchresult.icons.component";
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';

@NgModule({
  declarations: [
    BusinessComponent,
    CompanySelect,
    BusinessComponent,
    BusinessLoginComponent,
    BusinessMenuComponent,
    BatteryListComponent,
    BatteryCreateComponent,
    BatteryEditComponent,
    BatteryDetailsComponent,
    BatteryCandidatesComponent,
    BatteryTestsAdd,
    BatteryReports,
    BatteryScoresComponent,
    BatteryScoresModalComponent,
    BatteryEmbedButtonModalComponent,
    BatteryTestBadgesComponent,
    BatteryTestBadgeComponent,
    BatteriesPageComponent,
    BatteryDetailsModalComponent,
    BatteryRenameModalComponent,
    InvitesPageComponent,
    InviteListComponent,
    InviteFilterComponent,
    InviteDetailsComponent,
    InviteDetailsModalComponent,
    CandidateInviteGraph,
    BatteryAddModalComponent,
    CandidateModalComponent,
    CompanyProfileComponent,
    CompanyApplicationComponent,
    SearchCriteriaComponent,
    SearchResultsComponent,
    SearchPageComponent,
    SearchResultItemComponent,
    SearchResultGridComponent,
    SearchInviteCandidatesModal,
    CellProgressbarComponent,
    CellSearchResultIconsComponent,
    SearchSliderComponent,
    SearchCriteriaIcon,
    SearchResultAddProject,
    SearchFavoritesComponent,
    DebugViewComponent,
    DebugModalComponent,
    GroupsPageComponent,
    GroupListComponent,
    GroupModalComponent,
    GroupCandidatesComponent,
    GroupCandidateAdd,
    TalentPageComponent,
    TalentPoolListComponent,
    TalentPoolCandidatesComponent,
    TalentPoolModalEditComponent,
    TalentPoolScoresComponent,
    TalentPoolScoresModalComponent,
    DataExportComponent
  ],
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(
      BusinessRoutes,
      {
        enableTracing: false,
        useHash: true
      }
    ),
    ModalModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    CustomFormsModule,
    NgxDatatableModule,
    PrettyJsonModule,
    NgSelectModule,
    LaddaModule.forRoot({
      style: "expand-right",
      spinnerSize: 30,
      spinnerColor: "black",
      spinnerLines: 12
    }),
    AgGridModule.withComponents([CellButtonComponent, CellLinkComponent, CellProgressbarComponent, CellSearchResultIconsComponent]),
    PapaParseModule,
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    Angular2FontawesomeModule,
    NouisliderModule,
    AccordionModule.forRoot(),
    IntercomModule.forRoot({
      appId: AppConfig.intercom_app_id,
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),
    GooglePlaceModule

  ],
  entryComponents: [
    BusinessComponent,
    DataExportComponent,
    CandidateModalComponent,
    BatteryDetailsModalComponent,
    BatteryRenameModalComponent,
    BatteryScoresModalComponent,
    BatteryEmbedButtonModalComponent,
    ModalConfirmComponent,
    BatteryCreateComponent,
    BatteryListComponent,
    InviteListComponent,
    InviteDetailsModalComponent,
    DebugModalComponent,
    SearchCandidateDetailsModal,
    SearchInviteCandidatesModal,
    BatteryAddModalComponent,
    DebugModalComponent,
    GroupModalComponent,
    TalentPoolModalEditComponent,
    TalentPoolScoresModalComponent
  ],
  providers: [
    SessionService, {
      provide: APP_INITIALIZER,
      useFactory: SessionFactory,
      deps: [SessionService, CompanyService, ChemAPIJs],
      multi: true
    },
    BusinessLoginService,
    ChemAPIJs, {
      provide: APP_INITIALIZER,
      useFactory: ChemApiFactory,
      deps: [ChemAPIJs],
      multi: true
    },
    BusinessApiResource,
    SharedApiResource,
    AdminApiResource, ///TODO to remove
    BrowserModule,
    ModalService,
    DownloadService,
    BsModalRef,
    WindowRef,
    CompanyService,
    ServiceLocator,
    NativeIncludeService,
    HttpClientModule,
    RoutesCanAccess,
    SelectProvider,
    Title
  ]
})

export class BusinessModule {

  private browser_document;

  ngDoBootstrap(appRef: ApplicationRef) {
    if (this.browser_document.getElementsByTagName('app-root').length > 0) {
      appRef.bootstrap(BusinessComponent);
    }
    if (this.browser_document.getElementsByTagName('data-export').length > 0) {
      appRef.bootstrap(DataExportComponent);
    }
  }

  constructor(@Inject(DOCUMENT) private document: any) {

    this.browser_document = document;

    AppConfig.appCode = AppConfig.appCodes.BUS;

    ServiceLocator.injector = Injector.create(
      Object.keys(ServiceList).map(key => ({
        provide: ServiceList[key].provide,
        useClass: ServiceList[key].provide,
        deps: ServiceList[key].deps
      }))
    );
  }

}
