/**
 * See this:
 * https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
 */
export class GenericList<T> extends Array<T> {

  hasItems() {
    return (this.length > 0);
  }

  clear() {
    this.length = 0;
  }

  clone() {
    let arr = new GenericList<T>();
    this.forEach(t => {
      arr.add(t);
    });
    return arr;
  }

  set(items: T[]) {
    this.splice(0, this.length);
    Array.prototype.push.apply(this, items);
    return this;
  }

  setTyped(items: T[], TCreator: { new(any): T; }) {
    this.splice(0, this.length);
    for (let i = 0; i < items.length; i++) {
      this.add(new TCreator(items[i]));
    }
  }

  add(item: T) {
    this.push(item);
  }

  remove(item: T) {
    let index = this.indexOf(item);
    if (index !== -1) {
      this.splice(index, 1);
    }
  }

  removeWhereField(fieldName: string, value: any) {
    let filtered = this.filter(f => f[fieldName] == value);
    if (filtered) {
      this.remove(filtered[0]);
    }
  }

  constructor() {
    super();
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, GenericList.prototype);
  }

}



