export class SessionInfo {

  public CHEM_TRANS: any = {};  // JAVASCRIPT strings, see APIInfoJavascript.java
  public TRANS: any = {};       // translation strings, see APIInfoJavascript.java
  public CHEM_API: any;         // chem-api-v1.js
  public SURL: any;             // appengine url
  public LURL: any;             // website url
  public SA: any;
  public user: any;             // logged in user, empty if not logged in, See MemberJson.java
  public native_utils: any;     // native javascript utilities, see assets folder
  public isBrowserSupported: boolean = true;
  public hasPermission: boolean = false; // dashboard permissions, see java Permissions.PERMISSION_BUSINESS_DASHBOARD
  public userPrefs: Array<string> = new Array<string>(); // set from linked company on backend
  public lang: string;          // session language code, see Types.java->LANG

  public get loggedIn(): boolean {
    let loggedIn: boolean = ((this.user && this.user.name) ? true : false);
    return loggedIn;
  }

  public hasPreference(prefCode: string): boolean {
    if (!this.userPrefs) return false;
    return this.userPrefs.indexOf(prefCode) >= 0;
  }

  public copyFrom(other: SessionInfo) {
    this.CHEM_TRANS = other.CHEM_TRANS;
    this.TRANS = other.TRANS;
    this.SURL = other.SURL;
    this.LURL = other.LURL;
    this.SA = other.SA;
    this.CHEM_API = other.CHEM_API;
    this.isBrowserSupported = other.isBrowserSupported;
    this.hasPermission = other.hasPermission;
    if (other.user) {
      this.user = other.user;
      this.userPrefs = other.user.preferences;
    }
    this.lang = other.lang;
  }

}
