import {ChangeDetectorRef, EventEmitter, Injectable, Injector, Input, NgZone, Output} from '@angular/core';
import {AppConfig} from "../../admin/app.config";
import {BusinessApiResource} from "../api/api.business.service";
import {ModalService} from "../../shared/modal/modal.service";
import {CompanyService} from "./service/company.service";
import {Router} from "@angular/router"
import {SessionService} from "../../shared/service/session/session.service";
import {FormBuilder} from '@angular/forms';
import {WindowRef} from "../../shared/window.ref";
import {BaseBaseComponent} from "../../shared/base/base.base.component";
import {SharedApiResource} from "../../shared/api/api.shared.service";
import {BusinessLoginService} from "../service/business.login.service";
import {Title} from '@angular/platform-browser';

@Injectable()
export class BusinessBaseComponent extends BaseBaseComponent {

  /**
   * Allows for retrieving singletons using `AppModule.injector.get(MyService)`
   * This is good to prevent injecting the service as constructor parameter.
   */
  static injector: Injector;

  @Output() onRowSelect = new EventEmitter();
  @Input() loading: boolean;
  rowsSelected = [];
  config = AppConfig;

  rowSelect({selected}) {
  }

  detectChanges() {
    setTimeout(() => {
      if (!this.ref['destroyed']) {
        this.ref.detectChanges();
      }
    }, 100);
  }

  constructor(
    public api: BusinessApiResource,
    public sharedApi: SharedApiResource,
    public modal: ModalService,
    public cs: CompanyService,
    public router: Router,
    public sess: SessionService,
    public bls: BusinessLoginService,
    public fb: FormBuilder,
    public zone: NgZone,
    public ref: ChangeDetectorRef,
    public winRef: WindowRef,
    public injector: Injector,
    public titleService: Title
  ) {
    super(fb, zone, ref, winRef, injector);
    BusinessBaseComponent.injector = injector;
    this.sess.showSecureAlert = false;
  }

}
