import {Component} from '@angular/core';
import {BusinessBaseComponent} from "../business.base.component";

@Component({
  selector: 'groups-page',
  templateUrl: 'groups.page.html'
})

export class GroupsPageComponent extends BusinessBaseComponent {
  groupSelected: any;

  onGroupSelect($event) {

    this.groupSelected = null;
    setTimeout(() => {
      this.groupSelected = $event;
    }, 1);

  }

}
