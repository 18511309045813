import {Inject, Injectable} from "@angular/core";
import {ApiBase} from "./api.base.service";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Cacheable} from "ngx-cacheable";

@Injectable()
@Inject(HttpClient)
export class SharedApiResource extends ApiBase {

  // see RestList.java and CodeJson.java
  getCodeList(codeList: string): Observable<any> {
    return this.get(this.apiUrl + 'session/list/' + codeList + '/', []);
  }

  @Cacheable()
  getCodeListCached(codeList: string): Observable<any> {
    return this.get(this.apiUrl + 'session/list/' + codeList + '/', []);
  }

  // see RestSmallActions.java, for eg auto-complete for languages
  smallActionPost(smallAction: string, param: string, payload: any): Observable<any> {
    return this.post(this.apiUrl + 'session/action/' + smallAction + '/' + param + '/', payload);
  }

  smallActionGet(smallAction: string, param: string): Observable<any> {
    return this.get(this.apiUrl + 'session/action/' + smallAction + '/' + param + '/', []);
  }

  // see RestContact.java
  submitContactForm(json: any): Observable<any> {
    return this.post(this.apiUrl + 'contact/', json);
  }

  constructor(public http: HttpClient) {
    super(http);
  }

}
