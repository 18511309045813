import {ApiBase} from "./api.base.service";
import {Observable} from "rxjs";

export class ApiTestService extends ApiBase {

  // see RestTestActions.java
  // see ExternalParameter.java
  getTestStatus(testType: string, apiParam: any = null): Observable<any> {
    return this.get(this.apiUrl + "session/test/getstatus/", {type: testType, apiParam: apiParam});
  }

  checkCreateNewVersion(testType: string): Observable<any> {
    return this.get(this.apiUrl + "session/test/newversion/", {type: testType});
  }

  setClearCompanyDefault(testId: number, testType: string): Observable<any> {
    return this.get(this.apiUrl + "session/test/setclearcompanydefault/", {testId: testId, type: testType});
  }

  testAction(action: string, payload: any): Observable<any> {
    return this.get(this.apiUrl + "session/test/" + action + '/', payload);
  }

}
