/**
 * Test status info returned to client
 * See TestChemJson.java
 */

import {GenericList} from "../../../business/model/list.generic.model";
import {MemberTest} from "../../../shared/model/membertest.model";

export class TestChemJson {

  key: string;                // datastore key

  id: number;

  name: string;

  testType: string;

  reportType: string;

  completed: Date;            // date completed

  testCompleted: boolean;

  percentComplete: number;

  //friendly
  testStatus: string;

  versionNumber: number;

  redoAllowed: boolean;

  resumeAllowed: boolean;

  resumeExpired: boolean;

  startOrResumeAllowed: boolean;

  versioningAllowed: boolean;

  dateCreated: Date;

  dateRedoAllowed: Date;

  dateResumeUntil: Date;

  dateUpdated: Date;

  statusCode: string;

  allVersions: GenericList<MemberTest>;

  //TODO type as ExternalParameter.java
  externalParam: any;

  apiParam: string;

  fromOther(other: any) {
    this.id = other.id;
    this.key = other.key;
    this.name = other.name;
    this.testType = other.testType;
    this.reportType = other.reportType;
    this.completed = other.completed;
    this.testCompleted = other.testCompleted;
    this.percentComplete = other.percentComplete;
    this.testStatus = other.testStatus;
    this.versionNumber = other.versionNumber;
    this.redoAllowed = other.redoAllowed;
    this.resumeAllowed = other.resumeAllowed;
    this.resumeExpired = other.resumeExpired;
    this.startOrResumeAllowed = other.startOrResumeAllowed;
    this.versioningAllowed = other.versioningAllowed;
    this.dateCreated = other.dateCreated;
    this.dateRedoAllowed = other.dateRedoAllowed;
    this.dateResumeUntil = other.dateResumeUntil;
    this.dateUpdated = other.dateUpdated;
    this.statusCode = other.statusCode;
    this.externalParam = other.externalParam;
    this.apiParam = other.apiParam;

    if (other.allVersions) {
      this.allVersions = new GenericList<MemberTest>();
      for (let i = 0; i < other.allVersions.length; i++) {
        this.allVersions.add(new MemberTest().fromOther(other.allVersions[i]));
      }
    }

    return this;
  }

  get isCompleted(): boolean {
    return (this.statusCode == 'C');
  }

}
