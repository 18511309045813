import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {Battery} from "../../model/battery.model";
import {GenericList} from "../../model/list.generic.model";
import {BatteryDetailsModalComponent} from "./battery.details.modal.component";
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {BatteryScoresModalComponent} from "./battery.scores.modal.component";
import {Subject} from "rxjs";
import {debounceTime} from 'rxjs/operators';
import {BusinessBaseComponent} from "../business.base.component";
import {BatteryEmbedButtonModalComponent} from "./battery.embed.button.modal.component";
import {Company} from "../../../shared/model/company.model";

@Component({
  selector: 'battery-list',
  templateUrl: 'battery.list.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BatteryListComponent extends BusinessBaseComponent {

  KEY_LOCAL_STORAGE = 'hidden';

  _batteries: GenericList<Battery> = new GenericList<Battery>();
  filteredRows: Array<Battery> = new Array<Battery>();
  batterySelected: Battery;
  filterValue: string = null;
  debounceSubject = new Subject();
  debounceMs: number = 1;
  isShowHideMode: boolean = false;

  @ViewChild('grid') grid: DatatableComponent;

  @Output() onDoRefresh = new EventEmitter();

  @Input() set batteries(val) {
    if (val) {
      this._batteries = val;
      this.isShowHideMode = false;
      this.setHiddenFromLocalStorage();
      this.filterData(false);
    }
  }

  @Input() companySelected: Company;

  refresh() {
    this.onDoRefresh.emit();
  }

  filterData(showHidden: boolean) {
    this.filteredRows = this._batteries.filter(batt => showHidden || !batt.hidden).filter(batt => this.filterValue == null || batt.name.toLowerCase().indexOf(this.filterValue.toLowerCase()) >= 0);
    if (this.rowsSelected[0]) {
      let prevSelectedRows = this._batteries.filter(batt => batt.id == this.rowsSelected[0].id);
      if (prevSelectedRows) {
        this.rowsSelected = prevSelectedRows;
      }
    }
  }

  setHiddenFromLocalStorage() {
    try {
      let ls = JSON.parse(localStorage.getItem(this.KEY_LOCAL_STORAGE));
      let idsToHide: number[] = ls[this.companySelected.companyId] || [];
      this._batteries.forEach(batt => {
        batt.hidden = idsToHide.indexOf(batt.id) >= 0;
      });
    } catch (ex) {
      localStorage.setItem(this.KEY_LOCAL_STORAGE, '{}');
      console.error(ex);
    }
  }

  rowSelect({selected}) {
    if (this.isShowHideMode) return;
    if (!this.batterySelected || this.batterySelected.id != this.rowsSelected[0].id) {
      //selected row changed
      this.batterySelected = this.rowsSelected[0];
      this.onRowSelect.emit(null);
      this.debounceSubject.next();
    }
  }

  batteryDetails(battery: Battery) {
    this.modal.openModal(BatteryDetailsModalComponent, battery, {class: 'modal-lg'}).subscribe(result => {
      battery.name = result.data.name;
    });
  }

  batteryScores(battery: Battery) {
    this.modal.openModal(BatteryScoresModalComponent, battery, {class: 'modal-lg'}).subscribe(result => {
    });
  }

  shareProject(battery: Battery) {
    this.modal.openModal(BatteryEmbedButtonModalComponent, battery, {class: 'modal-lg'}).subscribe(result => {
    });
  }

  newProject() {
    this.router.navigate(['/newproject']);
  }

  onShowHideClick(battery: Battery) {
    battery.hidden = !battery.hidden;
  }

  hideModeClicked() {
    this.isShowHideMode = !this.isShowHideMode;
    this.filterValue = null;
    if (this.isShowHideMode) {
      this.filterData(true);
    } else {
      let idsToHide: number[] = this.filteredRows.filter(b => b.hidden).map(b => b.id);
      let ls = JSON.parse(localStorage.getItem(this.KEY_LOCAL_STORAGE));
      ls[this.companySelected.companyId] = idsToHide;
      localStorage.setItem(this.KEY_LOCAL_STORAGE, JSON.stringify(ls));
      this.filterData(false);
    }
  }

  ngOnInit() {
    this.debounceSubject.pipe(debounceTime(this.debounceMs))
      .subscribe(() => {
        this.onRowSelect.emit(this.batterySelected);
      });
  }

}
