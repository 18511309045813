import {NgModule} from "@angular/core";
import {KeyValuesToListPipe} from "./comp/pipe.keyvalues.to.list";
import {LoadingIndicator} from "./comp/loading.indicator";
import {FormGroupComponent} from "./form/formgroup.component";
import {InputMaskDirective} from "./directive/input.mask.directive";
import {NavigationButtonsComponent} from "./comp/navigation.buttons";
import {CliquidityCheckbox} from "./comp/cliquidity.checkbox";
import {ActionButtons} from "./comp/action.buttons";
import {InvalidTypeDirective} from "./service/validation/invalid.type.directive";
import {InvalidmessageDirective} from "./service/validation/invalid.message.directive";
import {HelpTooltip} from "./comp/help.tooltip";
import {ModalTemplateComponent} from "./modal/modal.template";
import {ModalConfirmComponent} from "./modal/modal.confirm";
import {SearchSelectComponent} from "../business/comp/search/search.select";
import {CliqButtonComponent} from "../vi/comp/cliq.button.component";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {PopoverModule, ProgressbarModule, TabsModule} from "ngx-bootstrap";
import {Angular2FontawesomeModule} from "angular2-fontawesome";
import {IntercomComponent} from "./comp/intercom.component";
import {SafePipe} from "../individual/safe.pipe";
import {AutofocusDirective} from "./directive/auto-focus.directive";
import {VersionControl} from "./comp/VersionControl/version.control.component";
import {VersionApiResource} from "./api/api.version.service";
import {AssessmentService} from "../individual/service/assessment.service";
import {AssessmentApiResource} from "../individual/api/api.assessment.service";
import {TabOrderDirective} from "./directive/tab.index.directive";
import {SearchCandidateDetails} from "../business/comp/search/search.candidate.details";
import {SearchCandidateDetailsModal} from "../business/comp/search/search.candidate.details.modal";
import {TestFinishedComponent} from "./comp/test.finished";
import {TranslationDirective} from "./directive/translation.directive";
import {TranslationService} from "./service/translation.service";
import {WizardComponent} from "./wizard/wizard.component";
import {WizardStepComponent} from "./wizard/wizard-step.component";
import {ValidationComponent} from "./service/validation/validation.component";
import {InvitesService} from "../individual/service/invites.service";
import {TestFinishedAskDeclineComponent} from "./comp/test.finished.ask.decline";
import {InvitesApiResource} from "../individual/api/api.invites.service";
import {FilterPipe} from "./comp/pipe.filter";
import {CellButtonComponent} from "./comp/ag-grid/grid.cellbutton.component";
import {CellLinkComponent} from "./comp/ag-grid/grid.celllink.component";
import {ColumnBuilder} from "./comp/ag-grid/grid.columndef.builder";

@NgModule({
  declarations: [
    ModalTemplateComponent,
    ModalConfirmComponent,
    FormGroupComponent,
    SearchSelectComponent,
    NavigationButtonsComponent,
    KeyValuesToListPipe,
    FilterPipe,
    LoadingIndicator,
    InputMaskDirective,
    AutofocusDirective,
    TranslationDirective,
    CliquidityCheckbox,
    CliqButtonComponent,
    ActionButtons,
    InvalidTypeDirective,
    InvalidmessageDirective,
    HelpTooltip,
    VersionControl,
    IntercomComponent,
    SafePipe,
    TabOrderDirective,
    SearchCandidateDetails,
    SearchCandidateDetailsModal,
    TestFinishedComponent,
    TestFinishedAskDeclineComponent,
    ValidationComponent,
    WizardComponent,
    WizardStepComponent,
    CellButtonComponent,
    CellLinkComponent,
    ColumnBuilder
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    TabsModule.forRoot(),
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    Angular2FontawesomeModule
  ],
  exports: [
    ModalTemplateComponent,
    ModalConfirmComponent,
    FormGroupComponent,
    SearchSelectComponent,
    NavigationButtonsComponent,
    KeyValuesToListPipe,
    FilterPipe,
    LoadingIndicator,
    InputMaskDirective,
    AutofocusDirective,
    TranslationDirective,
    CliquidityCheckbox,
    CliqButtonComponent,
    ActionButtons,
    InvalidTypeDirective,
    InvalidmessageDirective,
    HelpTooltip,
    IntercomComponent,
    SafePipe,
    VersionControl,
    TestFinishedComponent,
    TestFinishedAskDeclineComponent,
    ValidationComponent,
    WizardComponent,
    WizardStepComponent,
    CellButtonComponent,
    CellLinkComponent
  ],
  providers: [
    VersionApiResource,
    AssessmentService,
    TranslationService,
    InvitesService,
    AssessmentApiResource,
    InvitesApiResource
  ]
})

export class SharedModule {
}
