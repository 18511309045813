import {BusinessApiResource} from "../../../business/api/api.business.service";
import {ChemAPIJs} from "./chem.api.js.js";
import {CompanyService} from "../../../business/comp/service/company.service";
import {WindowRef} from "../../window.ref";
import {ModalService} from "../../modal/modal.service";
import {NativeIncludeService} from "./native.include.service";
import {DownloadService} from "../../../business/service/download.service";
import {PapaParseService} from 'ngx-papaparse';
import {SessionService} from "../session/session.service";
import {AppService} from "../app.service";

export const ServiceList: { [key: string]: { provide: any, deps: any[], useClass?: any } } = {
  'api': {
    provide: BusinessApiResource,
    deps: []
  },
  'chem': {
    provide: ChemAPIJs,
    deps: []
  },
  'cs': {
    provide: CompanyService,
    deps: [BusinessApiResource]
  },
  'winRef': {
    provide: WindowRef,
    deps: []
  },
  'modal': {
    provide: ModalService,
    deps: []
  },
  'nativeInclude': {
    provide: NativeIncludeService,
    deps: []
  },
  'ds': {
    provide: DownloadService,
    deps: []
  },
  'papa': {
    provide: PapaParseService,
    deps: []
  },
  'session': {
    provide: SessionService,
    deps: []
  },
  'apps': {
    provide: AppService,
    deps: []
  }
}
