import {EventEmitter, Inject, Injectable, Injector, NgZone} from '@angular/core';
import {AbstractModalService} from "./abstact.modal.service";
import {Observable} from "rxjs";
import {FormBuilder} from "@angular/forms";
import {AppConfig} from "../../admin/app.config";
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {TranslationService} from "../service/translation.service";

@Injectable()
@Inject(BsModalService)
@Inject(BsModalRef)
@Inject(FormBuilder)

export class ModalService implements AbstractModalService {

  appConfig = AppConfig;
  static modalRefs: BsModalRef[] = [];
  static modalEmitters: EventEmitter<any>[] = [];
  static injector: Injector;
  loading: boolean;
  loaded: boolean = false;
  saving: boolean;

  data: any;

  getData(data: any): any {
    return data;
  }

  public openModal(template: any, data: any, config?: any): Observable<any> {
    this.data = this.getData(data);
    let emitter = new EventEmitter();
    ModalService.modalEmitters.push(emitter);
    let ref: BsModalRef = this.modalService.show(template, {
      initialState: {data: this.data},
      class: (config && config.class) || 'modal-md',
      backdrop: (config && config.backdrop != undefined ? config.backdrop : true),
      ignoreBackdropClick: true
    });

    ModalService.modalRefs.push(ref);

    if (config && config.onShow) {
      config.onShow();
    }

    return emitter;
  }

  notify(data: any) {
    let emitter: EventEmitter<any> = ModalService.modalEmitters.pop();
    if (emitter) {
      emitter.emit({data: data});
      emitter.complete();
    }
  }

  close() {
    let ref: BsModalRef = ModalService.modalRefs.pop();
    if (ref) {
      ref.hide();
    }
  }

  constructor(
    public modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public zone: NgZone,
    public injector: Injector,
    public fb: FormBuilder,
    public ts: TranslationService
  ) {
    ModalService.injector = injector;
  }

}
