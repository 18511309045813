import {ScoreLevel} from "../../model/search/scorelevel.model";

export const SearchCodes = {

  searchResultLimit: [
    <ScoreLevel>{code: '20', d: '20'},
    <ScoreLevel>{code: '100', d: '100'},
    <ScoreLevel>{code: '200', d: '200'},
    <ScoreLevel>{code: '300', d: '300'},
    <ScoreLevel>{code: '400', d: '400'},
    <ScoreLevel>{code: '500', d: '500'},
  ],

  searchScopeItems: [
    <ScoreLevel>{code: 'C', d: 'Company candidates'},
    <ScoreLevel>{code: 'P', d: 'Specific projects'},
    <ScoreLevel>{code: 'G', d: 'Global candidates'}
  ],

  rpCompetencies: [
    {id: 1, code: "AP", d: "Follow Rules"},
    {id: 2, code: "AN", d: "Break Rules"},
    {},
    {id: 3, code: "BP", d: "Cautious"},
    {id: 4, code: "BN", d: "Reckless"},
    {},
    {id: 5, code: "CP", d: "Competitive"},
    {id: 6, code: "CN", d: "Laidback"},
    {},
    {id: 7, code: "DP", d: "Stable"},
    {id: 8, code: "DN", d: "Volatile"}
  ],

  //integrated competencies from the competency report, not used in search anymore
  intCompetencies: [
    {id: 1, description: "Drive and Energy", section: "L"},
    {id: 2, description: "Flexibility", section: "L"},
    {id: 3, description: "Commitment", section: "L"},
    {id: 4, description: "Self-management", section: "L"},
    {id: 5, description: "Wisdom and Awareness", section: "L"},
    {id: 6, description: "Integrity", section: "L"},
    {id: 7, description: "Group belonging", section: "R"},
    {id: 8, description: "Sociable", section: "R"},
    {id: 9, description: "Emotionally sensitive", section: "R"},
    {id: 10, description: "Physical", section: "R"},
    {id: 11, description: "Humanistic / Altruistic", section: "R"},
    {id: 12, description: "Humorous / Playful", section: "R"},
    {id: 13, description: "Friendship", section: "R"},
    {id: 14, description: "Romantic", section: "R"},
    {id: 15, description: "Practical / Pragmatic", section: "R"},
    {id: 16, description: "Ideas oriented", section: "R"},
    {id: 17, description: "Independent minded", section: "R"},
    {id: 18, description: "Leadership", section: "W"},
    {id: 19, description: "Management", section: "W"},
    {id: 20, description: "Teamwork", section: "W"},
    {id: 21, description: "Technical / Specialist", section: "W"},
    {id: 22, description: "Ideas orientation", section: "W"},
    {id: 23, description: "Task orientation", section: "W"},
    {id: 24, description: "People orientation", section: "W"},
    {id: 25, description: "Complexity", section: "W"},
    {id: 26, description: "Learning", section: "W"},
    {id: 27, description: "Judgement and Intuition", section: "W"},
    {id: 28, description: "Practical / Pragmatic", section: "W"},
    {id: 29, description: "Independent minded", section: "W"}
  ],

  chem1Competencies: [
    {code: 'SE_DE', section: 'SE', description: 'Drive and Energy'},
    {code: 'SE_FL', section: 'SE', description: 'Flexibility'},
    {code: 'SE_COM', section: 'SE', description: 'Commitment'},
    {code: 'SE_SM', section: 'SE', description: 'Self-management'},
    {code: 'SE_IN', section: 'SE', description: 'Integrity'},
    {code: 'IN_SO', section: 'IN', description: 'Sociable'},
    {code: 'IN_ES', section: 'IN', description: 'Emotionally sensitive'},
    {code: 'IN_PS', section: 'IN', description: 'Physical'},
    {code: 'IN_HA', section: 'IN', description: 'Humanistic / Altruistic'},
    {code: 'IN_FE', section: 'IN', description: 'Friendship'},
    {code: 'IN_RO', section: 'IN', description: 'Romantic'},
    {code: 'WR_LE', section: 'WR', description: 'Leadership'},
    {code: 'WR_MA', section: 'WR', description: 'Management'},
    {code: 'WR_TE', section: 'WR', description: 'Teamwork'},
    {code: 'WR_LTSLB', section: 'WR', description: 'Technical / Specialist'},
    {code: 'WR_IORB', section: 'WR', description: 'Ideas orientation'},
    {code: 'WR_CO', section: 'WR', description: 'Complexity'},
    {code: 'WR_LEARN', section: 'WR', description: 'Learning'},
    {code: 'WR_JI', section: 'WR', description: 'Judgement and Intuition'},
    {code: 'WR_PP', section: 'WR', description: 'Practical / Pragmatic'},
    {code: 'WR_IM', section: 'WR', description: 'Independent minded'}
  ],

  // see competency_higher_dim_rules.csv
  higherDimSoftSkillCompetencies: [
    {code: 'COLLAB', description: "Collaborative", section: "P"},
    {code: 'CONAT', description: "Motivated", section: "P"},
    {code: 'CONCEP', description: "Conceptual", section: "P"},
    {code: 'CONINT', description: "Mindful", section: "P"}
  ],

  // see competency_higher_dim_rules.csv
  higherDimWorkCompetencies: [
    {code: 'LEAD', description: "Leadership", section: "W"},
    {code: 'MANAGE', description: "Management", section: "W"},
    {code: 'TEAM', description: "Team", section: "W"},
    {code: 'TECHN', description: "Technical", section: "W"},
    {code: 'STRAT', description: "Strategic", section: "W"}
  ],

  scoreLeveltems: [
    <ScoreLevel>{code: 'L', d: 'Low'},
    <ScoreLevel>{code: 'M', d: 'Medium'},
    <ScoreLevel>{code: 'MH', d: 'Medium-high'},
    <ScoreLevel>{code: 'H', d: 'High'},
  ],

  score4LevelItems: [
    <ScoreLevel>{level: 1, from: 1, to: 1, d: 'Low'},
    <ScoreLevel>{level: 2, from: 2, to: 3, d: 'Medium'},
    <ScoreLevel>{level: 3, from: 4, to: 4, d: 'High'}
  ],

  score5LevelItems: [
    <ScoreLevel>{level: 1, from: 1, to: 2, d: 'Low'},
    <ScoreLevel>{level: 2, from: 3, to: 3, d: 'Medium'},
    <ScoreLevel>{level: 3, from: 4, to: 5, d: 'High'}
  ],

  score7LevelItems: [
    <ScoreLevel>{level: 1, from: 1, to: 3, d: 'Low'},
    <ScoreLevel>{level: 2, from: 4, to: 5, d: 'Medium'},
    <ScoreLevel>{level: 3, from: 6, to: 7, d: 'High'}
  ],

  score7LevelHighItems: [
    <ScoreLevel>{level: 3, from: 4, to: 7, d: 'High'}
  ],

  stenLevelItems: [
    <ScoreLevel>{level: 1, from: 1, to: 3, d: 'Low'},
    <ScoreLevel>{level: 2, from: 4, to: 6, d: 'Medium'},
    <ScoreLevel>{level: 3, from: 7, to: 10, d: 'High'}
  ],

  levelItems: [
    <ScoreLevel>{level: 1, from: 0, to: 33, d: 'Low'},
    <ScoreLevel>{level: 2, from: 33, to: 66, d: 'Medium'},
    <ScoreLevel>{level: 3, from: 66, to: 100, d: 'High'}
  ],

  stanineLevels: [
    <ScoreLevel>{level: 1, from: 1, to: 3, d: 'Low'},
    <ScoreLevel>{level: 2, from: 5, to: 6, d: 'Medium'},
    <ScoreLevel>{level: 3, from: 7, to: 9, d: 'High'}
  ],

  viHollandItems: [
    <ScoreLevel>{code: 'R', d: 'Realistic'},
    <ScoreLevel>{code: 'I', d: 'Investigative'},
    <ScoreLevel>{code: 'A', d: 'Artistic'},
    <ScoreLevel>{code: 'S', d: 'Social'},
    <ScoreLevel>{code: 'E', d: 'Enterprising'},
    <ScoreLevel>{code: 'C', d: 'Conventional'}
  ],

  // See google sheet "chem2_virtuous_text"
  // Intentional  : Intentional(4), optimistic(6), passionate(10), intellectual(15)
  // Adaptable    : intuitive(2), accepting(5), optimistic(6), compassionate(10), freedom and responsibility(12), adaptable(13), Compassionate(14)
  // Intellectual : Interdependence(1), experimental(3), pragmatic(7), principled(8), transcendent(9), creative(11), intellectual(15)
  chem2LifeItems: [
    <ScoreLevel>{code: '4|6|10|15', d: 'Intentional'},
    <ScoreLevel>{code: '2|5|6|10|12|13|14', d: 'Adaptable'},
    <ScoreLevel>{code: '1|3|7|8|9|11|15', d: 'Intellectual'}
  ],

  chem2ATTItems: [
    <ScoreLevel>{code: '4|5', d: 'Optimistic'},
    <ScoreLevel>{code: '2|3|4', d: 'Balanced'},
    <ScoreLevel>{code: '1|2', d: 'Critical'}
  ],

  chem2HEADItems: [
    <ScoreLevel>{code: '4|5', d: 'Structured / Analytical'},
    <ScoreLevel>{code: '2|3|4', d: 'Adaptive'},
    <ScoreLevel>{code: '1|2', d: 'Intuitive'}
  ],

  chem2HEARTItems: [
    <ScoreLevel>{code: '4|5', d: 'Energetic'},
    <ScoreLevel>{code: '2|3|4', d: 'Balanced'},
    <ScoreLevel>{code: '1|2', d: 'Unemotional'}
  ],

  chem2FEETItems: [
    <ScoreLevel>{code: '4|5', d: 'Controlled'},
    <ScoreLevel>{code: '2|3|4', d: 'Adaptive'},
    <ScoreLevel>{code: '1|2', d: 'Not controlling'}
  ],

  // see chem2_enneagrams
  chem2Enn: [
    <ScoreLevel>{code: '1', d: 'Type 1: To be perfect'},
    <ScoreLevel>{code: '2', d: 'Type 2: To give / be needed'},
    <ScoreLevel>{code: '3', d: 'Type 3: To perform'},
    <ScoreLevel>{code: '4', d: 'Type 4: To want more'},
    <ScoreLevel>{code: '5', d: 'Type 5: To know'},
    <ScoreLevel>{code: '6', d: 'Type 6: To control'},
    <ScoreLevel>{code: '7', d: 'Type 7: To enjoy'},
    <ScoreLevel>{code: '8', d: 'Type 8: To take'},
    <ScoreLevel>{code: '9', d: 'Type 9: To let go'}
  ],

  ssm:
    {
      cols: ["COLLAB", "CONAT", "CONCEP", "CONINT"],
      rows: ["STRUCT", "COMP", "TECH", "STRAT", "THEO", "INTEG", "HOLIS"]
    },

}

