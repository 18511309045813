import {Component, Input} from '@angular/core';
import {BusinessBaseComponent} from "../business.base.component";
import {Group} from "../../../shared/model/group.model";

@Component({
  selector: 'talent-pool-scores',
  templateUrl: 'talent.pool.scores.html'
})

export class TalentPoolScoresComponent extends BusinessBaseComponent {

  sendingScorers: boolean = false;
  @Input() group: Group;

  emailResultsToConsultant() {
    this.sendingScorers = true;
    this.api.batteryAction("emailtalentpoolscores", {
      companyKey: this.cs.company.companyKey,
      groupId: this.group.id,
    }).subscribe((result) => {
      this.sendingScorers = false;
      setTimeout(() => {
        alert('The results will be ready soon. Please check the inbox of ' + this.sess.info.user.email + '.');
      }, 100);
    });
  }
}
