import {Component, Input, ViewChild} from '@angular/core';
import {Battery} from "../../model/battery.model";
import {GenericList} from "../../model/list.generic.model";
import {Subscription} from 'rxjs';
import {BatteryAddModalComponent} from "./battery.add.modal.component";
import {BusinessBaseComponent} from "../business.base.component";
import {Company} from "../../../shared/model/company.model";
import {ModalConfirmComponent} from "../../../shared/modal/modal.confirm";
import {InviteListComponent} from "../invite/invite.list";
import {$clearBatteryActionCache} from "../../api/api.business.service";

@Component({
  selector: 'batteries-page',
  templateUrl: 'batteries.page.html'
})

export class BatteriesPageComponent extends BusinessBaseComponent {

  batteries: GenericList<Battery>;
  _companySelected: Company;
  _batterySelected: Battery;
  _inviteSelected: any;
  apiSubscription: Subscription;

  @ViewChild('invitelist') inviteListComponent: InviteListComponent;

  @Input() set batterySelected(val) {
    this._batterySelected = val;
    setTimeout(() => {
      this._inviteSelected = null;
      this.detectChanges();
    }, 1);
  }

  get batterySelected() {
    return this._batterySelected;
  }

  get inviteSelected() {
    return this._inviteSelected;
  }

  onBatterySelect($event) {
    this.batterySelected = null;
    setTimeout(() => {
      this.batterySelected = $event;
    }, 1);

  }

  onInviteSelect($event) {
    setTimeout(() => {
      this._inviteSelected = $event;
    }, 1);
  }

  addCandidatesToBattery() {
    if (this._batterySelected) {
      this.modal.openModal(BatteryAddModalComponent, this._batterySelected, {class: 'modal-lg'}).subscribe(result => {
        let newCandidates = result.data;
        if (newCandidates && newCandidates.length > 0) {
          this.api.batteryAction("details", {id: this._batterySelected.id}).subscribe((result) => {
            this._batterySelected = new Battery().fromOther(result.responseObject);
            alert('We will start sending out the invitation emails soon. You will receive a delivery report and the end of the day.');
          });
        }
        $clearBatteryActionCache.next();
      });
    }
  }

  removeSelectedCandidate() {
    let inv = this._inviteSelected;

    this.apiSubscription = this.api.batteryAction("getcandidatebatteryids", {
      companyKey: this.cs.company.companyKey,
      candidateId: inv.m.id
    }).subscribe((result) => {
      let batteryIds: [number] = result.responseObject;
      let n = batteryIds.length;
      let message = '';
      let yes1;
      let yes2;
      if (n == 1) {
        message = `The candidate <i>${inv.m.email}</i> will be removed from project <i>${this._batterySelected.name}</i>.<br/><br/>Your company will not be able to access the candidate's results anymore.`
        yes1 = '';
        yes2 = `Remove`;
      } else {
        message = `The candidate <i>${inv.m.email}</i> is also added to ${n - 1} other project(s).<br/><br/>You can remove the candidate from this project only.<br/><br/>You can also remove the candidate from all projects and your company will not be able to access the candidate's results anymore.`
        yes1 = 'Remove from project';
        yes2 = `Remove from all projects`;
      }

      this.modal.openModal(ModalConfirmComponent, {
        yes: yes1,
        yes2: yes2,
        message: message
      }, {}).subscribe(result => {
        console.log("result", result);
        this.apiSubscription = this.api.batteryPostAction("removecandidate", {
          batteryId: this._batterySelected.id,
          candidateId: inv.m.id,
          removeCompanyInvite: (result.data == 'yes2'),
          companyKey: this.cs.company.companyKey,
        }).subscribe((result) => {
          this._removeCandidate(inv.m.email);
          $clearBatteryActionCache.next();
          this.inviteListComponent.getInvites(true);
        });
      });

    });
  }

  _removeCandidate(email: string) {
    let lookups = this._batterySelected.candidates.filter(c => c.email == email);
    if (lookups) {
      let idx = this._batterySelected.candidates.indexOf(lookups[0]);
      this._batterySelected.candidates.splice(idx, 1);
    }
  }

  getBatteriesForCompany(company: Company) {
    if (company) {
      this.loading = true;
      this._inviteSelected = null;

      if (this.apiSubscription) {
        this.apiSubscription.unsubscribe();
      }
      this.apiSubscription = this.api.batteryAction("list", {companyKey: company.companyKey}).subscribe((result) => {
        this.batteries = new GenericList<Battery>().set(result.responseObject);
        this.loading = false;
        this.detectChanges();
      });
    }
  }

  getBatteries() {
    this.getBatteriesForCompany(this.cs.company);
  }

  ngOnInit() {
    this.loading = false;
    this.getBatteries();
    this.cs.OnChange().subscribe((company) => {
      if (this._companySelected && this._companySelected.companyId == company.companyId) {
        return;
      }
      this._companySelected = company;
      this.batterySelected = null;
      this.getBatteriesForCompany(company);
    });
  }

}
