import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ScoreLevel} from "../../model/search/scorelevel.model";
import {Competency} from "../../model/search/competency.model";
import {BusinessBaseComponent} from "../business.base.component";

@Component({
  selector: 'search-slider',
  templateUrl: 'search.slider.html'
})

export class SearchSliderComponent extends BusinessBaseComponent {

  _model: Competency;
  @ViewChild('sliderRef') sliderRef;
  @Output() modelChange = new EventEmitter();

  levels: any = {
    0: <ScoreLevel>{level: 0, from: 0, to: 0, description: 'Not important'},
    1: <ScoreLevel>{level: 1, from: 0, to: 33, description: 'Low'},
    2: <ScoreLevel>{level: 2, from: 33, to: 66, description: 'Medium'},
    3: <ScoreLevel>{level: 3, from: 66, to: 100, description: 'High'}
  };

  sliderDisabled: boolean = false;
  sliderValue = 0;

  rangeAllowZero = {
    'min': 0,
    '33%': 1,
    '66%': 2,
    'max': 3
  }

  sliderConfig: any = {
    behaviour: 'drag-tap',
    connect: false,
    snap: true,
    range: this.rangeAllowZero
  }

  ngOnInit() {
    this.setModelLevel(0);
  }

  onSliderChange(comp: Competency, level: number) {
    this.setModelLevel(level);
    this.modelChange.emit(this._model);
  }

  setModelLevel(level: number) {
    let scoreLevel = this.levels[level];
    this._model.scoreLevel.level = scoreLevel.level;
    this._model.scoreLevel.from = scoreLevel.from;
    this._model.scoreLevel.to = scoreLevel.to;
    this._model.scoreLevel.d = scoreLevel.d;
  }

  get disabled() {
    return this.sliderDisabled;
  }

  @Input()
  set disabled(val) {
    this.sliderDisabled = val;
  }

  get model(): Competency {
    return this._model;
  }

  @Input()
  set model(comp: Competency) {
    this._model = new Competency(comp.categoryCode, comp.codeList, comp.description, comp.related, null, null, null, null);
    this._model.codeList = comp.codeList;
    this._model.description = comp.description;
    this._model.related = comp.related;
  }

}
