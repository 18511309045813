import {AppConfig} from "../../../admin/app.config";
import {NativeIncludeItem} from "./native.include.item";

/**
 * dynamically include external javascript and css files
 *
 * @type {{type: string; name: string; src: string}[]}
 */

const appId = 123;

export const NativeIncludeItems: NativeIncludeItem[] = [

  {type: 'js', name: 'jquery', src: '//ajax.googleapis.com/ajax/libs/jquery/1.7.2/jquery.min.js'},
  {type: 'js', name: 'jquery.ui', src: AppConfig.links.appUrl + '/js/jquery-ui-1.8.13.full.js'},
  {type: 'js', name: 'jstorage', src: AppConfig.links.appUrl + '/js/jstorage.min.js'},
  {type: 'js', name: 'jquery.json', src: AppConfig.links.appUrl + '/js/jquery.json-2.2.min.js'},
  {type: 'js', name: 'jquery.cookie', src: AppConfig.links.appUrl + '/js/jquery.cookie.js'},
  {type: 'js', name: 'fancybox', src: '//cdnjs.cloudflare.com/ajax/libs/fancybox/2.1.7/js/jquery.fancybox.min.js'},
  {type: 'js', name: 'jcrop', src: AppConfig.links.appUrl + '/js/jquery.Jcrop.min.js'},

  //from php website
  {type: 'js', name: 'mychemistry.js', src: AppConfig.links.webUrl + '/js/mychemistry.js'},             // use login() and logout() scripts on website
  {type: 'js', name: 'onload', src: AppConfig.links.webUrl + '/js/onload.js'},                          // login will call modifyLoggedIn() to update profile info

  {type: 'js', name: 'api.info', src: AppConfig.links.appUrl + '/server/chem_api.js?appid=' + appId},   // APIInfoJavascript.java
  {type: 'js', name: 'chem.api.v1', src: AppConfig.links.appUrl + '/js/chem-api-v1.js?appid=' + appId},
  {type: 'js', name: 'utils', src: AppConfig.links.appUrl + '/js/utils.js'},
  {type: 'js', name: 'native_utils.js', src: AppConfig.links.appUrl + '/js/native_utils.js'},

  {
    type: 'css',
    name: 'fancybox.css',
    src: '//cdnjs.cloudflare.com/ajax/libs/fancybox/2.1.7/css/jquery.fancybox.min.css'
  },
  {type: 'css', name: 'jquerycustom.css', src: AppConfig.links.appUrl + '/css/jquery-ui-1.8.14.custom.css'}

];
