/**
 * See MemberInviteVirtualStatus.java
 */
export class MemberInviteVirtualStatus {

  countDeclined: number;

  countAccepted: number;

  countPending: number;

  virtualStatus: string;

  summaryStatus: string;

  constructor(from: any) {
    if (from) {
      this.countDeclined = from.countDeclined;
      this.countAccepted = from.countAccepted;
      this.countPending = from.countPending;
      this.virtualStatus = from.virtualStatus;
      this.summaryStatus = from.summaryStatus;
    }
  }

}
