import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";

/**
 * https://embed.plnkr.co/J04rdB/
 * See search.result.item.html
 */
@Component({
  selector: 'child-cell',
  template: `

    {{item.member_alias}}

    <span *ngIf="!item.istalentpool" class="result-item" [ngClass]="{'is-accepted':item.isaccepted, 'is-not-accepted':!item.isaccepted, 'is-invited':item.isinvited, 'is-not-invited':!item.isinvited, 'is-favorite':item.isfavorite, 'is-not-favorite':!item.isfavorite}" style=" width: 100%; height:100%">

      <div class="result-icons pl-1">
          <span href="javascript:void(null)"
                class="link-icon invite-icon"
                title="{{item.isinvited ? 'Invited' : 'Not invited'}}">
            <i fa [name]="'plus-circle'"></i>
          </span>
      </div>

        <div class="result-icons pl-1">
          <span href="javascript:void(null)"
                class="link-icon accepted-icon"
                title="{{item.isaccepted ? 'Sharing results' : 'Not sharing results'}}">
            <i fa [name]="'check-circle'"></i>
          </span>
      </div>

      <div class="result-icons pl-1">
          <span href="javascript:void(null)"
                class="link-icon favorite-icon"
                title="{{item.isfavorite ? 'In favorites' : 'Not in favorites'}}">
            <i fa [name]="'star'"></i>
          </span>
      </div>

    </span>

  `
})

export class CellSearchResultIconsComponent implements ICellRendererAngularComp {

  refresh(params: any): boolean {
    return undefined;
  }

  public params: any;

  get item() {
    return this.params.data;
  }

  agInit(params: any): void {
    this.params = params;
  }

}
