import {Observable} from 'rxjs';
import {Inject, Injectable} from "@angular/core";
import {Competency} from "../../business/model/search/competency.model";
import {SharedApiResource} from "../api/api.shared.service";

@Inject(SharedApiResource)
@Injectable()
export class SelectProvider {

  NOT_IMPORTANT_TEXT = 'Not important';
  NOT_IMPORTANT_CODE = 'NI';

  // see RestSmallAction.java
  public autoComplete(competency: Competency, term: string): Observable<any> {
    return new Observable(observer => {
      this.api.smallActionPost(competency.related, term, []).subscribe((result) => {
        let list = result.responseObject;
        if (list) {
          list = list.filter(c => c.v.indexOf(this.NOT_IMPORTANT_TEXT) < 0);
        }
        observer.next(list);
        observer.complete();
      });
    });
  }

  // see RestSmallAction.java
  public autoCompleteSmallAction(related: any, term: string): Observable<any> {
    return new Observable(observer => {
      this.api.smallActionPost(related, term, []).subscribe((result) => {
        let list = result.responseObject;
        if (list) {
          list = list.filter(c => c.v.indexOf(this.NOT_IMPORTANT_TEXT) < 0);
        }
        observer.next(list);
        observer.complete();
      });
    });
  }

  // result is of type CodeJson.java
  public codeList(codeList: string): Observable<any> {
    var filterNotImportant = ((c: any) => {
      return c.d.indexOf(this.NOT_IMPORTANT_TEXT) < 0;
    });
    return this.codeListByFilter(codeList, filterNotImportant);
  }

  public codeListByFilter(codeList: string, filterExpression: Function): Observable<any> {
    return new Observable(observer => {
      this.api.getCodeList(codeList).subscribe((result) => {
        let list = result.responseObject;
        if (list && filterExpression) {
          list = list.filter(filterExpression);
        }
        list = list.filter(c => c.k != this.NOT_IMPORTANT_CODE);
        observer.next(list);
        observer.complete();
      });
    });
  }

  public getSmallAction(action: string, term: string): Observable<any> {
    return new Observable(observer => {
      this.api.smallActionPost(action, term, []).subscribe((result) => {
        let list = result.responseObject;
        observer.next(list);
        observer.complete();
      });
    });
  }

  constructor(protected api: SharedApiResource) {
  }

}
