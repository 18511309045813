import {BusinessBaseComponent} from "../business.base.component";
import {Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {SearchData} from "../../model/search/search.data.model";
import {SearchCriteria} from "./search.criteria";
import {Competency} from "../../model/search/competency.model";
import {SearchCodes} from "./search.codes";
import {SearchDataAndCriteria} from "../../model/search/search.data.and.criteria.model";
import {Options} from "ngx-google-places-autocomplete/objects/options/options";
import {GooglePlaceDirective} from "ngx-google-places-autocomplete";

@Component({
  selector: 'search-criteria',
  templateUrl: 'search.criteria.component.html'
})

export class SearchCriteriaComponent extends BusinessBaseComponent {

  searchData: SearchData = new SearchData();            // The criteria for the current search
  sc: SearchCriteria = new SearchCriteria(this.api, this.cs);
  searchCodes: any = SearchCodes;

  @Output() onSearch = new EventEmitter<SearchDataAndCriteria>();
  @Output() onClear = new EventEmitter();
  @Output() onCriteriaChange = new EventEmitter();
  @Input() showSearch: boolean = true;
  @Input() showClearAll: boolean;
  @Input() showScopeGlobal: boolean = true;
  @Input() showSearching: boolean;
  showScopeProjects: boolean;
  countryCode: string = null;    // ISO 3166-1 Alpha-2 compatible country code, see profile.basic.html

  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  geoOptions: Options = {
    types: ['(cities)'],
    componentRestrictions: null,
    bounds: null,
    fields: [],
    strictBounds: false,
    origin: null
  };

  onCountryChange(country: any) {
    if (country && country.selectedItems.length > 0) {
      this.countryCode = country.selectedItems[0]['k'];
    } else {
      this.countryCode = null;
    }
    this.setLocationCountry();
    this.onChange();
  }

  setLocationCountry() {
    if (this.placesRef.options && this.countryCode) {
      this.placesRef.options.componentRestrictions = {country: this.countryCode}
    } else {
      this.placesRef.options.componentRestrictions = null;
    }
    this.placesRef.reset();
  }

  onCityChange(address) {
    if (address == null) {
      this.sc.city.selectedItems = [];
      this.sc.city.payload = null;
    } else {
      let city = address.name
      let placeId = address.place_id; // for eg Cape Town: "ChIJ1-4miA9QzB0Rh6ooKPzhf2g"
      this.sc.city.selectedItems = [{k: placeId, d: city}];
      this.sc.city.payload = address.formatted_address;
    }
    this.onSelectChange();
  }

  populate(searchData: SearchData) {
    this.sc.populateFromCriteria(searchData);
    if (!this.showScopeGlobal) {
      this.sc.updateScopeSelect(false);
    }
  }

  onSearchClicked() {
    this.searchData = new SearchData();
    this.searchData = this.sc.getSearchData();
    this.searchData.companyKey = this.cs.company.companyKey;
    this.onSearch.emit({searchData: this.searchData, searchCriteria: this.sc});
  }

  onChange() {
  }

  onSelectChange() {
    this.searchData = this.sc.getSearchData();
    this.searchData.companyKey = this.cs.company.companyKey;
    this.onCriteriaChange.emit(this.searchData);
  }

  clearSearch() {
    this.sc.clearCriteria();
    this.searchData = new SearchData();
    this.onClear.emit('');
    this.onSelectChange();
  }

  onScopeChange(c: Competency) {
    let scopeCode = c.selectedItems['code'];
    this.showScopeProjects = (scopeCode == 'P');
  }

  ngOnInit() {
    let _this = this;
    $("input[type=search]").on("input", function () {
      let city = $(this).val();
      if (!city) {
        _this.onCityChange(null);
      }
    });
  }

}
