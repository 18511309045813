import {Component, Input} from "@angular/core";
import {BusinessBaseComponent} from "../business.base.component";
import {SearchCodes} from "./search.codes";
import {GenericList} from "../../model/list.generic.model";
import {CandidateEducation} from "../../../individual/model/candidate.education.model";
import {CandidateItemCard} from "../../../individual/model/candidate.itemcard.model";
import {CandidateExperience} from "../../../individual/model/candidate.experience.model";

@Component({
  selector: 'search-candidate-details',
  templateUrl: 'search.candidate.details.html'
})

export class SearchCandidateDetails extends BusinessBaseComponent {

  d: any;                                                                       // see BigQueryMemberResults.java and BatteryScores.java
  eduLevelCodes: any;                                                           // for eg DEG:Degree
  higherDimWorkCodes: any = SearchCodes.higherDimWorkCompetencies;              // see SoftSkillScorer->COMP_HIGHER_DIM
  higherDimSofSkillCodes: any = SearchCodes.higherDimSoftSkillCompetencies;     // see SoftSkillScorer->COMP_HIGHER_DIM
  chem1Competencies: any = SearchCodes.chem1Competencies;
  rpCompetencyCodes: any = SearchCodes.rpCompetencies;
  unknown: string = 'Unknown';
  searchCodes = SearchCodes;

  @Input() set data(val: any) {
    if (val) {
      this.loading = true;
      this.sharedApi.getCodeListCached('EDU_FIELD').subscribe((result) => {
        this.eduLevelCodes = result.responseObject;
        this.d = val;
        this.loading = false;
      });
    }
  }

  // see ProfileExperienceLoadJson.java->asConcatenatedPipeString
  parseExperience(): GenericList<CandidateExperience> {
    let list: GenericList<CandidateExperience> = new GenericList<CandidateExperience>();
    if (this.d && this.d.experience) {
      let exp = this.d.experience.split('$');
      exp.forEach((e) => {
        let split = e.split('|');
        list.add(new CandidateExperience(split[0], split[1], split[2], split[3], (split[4] == 'true'), split[5], split[6]));
      });
    }
    return list;
  }

  // see ProfileEducationLoadJson.java->asConcatenatedPipeString
  parseEducation(): GenericList<CandidateEducation> {
    let list: GenericList<CandidateEducation> = new GenericList<CandidateEducation>();
    if (this.d && this.d.education) {
      let edu = this.d.education.split('$');
      edu.forEach((e) => {
        let split = e.split('|');
        list.add(new CandidateEducation(split[0], split[1], new CandidateItemCard(split[2], this.getEduLevel(split[2])), split[3], split[4], null));
      });
    }
    return list;
  }

  get allowedBasicBio() {
    return (this.d && this.d.allowedbasicbio);
  }

  get allowedAllBio() {
    return (this.d && this.d.allowedallbio);
  }

  get hasEdu() {
    return (this.d && this.d.education);
  }

  get hasHigherDimWorkCodes() {
    return (this.d && this.d.dimLEAD);
  }

  get hasHigherDimSofSkillCodes() {
    return (this.d && this.d.dimCOLLAB);
  }

  get hasCara() {
    return (this.d && this.d.cara_date);
  }

  get hasChem1() {
    return (this.d && this.d.chem1_date);
  }

  get hasChem2() {
    return (this.d && this.d.chem2_date);
  }

  get hasVi() {
    return (this.d && this.d.vi_date);
  }

  get hasRp() {
    return false;
  }

  get hasNr() {
    return (this.d && this.d.nr_date);
  }

  get hasData() {
    return (this.allowedBasicBio || this.allowedAllBio || this.hasHigherDimWorkCodes || this.hasCara || this.hasChem1 || this.hasChem2 || this.hasNr);
  }

  getEduLevel(code: string) {
    return this.eduLevelCodes.filter(l => l.k == code)[0].d;
  }

}
