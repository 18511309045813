export class ApiStatusModel {

  endpointCreate: any = {statusCode: '', loading: false};
  endpointAdd: any = {statusCode: '', loading: false};
  endpointResults: any = {statusCode: '', loading: false};

  create: any = {};

  workflow: any = {loading: false};

  assessresults: any = {loading: false};

  assessresultscore: any = {};

  person: any = {};

  job: any = {};

  reset: any = {loading: false};

}
