import {Inject, Injectable} from "../../../node_modules/@angular/core";
import {ProfileApiResource} from "../api/api.profile.service";
import {AssessmentApiResource} from "../api/api.assessment.service";
import {AssessmentsJson} from "../model/api/assessments.json";
import {CandidateTests} from "../model/candidate.tests.model";
import {CandidateAssessment} from "../model/candidate.assessment.model";
import {GenericList} from "../../business/model/list.generic.model";
import {CandidateAssessments} from "../model/candidate.assessments.model";
import {SessionService} from "../../shared/service/session/session.service";
import {AppService} from "../../shared/service/app.service";
import {TestChemJson} from "../model/api/test.chem.json";
import {ServiceLocator} from "../../shared/service/native/service.locator";
import {MemberInvitesExtendedJsons} from "../../shared/model/memberinvitesextendedjsons.model";

@Injectable()
@Inject(ProfileApiResource)
export class AssessmentService {

  appService: AppService = ServiceLocator.injector.get(AppService);

  public _getCandidateLatestTestsCompleted(returnUrl?: string, companyId?: number) {
    return new Promise<CandidateTests>((resolve, reject) => {
      this.api._getCandidateLatestTestsCompleted({returnUrl: returnUrl, companyId: companyId}).subscribe((result) => {
        let tests = new CandidateTests();
        if (result.responseObject.length > 0) {
          tests.fromApi(result.responseObject);
        }
        resolve(tests);
      })
    })
  }

  public getAllVersionsForCandidate() {
    return new Promise<CandidateTests>((resolve, reject) => {
      this.api._getAllVersionsForCandidate().subscribe((result) => {
        let tests = new CandidateTests();
        if (result.responseObject.length > 0) {
          tests.fromApi(result.responseObject);
        }
        resolve(tests);
      })
    })
  }

  public getCandidateLatestTests() {
    return new Promise<CandidateTests>((resolve, reject) => {
      this.api._getCandidateTests().subscribe((result) => {
        let tests = new CandidateTests();
        if (result.responseObject.length > 0) {
          tests.fromApi(result.responseObject);
        }
        resolve(tests);
      })
    })
  }

  public getDashboardAssessments() {
    return new Promise<AssessmentsJson>((resolve, reject) => {
      this.api._getDashboardSection('steps').subscribe((result) => {
        let assessment: AssessmentsJson = result.responseObject.data;
        resolve(assessment);
      })
    })
  }

  public getAssessmentsForInvites(mies: MemberInvitesExtendedJsons, aboutAssessmentPath: string, returnUrl?: string, companyId?: number) {
    return new Promise<CandidateAssessments>((resolve, reject) => {
      this._getCandidateLatestTestsCompleted(returnUrl, companyId).then((result: CandidateTests) => {
        let tests = new CandidateTests();
        tests.tests = new GenericList<TestChemJson>().set(result.tests.filter(t => mies.filter(mie => mie.i.inviteType == t.reportType).length > 0));
        this.getDashboardAssessments().then((result) => {
          let assessments = new AssessmentsJson().setFromNative(result);
          let cas: CandidateAssessments = this.buildAssessmentList(tests, assessments, aboutAssessmentPath, false);
          cas.assessments.forEach(ma => {
            ma.mie = mies.filter(mie => mie.i.inviteType == ma.reportType)[0];
          })
          resolve(cas);
        })
      })
    });
  }

  public buildAssessmentList(tests: CandidateTests, aj: AssessmentsJson, aboutAssessmentPath: string, includeComp = true): CandidateAssessments {

    let cas = new CandidateAssessments();

    cas.cara = new CandidateAssessment(
      "CARA",
      "CARA",
      "Cliquidity Adaptive Reasoning Assessment (CARA)",
      this.appService.deployUrl + "/assets/images/CARA.png",
      aj.getStep(aj.STEP_TYPE_CARA).title,
      "Your report will be available to you when you complete the assessment",
      "Free for individual users",
      "20-30 minutes",
      !this.sess.hasPreferenceHideCARA_CAND && tests.hasTestForType('CARA'),
      true,
      aboutAssessmentPath + "reasoningassessment",
      tests.getTestForType('CARA'),
      aj.ACTION_DO_ASSESSMENT_3
    );

    cas.chem1 = new CandidateAssessment(
      "C1",
      "CHEM1",
      "Personality Assessment",
      this.appService.deployUrl + "/assets/images/Personality.png",
      "Personality preferences",
      "Your report will be available to you when you complete the assessment",
      "Free for individual users",
      "10-20 minutes",
      tests.hasTestForType('C1'),
      true,
      aboutAssessmentPath + "personalityassessment",
      tests.getTestForType('C1'),
      aj.ACTION_DO_ASSESSMENT_1
    );

    cas.chem2 = new CandidateAssessment(
      "C2",
      "CHEM2",
      "Motivation Assessment",
      this.appService.deployUrl + "/assets/images/Motivation.png",
      "Personal motivation, drive and energy",
      "Your report will be available to you when you complete the assessment",
      "Free for individual users",
      "20-30 minutes",
      tests.hasTestForType('C2'),
      true,
      aboutAssessmentPath + "motivationassessment",
      tests.getTestForType('C2'),
      aj.ACTION_DO_ASSESSMENT_2
    );

    cas.vi = new CandidateAssessment(
      "VI",
      "VI",
      "Vocational Interests",
      this.appService.deployUrl + "/assets/images/VI.png",
      "Personality-based preference for certain types of tasks",
      "Your report will be available to you when you complete the assessment.", // <br/><b>There is an optional feedback video that explains the results</b>",
      "Free for individual users",
      "10-20 minutes",
      tests.hasTestForType('VI'),
      true,
      aboutAssessmentPath + "interestassessment",
      tests.getTestForType('VI'),
      aj.ACTION_DO_ASSESSMENT_4
    );

    cas.rp = new CandidateAssessment(
      "RP",
      "RP",
      "Risk Profiler",
      this.appService.deployUrl + "/assets/images/rp_logo.png",
      "Personality attributes that are linked to common counterproductive work behaviours",
      "Your report will be available to you when you complete the assessment.",
      "Free for individual users",
      "10-20 minutes",
      tests.hasTestForType('RP'),
      true,
      aboutAssessmentPath + "riskassessment",
      tests.getTestForType('RP'),
      aj.ACTION_DO_ASSESSMENT_5
    );

    cas.nr = new CandidateAssessment(
      "NR",
      "NR",
      "Numerical Reasoning",
      this.appService.deployUrl + "/assets/images/nr_logo.png",
      "Numerical reasoning skills",
      "Your report will be available to you when you complete the assessment.",
      "Free for individual users",
      "10-20 minutes",
      tests.hasTestForType('NR'),
      true,
      aboutAssessmentPath + "numericalassessment",
      tests.getTestForType('NR'),
      aj.ACTION_DO_ASSESSMENT_6
    );

    cas.epg = new CandidateAssessment(
      "EPG",
      "EPG",
      "Entrepreneurial Game",
      this.appService.deployUrl + "/assets/images/EPGame.png",
      "Business related behaviours indicating commercial effectiveness, decision making, gratification seeking etc.",
      "Your report will be available to you when you complete the assessment.",
      "Free for individual users",
      "30-60 minutes",
      !this.sess.hasPreferenceHideEPG_CAND && tests.hasTestForType('EPG'),
      false,
      aboutAssessmentPath + "epgameassessment",
      tests.getTestForType('EPG'),
      aj.ACTION_DO_ASSESSMENT_7
    );

    cas.rp.visible = false;
    cas.epg.visible = false;

    if (includeComp) {
      cas.comp = new CandidateAssessment(
        "COMP",
        "COMP",
        "Competency Report",
        this.appService.deployUrl + "/assets/images/Competency.png",
        "An integration of assessment results.",
        "The competency dimensions reported on reflect the personal functioning in the categories of  Self, Interpersonal Relationships and Work.",
        null,
        "Immediate report generation",
        true,
        false,
        aboutAssessmentPath + "competencyreport",
        tests.getTestForType('COMP'),
        null
      );
    }

    cas.assessments = new GenericList<CandidateAssessment>();
    if (cas.cara.visible) cas.assessments.add(cas.cara);
    if (cas.chem1.visible) cas.assessments.add(cas.chem1);
    if (cas.chem2.visible) cas.assessments.add(cas.chem2);
    if (cas.vi.visible) cas.assessments.add(cas.vi);
    if (cas.rp.visible) cas.assessments.add(cas.rp);
    if (cas.nr.visible) cas.assessments.add(cas.nr);
    if (cas.epg.visible) cas.assessments.add(cas.epg);
    if (cas.comp && cas.comp.visible) cas.assessments.add(cas.comp);

    return cas;

  }

  constructor(public api: AssessmentApiResource, public sess: SessionService) {
  }

}
