import {BusinessBaseComponent} from "../business.base.component";
import {Component, Input} from "@angular/core";

@Component({
  selector: 'search-results',
  templateUrl: 'search.results.component.html'
})
export class SearchResultsComponent extends BusinessBaseComponent {

  searched: boolean = false;    // true when a search was done
  searchResult = {};            // BigQueryResult.java->results
  displayResult = [];           // results formatted for display
  resultsMain = [];             // results with a 100% criteria match
  resultsOther = [];            // result with lower match percentage

  // results is of type BigQueryResult.java->results
  @Input() set results(searchResults: any) {
    if (searchResults == null) {
      this.searched = false;
      this.searchResult = {};
    } else {
      this.searched = true;
    }
    this.setResults(searchResults);
  }

  setResults(searchResults: any) {

    this.displayResult = [];
    this.searchResult = searchResults;
    for (let k in this.searchResult) {
      let v = this.searchResult[k];
      this.displayResult.push({
        key: k,
        value: v
      });
    }

    this.resultsMain = this.displayResult.filter(x => x.value.rank_percent >= 100);
    this.resultsOther = this.displayResult.filter(x => x.value.rank_percent < 100);

  }

  get hasMain() {
    return (this.resultsMain.length > 0);
  }

  get hasOther() {
    return (this.resultsOther.length > 0);
  }

  get hasResults() {
    return (this.resultsMain.length > 0 || this.resultsOther.length > 0);
  }

}
