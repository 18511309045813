import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Battery} from "../../model/battery.model";
import {ModalConfirmComponent} from "../../../shared/modal/modal.confirm";
import {BusinessBaseComponent} from "../business.base.component";
import {BatterySummary} from "../../model/battery.summary.model";
import {GenericList} from "../../model/list.generic.model";
import {TestProfile} from "../../model/testprofile.model";
import {BatteryRenameModalComponent} from "./battery.rename.modal.component";
import {ApiResult} from "../../../shared/model/api.result.model";

@Component({
  selector: 'battery-details',
  templateUrl: 'battery.details.html'
})

export class BatteryDetailsComponent extends BusinessBaseComponent {

  _battery: Battery;
  summary: BatterySummary;
  testChanges: GenericList<TestProfile>;
  summaryLoading: boolean = false;

  actions = {
    resend: {loading: false},
    accept: {loading: false},
    register: {loading: false},
    score: {loading: false}
  }

  @Input() set battery(val: Battery) {
    if (val) {
      this.getBattery(val.id);
    }
  }

  @Output()
  batteryChange: EventEmitter<Battery> = new EventEmitter<Battery>();

  editName() {
    this.modal.openModal(BatteryRenameModalComponent, this._battery, {class: 'modal-md'}).subscribe(result => {
      this.api.batteryAction("updatename", {name: result.data.name, id: this._battery.id}).subscribe((result) => {
        let apiResult = new ApiResult(result);
        if (apiResult.valid) {
          if (apiResult.hasResult) {
            this._battery.fromOther(apiResult.responseObject);
            this.batteryChange.next(this._battery);
          }
        } else {
          alert(`${apiResult.status} (${apiResult.resultCode}) `);
        }
      });
    });
  }

  filterExcludeBatteries(list) {
    return list.filter(function (d) {
      return d.i.inviteType != 'BATT';
    });
  }

  sendReminders() {
    this.modal.openModal(ModalConfirmComponent, {
      title: 'Confirm',
      yes: 'Continue',
      message: 'We will resend invitations to all candidates who have not yet responded. Continue?'
    }, {}).subscribe(result => {
        if (result.data == true) {
          this.actions.resend.loading = true;
          this.api.batteryAction("resendinvites", {
            id: this._battery.id,
            companyKey: this.cs.company.companyKey
          }).subscribe((result) => {
            this._battery.emailKey = result.responseObject.emailKey;
            this.actions.resend.loading = false;
            alert(result.detailMessage);
          });
        }
      }
    );
  }

  getBattery(id: number) {
    this.api.batteryAction("details", {id: id}).subscribe((result) => {
      this._battery = new Battery().fromOther(result.responseObject);
      this.testChanges = new GenericList<TestProfile>().set(this._battery.tests);
    });
  }

  summaryOpened(isOpened: boolean) {
    if (isOpened) {
      if (!this.summary) {
        this.loadSummary();
      }
    }
  }

  loadSummary() {
    if (!this._battery) return;
    let batteryId = this._battery.id;
    this.summaryLoading = true;
    this.api.batteryAction("getsummary", {
      batteryId: batteryId,
    }).subscribe((result) => {
      this.summary = new BatterySummary().setFromNative(result.responseObject);
      this.summary.testCount = this._battery.tests.length;
      this.summaryLoading = false;
    });
  }

  onBatteryTestsChange(tests: GenericList<TestProfile>) {
    this.testChanges.set(tests.filter(tp => tp.selected));
  }

  canSaveTestChanges(): boolean {
    if (this.testChanges.length == 0) return false;

    let current = this._battery.tests.map(tp =>
      tp.testType
    ).join('');
    let selected = this.testChanges.map(tp =>
      tp.testType
    ).join('');

    let hasChanged = (current != selected);
    if (!hasChanged) return false;

    return true;
  }

  updateBatteryTests() {
    this.modal.openModal(ModalConfirmComponent, {
      yes: 'Proceed',
      message: 'Candidates might receive notifications to complete additional assessments, proceed ?'
    }).subscribe(result => {
      this.api.batteryPostAction("updatetests", {
        batteryId: this._battery.id,
        tests: this.testChanges
      }).subscribe((result) => {
        this.getBattery(this._battery.id);
      });
    });
  }

}
