import {Component, Input} from "@angular/core";
import {AppConfig} from "../../admin/app.config";
import {TestChemJson} from "../../individual/model/api/test.chem.json";


@Component({
  selector: 'test-finished',
  template: `

    <div class="test-finished">

      <div class="center-horizontal" style="grid-area: text;">
        <span trans="SHARED1" [transArg]="config.admin.testLongNames[_testStatus.reportType]">You have completed your %s.</span>
        <div *ngIf="inviteKey" class="mt-4 mb-2">
          <test-finished-ask-decline [inviteKey]="inviteKey"></test-finished-ask-decline>
        </div>
      </div>

      <ng-container *ngIf="isReturnToProvider">
        <action-button [action]="config.buttonActions.ACTION_RETURN_PROVIDER" [param]="returnUrl" style="grid-area: tell;"></action-button>
      </ng-container>

      <ng-container *ngIf="!isReturnToProvider">
        <action-button [action]="config.buttonActions.ACTION_TELL" style="grid-area: tell;"></action-button>
        <action-button [action]="config.buttonActions.ACTION_REPORT" [param]="_testStatus.reportType" [subparam]="_testStatus.versionNumber" style="grid-area: report;"></action-button>
        <action-button [action]="config.buttonActions.ACTION_ASSESSMENTS" style="grid-area: dashboard;"></action-button>
      </ng-container>

    </div>

  `
})
export class TestFinishedComponent {
  config = AppConfig;
  _testStatus: TestChemJson;
  inviteKey: string;

  @Input() set testStatus(value: TestChemJson) {
    this._testStatus = value;
    this.inviteKey = (this._testStatus && this._testStatus.externalParam) ? this._testStatus.externalParam.paramMap['inviteKey'] : null;
  }

  get isReturnToProvider(): boolean {
    return (this._testStatus && this._testStatus.externalParam && this._testStatus.externalParam.paramMap['returnUrl']);
  }

  get isInvitedFromCompany(): boolean {
    if (this._testStatus && this._testStatus.externalParam && this._testStatus.externalParam.paramMap['companyId']) {
      return true;
    }
    return false;
  }

  get returnUrl(): string {
    return this._testStatus && this._testStatus.externalParam ? this._testStatus.externalParam.paramMap['returnUrl'] : null;
  }

  declineInvite() {

  }

  constructor() {

  }

}
