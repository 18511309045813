import {Component} from '@angular/core';
import {BusinessBaseComponent} from "../business.base.component";

@Component({
  selector: 'invites-page',
  templateUrl: 'invites.page.html'
})

export class InvitesPageComponent extends BusinessBaseComponent {

  inviteSelected: any;

}
