import {Component, Input, ViewChild} from '@angular/core';
import {Battery} from "../../model/battery.model";
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {BusinessBaseComponent} from "../business.base.component";

@Component({
  selector: 'battery-scores',
  templateUrl: 'battery.scores.html'
})

export class BatteryScoresComponent extends BusinessBaseComponent {

  @Input() battery: Battery;
  scores: any[];                  // see CSVScoresGenerator.getCsvData()
  columns: any = [];
  hasScores: boolean = false;
  sendingScorers: boolean = false;
  @ViewChild('grid') grid: DatatableComponent;

  ngOnInit() {
  }

  scoresOpened(isOpened: boolean) {
    if (isOpened) {
      if (!this.scores) {
        this.loadScores();
      }
    }
  }

  emailResultsToConsultant() {
    this.sendingScorers = true;
    this.api.batteryAction("emailbatteryscores", {
      companyKey: this.cs.company.companyKey,
      batteryId: this.battery.id,
    }).subscribe((result) => {
      this.sendingScorers = false;
      setTimeout(() => {
        alert('The results will be available soon. Please check your inbox at ' + this.sess.info.user.email + '.');
      }, 100);
    });
  }

  //deprecated
  _downloadResults() {
    let url = this.sess.APP_URL + '/show?report=candidateCSVReport&jk=' + 'BATT:' + this.battery.id;
    var link = document.createElement("a");
    link.download = "a";
    link.href = url;
    document.body.appendChild(link);
    link.click();
  }

  loadScores() {

    this.loading = true;
    this.hasScores = false;
    this.api.batteryAction("scorespreview", {id: this.battery.id}).subscribe((result) => {

      let csvData = result.responseObject;

      this.scores = [];
      this.columns = [];

      if (!csvData) {
        this.loading = false;
        return
      }

      this.columns.push({
        prop: 'section',
        name: csvData[0][0],
        width: '100',
        cellClass: 'label-cell',
        frozenLeft: true
      });
      this.columns.push({
        prop: 'field',
        name: csvData[0][1],
        width: '190',
        cellClass: 'label-cell',
        frozenLeft: true
      });
      let c = 1;
      for (var col = 2, len = csvData[0].length; col < csvData[0].length; col++) {
        this.columns.push({prop: 'CAND' + c, name: csvData[0][col], width: '160'});
        c++;
      }

      //skip csv header row
      for (var row = 1, len = csvData.length; row < csvData.length; row++) {
        let score = {};
        score['section'] = csvData[row][0];
        score['field'] = csvData[row][1];
        let c = 1;
        for (var col = 2, len = csvData[row].length; col < len; col++) {
          score['CAND' + c] = csvData[row][col];
          c++;
        }
        this.scores.push(score);
      }

      this.scores = [...this.scores];
      this.columns = [...this.columns];

      this.hasScores = (this.columns.length > 2);

      this.loading = false;

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);


    });
  }

}
