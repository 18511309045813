import {environment} from "../environments/environment";

export const AppConfig = {
  env: environment.env,
  appCode: '',
  appDeployPath: '',
  intercom_app_id: 'l7f4dekz',
  mainDomain: 'cliquidity.co.uk',
  recaptcha_site_key: '6LeKxY0UAAAAAHvEuufuXJosWO_Qsma0u3BYyjEH',
  links: {
    appUrl: environment.appUrl,
    webUrl: environment.webUrl,
    aboutAssessmentsPath: "/individual/about/individual/",
    webReportUrl: environment.appUrl + '/wr/report.html',
    login_callback_url: {
      app: '/api_login_callback.jsp',   // running local npm mode on http://localhost:4200 or running deployed script directly
      website: 'auth/login-callback/'   // running compile code from php website
    },
  },
  admin: {
    debugParam: 'param=cliqdbg',
    dateFormat: 'YYYY-MM-DD HH:mm',
    dateFormatYMD: 'YYYY-MM-DD',
    apiResultCodes: {
      TEST_COMPLETE_NO_ACCESS: 'TCNA',
      RESPONSE_LOGIN_REQUIRED: 'LR'
    },
    admin_commands: [
      {
        cmd: "billingsheet",
        label: "Email billing sheet",
        params: [{
          label: 'For test type',
          key: 'testType',
          value: 'ALL',
          choices: [
            {key: 'ALL', value: 'ALL'},
            {key: 'C1', value: 'CHEM1'},
            {key: 'C2', value: 'CHEM2'},
            {key: 'CARA', value: 'CARA'},
            {key: 'VI', value: 'VI'},
            {key: 'RP', value: 'RP'},
            {key: 'NR', value: 'NR'},
            {key: 'EPG', value: 'EPG'}
          ]
        }, {
          label: 'Anonymize',
          key: 'anonymize',
          value: 'False',
          choices: [
            {key: 'True', value: 'Yes'},
            {key: 'False', value: 'No'},
          ]
        }]
      }],
    developer_commands: [
      {
        cmd: "emailTestScores",
        label: "Email Test scores",
        params: [{
          label: 'For test type',
          key: 'testType',
          value: 'C1',
          choices: [
            {key: 'C1', value: 'CHEM1'},
            {key: 'C2', value: 'CHEM2'},
            {key: 'CARA', value: 'CARA'},
            {key: 'VI', value: 'VI'},
            {key: 'RP', value: 'RP'},
            {key: 'COMP', value: 'COMP'}
          ]
        }, {
          label: 'Anonymize',
          key: 'anonymize',
          value: 'False',
          choices: [
            {key: 'True', value: 'Yes'},
            {key: 'False', value: 'No'},
          ]
        }]
      },
      {
        cmd: "emailRPScores",
        label: "Email RP scores",
        params: [{
          label: 'Format',
          key: 'format',
          value: 'R',
          choices: [
            {key: 'RP_REPORT', value: 'Report scores'},
            {key: 'RP_ALL', value: 'All scores'},
            {key: 'RP_DIMS', value: 'Dim scores'},
            {key: 'RP_RAW', value: 'Raw scores'}
          ]
        }]
      },
      {
        cmd: "emailEntitiesJsonForType",
        label: "Email Entities JSON",
        params: [{
          label: 'Full java class name',
          key: 'fullClassName'
        }]
      },
      {
        cmd: "rescoreTests",
        label: "Rescore tests",
        params: [{
          label: 'Test',
          key: 'testType',
          value: 'ALL',
          choices: [
            {key: 'ALL', value: 'ALL'},
            {key: 'C1', value: 'CHEM1'},
            {key: 'C2', value: 'CHEM2'},
            {key: 'CARA', value: 'CARA'},
            {key: 'VI', value: 'VI'},
            {key: 'RP', value: 'RP'},
            {key: 'NR', value: 'NR'},
            {key: 'EPG', value: 'EPG'}
          ]
        }]
      },
      {
        cmd: "rescoreAllCompetencies",
        label: "Rescore all competencies"
      },
      {
        cmd: "bqSync",
        label: "Sync to BigQuery",
        link: '/controller?a=bqSync&n=500'
      },
      {
        cmd: "testbusinessinvites",
        label: "Test business invites"
      },
      {
        cmd: "testbatchinvites",
        label: "Test batch invites"
      },
      {
        cmd: "fixoldaliases",
        label: "Fix old aliases"
      }
    ],
    assessStatusCodes: [
      {code: 'D37002019002', value: 'Incomplete'},
      {code: 'D37002019003', value: 'InProgress'},
      {code: 'D37002019001', value: 'Completed'},
      {code: 'D37002019004', value: 'Sent'}
    ],
    statusCodes: {
      'P': 'Pending',
      'A': 'Accepted',
      'D': 'Declined',
      'B': 'Blocked',
      'DA': 'Declined',  // INVITE_STATUS_DECLINE_ALWAYS
      'NI': 'Declined'   // INVITE_STATUS_DECLINE_NO_INVITES
    }, testStatus: {
      PENDING: 'P',
      STARTED: 'S',
      COMPLETED: 'C',
      EXPIRED: 'EX'
    },
    progressCodes: {
      'P': 'Not started',
      'S': 'Started',
      'C': 'Completed'
    },
    testTypes: {
      'C1': 'CHEM1',
      'C2': 'CHEM2',
      'CARA': 'CARA',
      'CAR': 'CARA',
      'VI': 'VI',
      'RP': 'RP',
      'NR': 'NR',
      'EPG': 'EPG'
    },
    testCodes: {
      'C1': 'C1',
      'C2': 'C2',
      'VI': 'VI',
      'CARA': 'CARA',
      'CAR': 'CARA',
      'RP': 'RP',
      'NR': 'NR',
      'EPG': 'EPG'
    },
    testShortNames: {
      'CHEM1': 'Personality',
      'CHEM2': 'Motivation',
      'VI': 'VI',
      'CARA': 'CARA',
      'RP': 'RP',
      'BATT': 'Project',
      'COMP': 'Competency',
      'SSM': 'Soft-Skills',
      'NR': 'Numerical',
      'EPG': 'EP Game'
    },
    testLongNames: {
      'CHEM1': 'Personality Assessment',
      'CHEM2': 'Motivation Assessment',
      'VI': 'Vocational Interests Assessment',
      'CARA': 'Cliquidity Adaptive Reasoning Assessment (CARA)',
      'RP': 'Performance Risk Profiler',
      'BATT': 'Project',
      'COMP': 'Competency Report',
      'SSM': 'Soft-Skills Report',
      'NR': 'Numerical Reasoning Assessment',
      'EPG': 'Entrepreneurial Game'
    },
    // see SoftSkillScorer.COMP_HIGHER_DIM
    ssmShortNames: {
      'COLLAB': 'Collaborative',
      'CONAT': 'Motivated',
      'CONCEP': 'Conceptual',
      'CONINT': 'Mindful',
      'STRUCT': 'Structured',
      'COMP': 'Competitive',
      'TECH': 'Technical',
      'STRAT': 'Strategic',
      'THEO': 'Theoretical',
      'INTEG': 'Integrative',
      'HOLIS': 'Transcendent'
    },
    testCodeMapping: {
      'C1': 'CHEM1',
      'C2': 'CHEM2',
      'VI': 'VI',
      'CARA': 'CARA',
      'RP': 'RP',
      'COMP': 'COMP',
      'SSM': 'SSM',
      'NR': 'NR',
      'EPG': 'EPG'
    },
    testStatuses: {
      'P': 'Pending',
      'S': 'Started',
      'C': 'Completed',
      'EX': 'Expired'
    },
    //see ScoreLevel.java
    scoreLevels: {
      'L': 'Low',
      'M': 'Medium',
      'MH': 'Medium-high',
      'H': 'High'
    },
    searchCodelistNames: {
      'BIO': 'Biographic',
      'EQU': 'Employment Equity',
      'EDU': 'Education',
      'PREF': 'Work Preferences',
      'CHEM1': 'Personality',
      'CHEM2': 'Motivation',
      'CARA': 'Adaptive Reasoning',
      'VI': 'Vocational Interest',
      'NR': 'Numerical Reasoning',
      'SSM': 'Soft Skills'
    },
    //See Types.java
    reportSubTypes: {'S': 'Short', 'L': 'Long'},
    //See Types.java->LANG
    languages: [
      {code: 'ENG', label: 'English'},
      // {code: 'PORT', label: 'Portuguese'},
      {code: 'SPAN', label: 'Spanish'},
      // {code: 'CHIN', label: 'Chinese'}
    ],
    genderCodes: [{
      code: "M",
      label: "Male"
    }, {
      code: "F",
      label: "Female"
    }],
    genderMapping: {
      'M': 'Male',
      'F': 'Female'
    },
    inviteMailerCodes: [{
      code: "INT",
      label: "Let Cliquidity send the emails",
      stringCode: 'MAILER_CLIQUIDITY_TIP'
    }, {
      code: "EXT",
      label: "I will send the emails using MailChimp",
      stringCode: 'MAILER_EXTERNAL_TIP'
    }],
    auditLogCodes: {
      types: [{
        code: "",
        label: "[Type]"
      }, {
        code: "INFO",
        label: "Info"
      }, {
        code: "ERROR",
        label: "Error"
      }],
      levels: [{
        code: 0,
        label: "[Level]"
      }, {
        code: 1,
        label: "1"
      }, {
        code: 2,
        label: "2"
      }, {
        code: 3,
        label: "3"
      }, {
        code: 4,
        label: "4"
      }, {
        code: 5,
        label: "5"
      }],
      pages: [{
        code: 1,
        label: "[Page]"
      }, {
        code: 2,
        label: "2"
      }, {
        code: 3,
        label: "3"
      }, {
        code: 4,
        label: "4"
      }, {
        code: 5,
        label: "5"
      }]
    },
    colWidth: {
      id: 140,
      date: 130,
      details: 70
    },
    controllerLinks: [
      {
        name: 'General', links: [
          {label: "Sync latest tests and users to BigQuery", a: 'bqSync'},
          {label: "Authorise Google Sheets", a: 'authsheets'}
        ]
      }, {
        name: 'SYSTEM', links: [
          {label: "system_strings", a: 'clearMemcache', sk: 'STR'},
          {label: "system_templates", a: 'clearMemcache', sk: 'TMPL'},
          {label: "system_codes", a: 'clearMemcache', sk: 'CODE'},
          {label: "system_forms", a: 'clearMemcache', sk: 'FRM'},
          {label: "mychem_settings", a: 'clearMemcache', sk: 'SET'},
          {label: "system_education", a: 'clearMemcache', sk: 'ED'},
          {label: "system_sports", a: 'clearMemcache', sk: 'SP'},
          {label: "system_languages", a: 'clearMemcache', sk: 'LA'},
          {label: "system_hobbies", a: 'clearMemcache', sk: 'HO'},
          {label: "system_industries", a: 'clearMemcache', sk: 'IN'}
        ]
      }, {
        name: 'CHEM1', links: [
          {label: "chem1_construct_sentences", a: 'clearMemcache', sk: 'SENT'},
          {label: "chem1_construct_descriptors", a: 'clearMemcache', sk: 'CDES'},
          {label: "chem1_construct_rules", a: 'clearMemcache', sk: 'CRULE'},
          {label: "chem1_contruct_matching_matrix", a: 'clearMemcache', sk: 'MSCO'},
          {label: "chem1_contruct_complement_matrix", a: 'clearMemcache', sk: 'MSCOC'},
          {label: "chem1_descriptor_matching_matrix", a: 'clearMemcache', sk: 'DMM'},
          {label: "chem1_higher_dimension_matrix", a: 'clearMemcache', sk: 'DIMMA'},
          {label: "chem1_higher_dimensions", a: 'clearMemcache', sk: 'DIM'}
        ]
      }, {
        name: 'CHEM2', links: [
          {label: "chem2_sentences", a: 'clearMemcache', sk: 'C2S'},
          {label: "chem2_cards", a: 'clearMemcache', sk: 'CRD'},
          {label: "chem2_enneagrams", a: 'clearMemcache', sk: 'ENN'},
          {label: "chem2_spiralscontextual", a: 'clearMemcache', sk: 'SCON'},
          {label: "chem2_spiralsvicious", a: 'clearMemcache', sk: 'SVIC'},
          {label: "chem2_virtuous", a: 'clearMemcache', sk: 'VIRT'},
          {label: "chem2_enneagram_text", a: 'clearMemcache', sk: 'ENNTXT'},
          {label: "chem2_HHF_text", a: 'clearMemcache', sk: 'HHFTXT'},
          {label: "chem2_virtuous_text", a: 'clearMemcache', sk: 'VIRTTXT'}
        ]
      }, {
        name: 'CARA', links: [
          {label: "cara_questions", a: 'clearMemcache', sk: 'CARA'},
          {label: "career_field_level1", a: 'clearMemcache', sk: 'CAR1'},
          {label: "career_field_level2", a: 'clearMemcache', sk: 'CAR2'}
        ]
      }, {
        name: 'VI', links: [
          {label: "vi_sub_categories", a: 'clearMemcache', sk: 'VISC'},
          {label: "vi_text", a: 'clearMemcache', sk: 'VIT'},
          {label: "vi_work_interests", a: 'clearMemcache', sk: 'VIWI'},
          {label: "vi_careers", a: 'clearMemcache', sk: 'VIC'},
          {label: "vi_questions", a: 'clearMemcache', sk: 'VIQ'},
          {label: "vi_statements", a: 'clearMemcache', sk: 'VIQS'}
        ]
      }, {
        name: 'RP', links: [
          {label: "rp_pictures", a: 'clearMemcache', sk: 'RPP'},
          {label: "rp_picture_rules", a: 'clearMemcache', sk: 'RPPR'},
          {label: "rp_questions", a: 'clearMemcache', sk: 'RPQ'}
        ]
      }, {
        name: 'NR', links: [
          {label: "nr_questions", a: 'clearMemcache', sk: 'NRQ'},
          {label: "nr_options", a: 'clearMemcache', sk: 'NRO'},
          {label: "assessment_sections", a: 'clearMemcache', sk: 'SEC'}
        ]
      }

    ],
    sheets: [
      {
        name: 'System', sheets: [
          {name: 'system_codes_dev', id: '1cU3N9qa5jOZ06P32S9K2wjfpgYws7RByAMKxC8NO9MQ'},
          {name: 'system_codes_prod', id: '1omwunR8J4X38W4MXzyIoQmTYAngzOKbaOVx9izfZvA8'},
          {name: 'system_forms_dev', id: '1yalpw0e4FUpp4ILzNSAUdD3B_MkNq6MzuxDqjNcrXtE'},
          {name: 'system_forms_prod', id: '1MVFB-mVHRHx8lU-AtEJ-lbeK5q510uTlYzL4xOiVhYE'},
          {name: 'system_strings', id: '1zhwkhcPH5l1fIHD0AsyUS2VNrCdhQ5dUy19wyYzq4w4'},
          {name: 'system_templates_dev', id: '1Y1nggmD839JrNxgXcJ3wMgDjUovuaAWmNVaUNh9Vheo'},
          {name: 'system_templates_prod', id: '190okvuR-aXH_AnciMWtxCmsTcE59Ow00LDp5K9mVRJo'},
          {name: 'system_products', id: '1y2rZjX-ySfDEJlydjsZVxyu70ipjJQZVT06Y_mnXtQI'},
          {name: 'system_sports', id: '12-pPWX4MzUxluoI2mNCzCGKzKCC6iqadbEGU_KyA1EI'},
          {name: 'system_hobbies', id: '14wsRSMOi8M6ZIhwdFzbzqANwQA_DUJEaye1n8RWN0kU'},
          {name: 'system_industries', id: '1SZlWgCPcKjJcrqYyuyUcEFV3-BNB5KTq7PDqF9jcfaE'},
          {name: 'system_languages', id: '16Z8KqgLHkFRIX-HNPtYVFatxgd_Rq4CxcvPluNFI6k0'},
          {name: 'system_education', id: '1rD7A6hHg7t67g0XyQGY8wMmRjU478DbZg3LBCAVbMvQ'},
          {name: 'Tegnologies & licenses', id: '1zLRM5qkIOi0PHIFFr4wYLpMURYrlQ5cfGWe1SjaXmwY'},
          {name: 'cliquidity_api', id: '1kaS4FJPcz8UQX_vJZsnZOR1aSqFveW1cjv3QsAi_6ac'},
          {name: 'career_field_level1', id: '14taKAb-eHseHXs83PNM-thHuIRqlRyuKBpNrTUyJ5dc'},
          {name: 'career_field_level2', id: '1B6qCIg1HU-5QRsAnkwg-H2xblj77GZRk4YTF5AKCHiY'}
        ]
      }, {
        name: 'CHEM1', sheets: [
          {name: 'chem1_construct_descriptors', id: '1tCSg5pd1xaaXEgAfPX-CfrFZEVWsoEPOJ74XVIDsrv0'},
          {name: 'chem1_construct_rules', id: '1ozQkOGwI0DkM2EdBPXPR3sYGDxQtRsnwONFehHCYs5U'},
          {name: 'chem1_construct_sentences', id: '1bzw1dwaswu5e8EdOVi8Q9bn6CWxcSkYvgQ9neEcw_BI'},
          {name: 'chem1_contruct_matching_matrix', id: '1GOhbX-1rrEHTpOyWG2fBd5DgQkHt5lK6srw3XPmxnUA'},
          {name: 'chem1_contruct_complement_matrix', id: '1PLrjLO6fo_Swy9BzrTxb6fKB-aoLhsBE4j5vs2L0IWQ'},
          {name: 'chem1_descriptor_matching_matrix', id: '1B9vThyEcerMJjrcjV4seGlvqPke3mUJypKfCjxVj_Hg'},
          {name: 'chem1_higher_dimension_matrix', id: '1TRk-12V8qMK4GQYKtGmICBRG2tQBY8pXqfbdD85NLEc'},
          {name: 'chem1_higher_dimensions', id: '1bZ5RtvXau4rm1fnd77-yQ2I_yPGTvNrgoObiJUod6Hg'},
          {name: 'chem1_level1_descriptors', id: '17z8cUk9ZPU-yr_2qaTXqmuIeOWafhhue2t3ODosvM-c'},
          {name: 'chem1_level123_descriptors', id: '1-Sjp_Wcy5g33tbcWslND9JAj3QeJy2uwy1i39riUJy4'}
        ]
      }, {
        name: 'CHEM2', sheets: [
          {name: 'chem2_cards', id: '1fw6Og9c09ydm891qR6cKtslU9zM4BzJv1xsBjPJqZ0M'},
          {name: 'chem2_enneagram_text', id: '1_FKw9kcXyhtsRFXL4NIAc_Gmp3Gz9AVTZjmyWUjf7ok'},
          {name: 'chem2_enneagrams', id: '1Uu57tDj4hV36BZhq8_qE1RebSVWFioS98jUwZ-_47t4'},
          {name: 'chem2_HHF_text', id: '1rzNfkv5SwZ-AH5Zd6kGuQjMucFCHn33p1m8xe8qvXfI'},
          {name: 'chem2_sentences', id: '118wn9LSleG2oVwk5KCB-y83ezLgXshATUP2Snv0jtbc'},
          {name: 'chem2_spiralscontextual', id: '1Yo_gEqXZp3YZ35cY8TwlHa5VBEHeMgAcUXZwNnRuIR4'},
          {name: 'chem2_spiralsvicious', id: '1dahwefKdbuSzLrL3tmIXw_d5D6oSvCZn8meyjZp7mrE'},
          {name: 'chem2_virtuous', id: '1wElwog5MB5ZbG8ZeRREXWLer0jdTc76PaJihiLGjJmc'},
          {name: 'chem2_virtuous_text', id: '1TJnZOsNrceOcA0YoPNMyB71xYL9jkcquHWRP_DjFO48'}
        ]
      }, {
        name: 'CARA', sheets: [
          {name: 'cara_questions', id: '1AZ3UtXiWjF_eZQafTrR424pq0A2SWij2D_0ym0a0jNc'},
          {name: 'career_field_level1', id: '14taKAb-eHseHXs83PNM-thHuIRqlRyuKBpNrTUyJ5dc'},
          {name: 'career_field_level2', id: '1B6qCIg1HU-5QRsAnkwg-H2xblj77GZRk4YTF5AKCHiY'}
        ]
      }, {
        name: 'VI', sheets: [
          {name: 'vi_sub_categories', id: '1d0g3NpPuWBP5ZvIvTH-xJcWxTntSEqPEZvlFY1HjWIE'},
          {name: 'vi_text', id: '1-xi8_W8mGFInkhezMMSDxpM-QeK7oUse2JB7sMmo-XI'},
          {name: 'vi_work_interests', id: '1BN36OPoP1qYnOJUl55faeae7VBBuf68oyZaTl1T7aFQ'},
          {name: 'vi_careers', id: '1YJKybE9C2gCtQt71dhpXzxBDH_HzrEs00TBfeL7krxc'},
          {name: 'vi_questions', id: '19cuzaKEs7npMtwfmQKZmWZfDD0YVmWDjAIAbR7aCmaE'},
          {name: 'vi_statements', id: '1V1JDJl-mASn6nyRXQ2dU8JdL5tr7OUz6ghcurPdAQ1s'},
        ]
      }, {
        name: 'RP', sheets: [
          {name: 'rp_pictures', id: '1ZkOTR-J6ABcOjnIDqIQxkHaQJlhlpBuGoU88hIoMlXQ'},
          {name: 'rp_picture_rules', id: '1CwBe8___jtwyAxuc8kGG8w1cZe27yVfwelRvt2vZiNw'},
          {name: 'rp_questions', id: '147i_cxXSKRoof87TxGXtMWq-BK8W-Iw850XfEfnZGAM'}
        ]
      }, {
        name: 'NR', sheets: [
          {name: 'nr_questions', id: '1JPl64WjWcGr_aUxfVoC_FBCj3knShk4rRm-QBS-4myY'},
          {name: 'nr_options', id: '1RrMZ58NRB7bg8DHIl8N-mk7deWJaZIWYqTXHtVye-fY'},
          {name: 'assessment_sections', id: '1laIzNTViPoHguNJwZs1CF_JQsFVMMdOv5ij-rgTnccM'}
        ]
      },
    ]
  },
  appCodes: {
    CHEM1: 'CHEM1',
    CHEM2: 'CHEM2',
    NR: 'NR',
    RP: 'RP',
    VI: 'VI',
    BUS: 'BUS',
    REPORT: 'REPORT',
    INDIVIDUAL: 'INDIVIDUAL',
    ADMIN: 'ADMIN',
    EMBED: 'EMBED',
    LANDING: 'LANDING'
  },
  invites: {
    // see InviteLogicImpl.java and  Types.java->INVITE_LIST_TYPE
    LIST_TYPE_ALL_EXTEND: 'alle',       // sent
    LIST_TYPE_ALL_COMPANIES: 'allc',
    LIST_TYPE_ALL_RECEIVED_EXTEND: 're',
    INVITE_STATUS_PENDING: 'P',
    INVITE_STATUS_ACCEPTED: 'A',
    INVITE_STATUS_DECLINED: 'D',
    INVITE_STATUS_DECLINE_ALWAYS: 'DA',
    CONNECTION_TYPE_BATTERY: 'BATT',      // business invite
    CONNECTION_TYPE_INVITE: 'INVITE'      // social invite
  },
  testStatus: {
    PENDING: 'P',
    STARTED: 'S',
    COMPLETED: 'C',
    NOTNOMINATED: 'NN'
  },
  buttonActions: {
    ACTION_TELL: 'tell',
    ACTION_REPORT: 'report',
    ACTION_DOTEST: 'dotest',
    ACTION_ASSESSMENTS: 'assessments',
    ACTION_RETURN_PROVIDER: 'returnprovider',
    ACTION_CONTINUE: 'continue'
  },
  member: {
    dateFormatUTC: 'YYYY-MM-DD HH:mm[Z]',
    perms: {
      PERMISSION_CODE_NO_EMAILS: 'N',
      PERMISSION_CODE_NO_INVITES: 'I'
    },
    prefs: {
      // see MemberPrefs.java
      NO_BUSINESS_SEARCH: 'NBS',
      NO_SOCIAL_INTRO: 'NI',
      // see Types.java
      HIDE_CARA: 'HIDECARA',
      HIDE_EPG: 'HIDEEPG'
    },
    messages: {
      devmode_not_logged_in: 'Running in development mode without a session. \nLog in at http://cliquidity.local then refresh this page...'
    }
  },
  company: {
    prefs: {
      // see Types.java
      HIDE_CARA: 'HIDECARA',
      HIDE_EPG: 'HIDEEPG',
    }
  }

}


