import {Component, EventEmitter, Output} from '@angular/core';
import {BusinessBaseComponent} from "../business.base.component";
import {Company} from "../../../shared/model/company.model";

@Component({
  selector: 'company-select',
  templateUrl: 'company.select.html'
})

export class CompanySelect extends BusinessBaseComponent {

  @Output() onSelect = new EventEmitter();

  ngOnInit() {
  }

  onChange(company: Company) {
    this.onSelect.emit(company);
  }

}
