/**
 * See RestContact.java
 */

import {Component} from "@angular/core";
import {FormGroup, Validators} from "@angular/forms";
import {BusinessBaseComponent} from "../business.base.component";
import {CompanyApplication} from "./company.application.model";
import {SupportContact} from "../../../shared/model/support.contact.model";

@Component({
  selector: 'company-application-form',
  templateUrl: './company.application.html'
})

export class CompanyApplicationComponent extends BusinessBaseComponent {

  static readonly APPLICATION_COOKIE = 'business_application';

  form: FormGroup;
  saving: boolean = false;
  saved: boolean = false;
  application: CompanyApplication = new CompanyApplication().setFromNative({
    companyName: '',
    companyReg: '',
    companyType: '',
    billingType: ''
  });

  billingTypes: {} = [
    {code: 'TRIAL', value: 'Trial'}
  ];

  companyTypes: {} = [
    {code: '', value: '[Select one]'},
    {code: 'EDU', value: 'Educational institution'},
    {code: 'CHARITY', value: 'Charity organisation'},
    {code: 'STARTUP', value: 'Startup corporation'},
    {code: 'OTHER', value: 'Other'}
  ];

  loginClick() {
    this.sess.onLoginClick('/apply').then((result: any) => {
    });
  }

  signupClick() {
    //to resume application after email verification, see RestAction.java
    this.sess.setWebsiteCookie(CompanyApplicationComponent.APPLICATION_COOKIE, '1');
    this.sess.onSignupClick();
  }

  save() {
    if (this.form.valid) {
      this.saving = true;
      let contact: SupportContact = {
        typeCode: 'APPLYB',
        email: this.sess.info.user.email, //person who submitted the application
        name: this.sess.user.name,
        subject: 'Business application',
        message: 'Business application',
        statusCode: 'NEW',
        metaData: {
          memberEmail: this.sess.info.user.email, //company member account
          data: JSON.stringify({
            company_name: this.application.companyName,
            company_reg: this.application.companyReg,
            company_type: this.application.companyType,
            billing_type: this.application.billingType
          })
        }
      };
      this.sharedApi.submitContactForm(contact).subscribe((result) => {
        this.saving = false;
        if (result.responseObject.code == 'S') {
          this.saved = true;
        } else {
          alert(result.responseObject.message);
        }
      });
    }
  }

  ngOnInit() {

    if (window.location.hash.indexOf('trial=1') >= 0) {
      this.application.billingType = this.billingTypes[0].code;
    }

    this.form = this.fb.group({
      'company_name': [null, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
      'company_reg': [null, [Validators.minLength(3), Validators.maxLength(50)]],
      'company_type': [null, []]
    });

    this.sess.OnAuthLogin().subscribe((result: any) => {
      this.sess.info.CHEM_API.addLoginAction(user => {
        this.sess.clearWebsiteCookie(CompanyApplicationComponent.APPLICATION_COOKIE);
        this.detectChanges();
      });
    });

  }

}
