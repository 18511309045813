import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Battery} from "../../model/battery.model";
import {FormGroup, Validators} from "@angular/forms";
import {BusinessBaseComponent} from "../business.base.component";

@Component({
  selector: 'battery-edit',
  templateUrl: 'battery.edit.html'
})

export class BatteryEditComponent extends BusinessBaseComponent {

  @Input() battery: Battery;
  @Output() onValidationChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  form: FormGroup;

  ngOnInit() {

    this.form = this.fb.group({
      'name': [null, [Validators.required, Validators.maxLength(30)]]
    })

    this.form.statusChanges.subscribe(val => {
      this.onValidationChange.emit(this.form.valid);
    });

  }

}
