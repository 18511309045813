import {GridOptions} from "ag-grid-community";

export class GridOptionsExtended implements GridOptions {
  columnDefs;
  columnTypes;
  context;
  api;

  enableColResize = true;
  enableFilter = false;
  floatingFilter = true;
  rowSelection = 'single';
  enableSorting = true;
  suppressCellSelection = true;
  rowHeight = 25;
  tooltipShowDelay = 1000;
  singleClickEdit = false;
  suppressRowClickSelection = true;

  onGridReadyCallback = function (params) {
  }

  onSelectionChangedCallback = function (params) {
  }

  setRowData = function (data: any) {
    this.api.setRowData(data);
  }

  onGridReady = function (params) {
    this.onGridReadyCallback(params);
  }

  onSelectionChanged = function (params) {
    this.onSelectionChangedCallback(params);
  }

  constructor(readonly parent: any) {
    this.context = {componentParent: parent};
  }
}
