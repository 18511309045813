import {Component} from "@angular/core";
import {SessionService} from "../../../shared/service/session/session.service";
import {ApiCommand} from "admin/model/api.command";
import {AppConfig} from "../../../admin/app.config";
import {AdminApiResource} from "../../../admin/api/admin.api.service";
import * as $ from "jquery";
import {GenericList} from "../../model/list.generic.model";
import {Company} from "../../../shared/model/company.model";

@Component({
  selector: 'data-export',
  templateUrl: './data.export.html'
})

export class DataExportComponent {

  working: boolean = false;     // sending command to backend
  typeSelected: string = '';    // selected test type
  resultMessage: string = '';   // message from backend
  companyIdSelected: number;    // optional company to filter on
  isAdmin: boolean = false;     // if user is admin
  anonymize: boolean = true;    // if user is admin then he can change this

  companies: GenericList<Company> = new GenericList<Company>();

  sendCommand() {
    this.working = true;
    this.resultMessage = '';
    let command = new ApiCommand(this.api);

    let p = [
      {attr: 'testType', val: this.typeSelected},
      {attr: 'companyId', val: this.companyIdSelected}

    ]
    if (this.isAdmin) {
      p.push({attr: 'anonymize', val: String(this.anonymize)});
    }

    command.commandWithPayload('result', 'emailAnonTestScores', {

      params: JSON.stringify(p)
    }).subscribe((response) => {
      setTimeout(() => {
        this.resultMessage = command.result['result']['message'];
        this.working = false;
      }, 1000);
    });

  }

  ngAfterContentInit() {
  }

  ngOnInit(): void {
    this.isAdmin = window['isAdmin'] == true;
    this.sess.OnAuthResult().subscribe((result: any) => {
      $('.loading').hide();
      if (!this.sess.allGood) {
        if (this.sess.isNpmServeEnv) {
          alert(AppConfig.member.messages.devmode_not_logged_in);
        }
      } else {
        this.loadCompanies();
      }
    })

  }

  loadCompanies() {
    let command = new ApiCommand(this.api);
    command.commandWithPayload('result', 'allcompanies', {}).subscribe((result) => {
      this.companies = result.resultObject;
    })
  }

  testChoices = [
    {key: 'C1', value: 'Personality (CHEM1)'},
    {key: 'C2', value: 'Motivation (CHEM2)'},
    {key: 'CARA', value: 'Adaptive Reasoning (CARA)'},
    {key: 'VI', value: 'Vocational Index (VI)'},
    {key: 'RP', value: 'Risk Profiler (RP)'},
    {key: 'COMP', value: 'Competencies (COMP)'}
  ];
  yesNoChoices = [
    {key: true, value: 'Yes'},
    {key: false, value: "No"},
  ]

  constructor(
    public api: AdminApiResource,
    public sess: SessionService
  ) {
  }

}
