import {Component, Input, ViewChild} from '@angular/core';
import {GenericList} from "../../model/list.generic.model";
import {Battery} from "../../model/battery.model";
import {InviteDetailsModalComponent} from "./invitedetails.modal.component";
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {BusinessBaseComponent} from "../business.base.component";
import {MemberInvitesExtendedJson} from "../../../shared/model/memberinvitesextendedjson.model";
import {MemberInvite} from "../../model/memberinvite.model";
import {SearchCandidateDetailsModal} from "../search/search.candidate.details.modal";
import {ApiResult} from "../../../shared/model/api.result.model";

@Component({
  selector: 'invite-list',
  templateUrl: 'invite.list.html'
})

export class InviteListComponent extends BusinessBaseComponent {

  @Input() showTestColumn: boolean = true;
  @Input() showProfileButton: boolean = false;
  @Input() scrollbarV: boolean = false;
  @Input() scrollbarH: boolean = false;
  @Input() inviteType: string = '';
  @Input() rowLimit: number = 5;
  @Input() showTestFilter: boolean = true;
  @Input() filterCallback: Function;
  @ViewChild('grid') grid: DatatableComponent;

  inviteSelected: any;
  _battery: Battery;
  allRows: GenericList<MemberInvitesExtendedJson>;
  filteredRows: GenericList<MemberInvitesExtendedJson> = new GenericList<MemberInvitesExtendedJson>();
  debounceSubject = new Subject();
  debounceMs: number = 1;
  apiSubscription: Subscription;
  emptyList: boolean = true;

  @Input() set battery(val: Battery) {
    this.onRowSelect.emit(null);
    if (val) {
      this._battery = val;
      this.getInvites(true);
      this.emptyList = false;
    }
  }

  ngOnInit() {
    this.loading = false;
    this.debounceSubject.pipe(debounceTime(this.debounceMs))
      .subscribe(() => {
        this.onRowSelect.emit(this.inviteSelected);
      });
  }

  onFilterChange(_filteredRows) {
    this.onRowSelect.emit(null);
    this.filteredRows = <GenericList<MemberInvitesExtendedJson>>_filteredRows;
    this.selectDefaultRow();
  }

  rowSelect({selected}) {

    if (!this.inviteSelected || this.inviteSelected.i.key != this.rowsSelected[0].i.key) {
      //selected row changed
      this.inviteSelected = this.rowsSelected[0];
      this.onRowSelect.emit(null);
      this.debounceSubject.next();
    }
  }

  inviteDetails(invite: MemberInvite) {
    this.modal.openModal(InviteDetailsModalComponent, invite, {class: 'modal-lg'}).subscribe(result => {
    });
  }

  selectDefaultRow() {
    this.rowsSelected = [];
    if (this.inviteSelected) {
      let prevSelectedRows = this.filteredRows.filter(inv => inv.i.key == this.inviteSelected.i.key);
      if (prevSelectedRows) {
        this.rowsSelected = prevSelectedRows;
      }
    }
    if (this.rowsSelected.length > 0) {
      this.onRowSelect.emit(this.rowsSelected[0]);
    }
  }

  viewProfile(memberId, $event) {

    $event.stopPropagation();
    $event.preventDefault();

    let promise = this.api.batteryAction("searchgetcandidatedetails", {
      companyKey: this.cs.company.companyKey,
      id: memberId
    });
    this.modal.openModal(SearchCandidateDetailsModal, {
      promise: promise,
      memberId: memberId
    }, {class: 'modal-lg'}).subscribe(result => {
    });
  }

  getInvites(useCache: boolean = false) {

    if (!this._battery) {
      return;
    }

    this.loading = true;
    if (this.apiSubscription) {
      this.apiSubscription.unsubscribe();
    }

    this.apiSubscription = this.api.batteryActionWithCache("listInvites", {
      companyKey: this.cs.company.companyKey,
      id: this._battery.id,
      inviteType: this.inviteType
    }, useCache).subscribe((result) => {
      let apiResult = new ApiResult(result);
      if (apiResult.valid) {
        let resultList = <GenericList<MemberInvitesExtendedJson>>result.responseObject;
        if (this.filterCallback) {
          this.allRows = this.filterCallback(resultList);
        } else {
          this.allRows = resultList;
        }
        this.selectDefaultRow();
      } else {
        alert(`${apiResult.status}`);
      }


      this.loading = false;
    });
  }

  preventToggle(event: any): boolean {
    event.stopPropagation();
    event.preventDefault();
    return false;
  }

}
