/**
 * see ScoreLevel.java
 */
export class ScoreLevel {
  code?: string = '';
  level?: number = 0;
  from?: number = 0;
  to?: number = 0;
  d?: string = '';        // maps to description in java
  d2?: string = '';       // include show the score range for each select item for eg "Low (1-2)"
  description?: string;

}

