// see https://scotch.io/tutorials/angular-2-form-validation

import {Component} from "@angular/core";
import {ModalService} from "../../../shared/modal/modal.service";
import {FormGroup, Validators} from "@angular/forms";

@Component({
  templateUrl: './group.modal.template.html'
})

export class GroupModalComponent extends ModalService implements IModalService {

  form: FormGroup;

  ngOnInit() {
    this.form = this.fb.group({
      'name': [null, [Validators.required]]
    })
  }

  save() {
    if (this.form.valid) {
      super.notify(this.data);
      super.close();
    }
  }

}
