import {Component} from '@angular/core';
import {BusinessBaseComponent} from "../business.base.component";

@Component({
  selector: 'app-login',
  templateUrl: 'business.login.component.html'
})

export class BusinessLoginComponent extends BusinessBaseComponent {

  ngOnInit(): void {
    this.sess.OnAuthLogin().subscribe((result: any) => {
      if (this.sess.allGood) {
        this.router.navigate(['/projects']);
      }
    });
  }

  loginClick() {
    this.bls.loginClick();
  }

}

