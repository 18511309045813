import {Component, Input} from '@angular/core';
import {OnMount} from "ng-dynamic";
import {AppConfig} from "../../admin/app.config";

/**
 * Standard  Cliquidity-styled button
 * Usage:
 * <cliqbutton text="{{text}}"></cliqbutton>
 * <cliqbutton text="click me"></cliqbutton>
 */
@Component({
  selector: 'cliqbutton',
  templateUrl: 'cliq.button.template.html'
})

export class CliqButtonComponent implements OnMount {

  @Input() text: string;

  @Input() link: string;

  class_white_bg = 'btn_do_it button white-button';

  locations = {
    'DASHB': '/user/dashboard',
    'VIREP': AppConfig.links.appUrl + '/wr/report.html?t=vi',       // see vi_questions sheet
    'ASSESS': AppConfig.links.webUrl + '/individual/assessments'
  };

  goto() {
    let url = this.locations[this.link];
    if (url) {
      let newWindow = (this.link == 'VIREP');
      if (newWindow) {
        window.open(url);
      } else {
        window.location.href = url;
      }
    }
  }

  /**
   * We need also need to use dynamicOnMount, <cliqbutton> can be embedded in dynamic html from google sheets and @Input does not work then
   * see DynamicHTMLModule
   * see https://github.com/lacolaco/ng-dynamic/blob/master/demo/src/awesome-button.component.ts
   */
  dynamicOnMount(attrs ?: Map<string, string>, content ?: string, element ?: Element)
    :
    void {
    this.text = attrs.get('text');
    this.link = attrs.get('link');
  }

}
