import {Routes} from "@angular/router";
import {BatteryCreateComponent} from "./comp/battery/battery.create";
import {BatteriesPageComponent} from "./comp/battery/batteries.page";
import {CompanyProfileComponent} from "./comp/profile/company.profile";
import {SearchPageComponent} from "./comp/search/search.page";
import {CompanyApplicationComponent} from "./comp/application/company.application";
import {RoutesCanAccess} from "./business.routes.can.access";
import {BusinessLoginComponent} from "./comp/login/business.login.component";
import {TalentPageComponent} from "./comp/talent/talent.pool.page";

export const BusinessRoutes: Routes = [
  {
    path: 'login',
    data: {},
    component: BusinessLoginComponent
  },
  {
    path: 'projects',
    data: {name: 'Projects'},
    component: BatteriesPageComponent,
    canActivate: [RoutesCanAccess]
  },
  {
    path: 'newproject',
    data: {},
    component: BatteryCreateComponent,
    canActivate: [RoutesCanAccess]
  },
  {
    path: 'profile', //not in use, not visible
    data: {},
    component: CompanyProfileComponent,
    canActivate: [RoutesCanAccess]
  },
  {
    path: 'search',
    data: {name: 'Search'},
    component: SearchPageComponent,
    canActivate: [RoutesCanAccess]
  },
  {
    path: 'talent',
    data: {name: 'Talent Pools'},
    component: TalentPageComponent,
    canActivate: [RoutesCanAccess]
  },
  {
    path: 'apply',
    data: {},
    component: CompanyApplicationComponent
  },
  {
    path: '**',
    redirectTo: 'projects',
    pathMatch: 'full',
    data: {}
  }
]
