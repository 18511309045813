import {Component, Input} from "@angular/core";

@Component({
  selector: 'navigation-buttons',
  templateUrl: 'navigation.buttons.html'
})

export class NavigationButtonsComponent {
  @Input() onNext: Function;
  @Input() onPrev: Function;
  @Input() onSave: Function;
  @Input() hideBack: boolean;
  @Input() hideNext: boolean;
  @Input() disablePrev: boolean = false;
  @Input() disableNext: boolean = true;
  @Input() pageNumber: any = '';
  @Input() working: boolean = true;
  @Input() completed: boolean = false;

  next() {
    if (this.onNext && this.disableNext) {
      this.save();
      this.onNext();
    }
  }

  prev() {
    if (this.onPrev && this.disablePrev) {
      this.save();
      this.onPrev();
    }
  }

  save() {
    if (this.onSave) this.onSave();
  }
}



