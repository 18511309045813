import {Component, EventEmitter, Input, Output} from '@angular/core';
import {GenericList} from "../../model/list.generic.model";
import {BusinessBaseComponent} from "../business.base.component";
import {Group} from "../../../shared/model/group.model";

@Component({
  selector: 'group-candidate-add',
  templateUrl: 'group.candidate.add.html'
})

export class GroupCandidateAdd extends BusinessBaseComponent {
  groups: GenericList<Group> = new GenericList<Group>();

  saving: boolean = false;
  saved: boolean = false;
  @Input() candidateId: number;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  addCandidateToGroup(group: Group) {
    this.saving = true;
    this.api.groupAction("addcand", {
      companyKey: this.cs.company.companyKey,
      group: JSON.stringify(group),
      candidateId: this.candidateId
    }).subscribe((result) => {
      setTimeout(() => {
        this.saving = false;
        this.saved = true;
        this.onClose.emit();
      }, 1000);
    });
  }

  ngOnInit() {
    this.getGroups();
    this.cs.OnChange().subscribe((company) => {
      this.getGroups();
    });
  }

  getGroups() {
    if (this.cs.company) {
      this.loading = true;
      this.api.groupAction("listgroups", {companyKey: this.cs.company.companyKey}).subscribe((result) => {
        this.groups.clear();
        this.groups.set(<Array<Group>>result.responseObject);
        this.loading = false;
      });
    }
  }

}
