import {Component, Input} from '@angular/core';
import {BusinessBaseComponent} from "../business.base.component";
import {GenericList} from "../../model/list.generic.model";
import {TestProfile} from "../../model/testprofile.model";

@Component({
  selector: 'battery-test-badges',
  template: `
      <div class="grid-test-badges">
          <battery-test-badge *ngFor="let test of tests" testType="{{test.reportType}}"></battery-test-badge>
      </div>`
})

export class BatteryTestBadgesComponent extends BusinessBaseComponent {
  @Input() tests: GenericList<TestProfile>;
}
