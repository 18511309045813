import {MemberInviteVirtualStatus} from "./memberInvitevirtualstatus.model";
import {AppConfig} from "../../admin/app.config";
import * as moment from "moment";

/**
 * See MemberInviteJson.java
 */

export class MemberInviteJson {
  key: string;

  alias: string;

  dateInviteSent: any;

  status: string;

  statusFriendly: string;

  virtualStatus: MemberInviteVirtualStatus;

  message: string;

  inviteType: string;

  companyId: number;

  companyName: string;

  get dateInviteSentFriendly(): string {
    return moment(this.dateInviteSent).format(AppConfig.member.dateFormatUTC);
  }

  constructor(from: any) {
    if (from) {
      this.key = from.key;
      this.alias = from.alias;
      this.dateInviteSent = from.dateInviteSent;
      this.status = from.status;
      this.statusFriendly = from.statusFriendly;
      this.virtualStatus = new MemberInviteVirtualStatus(from.virtualStatus);
      this.message = from.message;
      this.inviteType = from.inviteType;
      this.companyId = from.companyId;
      this.companyName = from.companyName;
    }
  }

}
