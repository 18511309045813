import {Component, ViewChild} from "@angular/core";
import {GenericList} from "../../model/list.generic.model";
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {GroupModalComponent} from "./group.modal.component";
import {Subject} from "rxjs";
import {debounceTime} from 'rxjs/operators';
import {ModalConfirmComponent} from "../../../shared/modal/modal.confirm";
import {BusinessBaseComponent} from "../business.base.component";
import {Group} from "../../../shared/model/group.model";

@Component({
  selector: 'group-list',
  templateUrl: 'group.list.html'
})

export class GroupListComponent extends BusinessBaseComponent {
  groups: GenericList<Group> = new GenericList<Group>();
  groupSelected: Group;
  debounceSubject = new Subject();
  debounceMs: number = 1;

  @ViewChild('grid') grid: DatatableComponent;

  ngOnInit() {
    this.getGroups();
    this.cs.OnChange().subscribe((company) => {
      this.groupSelected = null;
      this.getGroups();
    });

    this.debounceSubject.pipe(debounceTime(this.debounceMs))
      .subscribe(() => {
        this.onRowSelect.emit(this.groupSelected);
      });
  }

  deleteGroup(group: Group) {
    this.modal.openModal(ModalConfirmComponent, {
      title: 'Warning',
      yes: 'Proceed',
      message: 'Delete group?'
    }, {}).subscribe(result => {
      this.api.groupAction("deletegroup", {
        companyKey: this.cs.company.companyKey,
        group: JSON.stringify(group)
      }).subscribe((result) => {
        this.groups.remove(group);
      });
    });
  }

  addGroup() {
    let newGroup = new Group().fromOther({name: 'name'});
    this.addGroupModal(newGroup);
  }

  addGroupModal(newGroup: Group) {
    this.modal.openModal(GroupModalComponent, newGroup).subscribe(result => {
      this.api.groupAction("addgroup", {
        companyKey: this.cs.company.companyKey,
        group: JSON.stringify(result.data)
      }).subscribe((result) => {
      });
    });
  }

  rowSelect({selected}) {
    if (!this.groupSelected || this.groupSelected.id != this.rowsSelected[0].id) {
      this.groupSelected = this.rowsSelected[0];
      this.onRowSelect.emit(null);
      this.debounceSubject.next();
    }
  }

  getGroups() {
    if (this.cs.company) {
      this.loading = true;
      this.api.groupAction("listgroups", {companyKey: this.cs.company.companyKey}).subscribe((result) => {
        this.groups.clear();
        this.groups.set(<Array<Group>>result.responseObject);
        this.loading = false;
      });
    }
  }

}
