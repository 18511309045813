import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[auto-focus]'
})
export class AutofocusDirective {

  @Input('auto-focus')
  public set setAutoFocus(doSet: string) {
    if (doSet == 'true') {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, 100);
    }
  }

  public constructor(private el: ElementRef) {
  }

}
