// see https://valor-software.com/ngx-bootstrap/#/progressbar

import {Component, EventEmitter, Input, Output} from '@angular/core';
import * as moment from "moment";
import {SearchCandidateDetailsModal} from "./search.candidate.details.modal";
import {BusinessBaseComponent} from "../business.base.component";

@Component({
  selector: 'search-result-item',
  templateUrl: 'search.result.item.html'
})

export class SearchResultItemComponent extends BusinessBaseComponent {

  @Input() item: any;
  @Input() isFavorites: boolean;                // showing favorites

  @Output() onAddToGroup = new EventEmitter();
  @Output() onRemoveFromGroup = new EventEmitter();

  @Input() showAddToProject: boolean = false;
  @Input() showAddRemoveFavorites: boolean = false;
  @Input() showAddToGroup: boolean = false;
  @Input() showRemoveFromGroup: boolean = false;

  addCandidateToGroup() {
    this.onAddToGroup.emit(this.item);
  }

  removeCandidateFromGroup() {
    this.onRemoveFromGroup.emit(this.item);
  }

  addRemoveCandidateToFavorites() {
    let action = (this.item.isfavorite ? 'removecand' : 'addcandfavorites');
    this.item.isfavorite = !this.item.isfavorite;
    this.api.groupAction(action, {
      companyKey: this.cs.company.companyKey,
      candidateId: this.item.member_id,
      group: JSON.stringify({})
    }).subscribe((result) => {
    });
  }

  viewProfile(memberId) {
    let promise = this.api.batteryAction("searchgetcandidatedetails", {
      companyKey: '',
      id: memberId
    });
    this.modal.openModal(SearchCandidateDetailsModal, {
      promise: promise,
      memberId: memberId
    }, {class: 'modal-lg'}).subscribe(result => {
    });
  }

  formatEpochMs(d) {
    if (d) {
      return moment.unix(d / 1000).format('YYYY-MM-DD');
    } else {
      return "Unknown";
    }
  }

}
