import {Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {GenericList} from "../../model/list.generic.model";
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {BusinessBaseComponent} from "../business.base.component";
import {Group} from "../../../shared/model/group.model";
import {TalentPoolModalEditComponent} from "./talent.pool.modal.edit";
import {ModalConfirmComponent} from "../../../shared/modal/modal.confirm";
import {TalentPoolScoresModalComponent} from "./talent.pool.scores.modal.component";

@Component({
  selector: 'talent-pool-list',
  templateUrl: 'talent.pool.list.html'
})

export class TalentPoolListComponent extends BusinessBaseComponent {

  _pools: GenericList<Group> = new GenericList<Group>();

  poolSelected: any;
  @Output() onChange = new EventEmitter();

  @ViewChild('grid') grid: DatatableComponent;

  @Input() set pools(val) {
    if (val) {
      this._pools = val;
    }
  }

  poolScores(group: Group) {
    this.modal.openModal(TalentPoolScoresModalComponent, group, {class: 'modal-lg'}).subscribe(result => {
    });
  }

  deletePool(group: Group) {
    this.modal.openModal(ModalConfirmComponent, {
      yes: 'Delete',
      message: `Talent pool "${group.name}" will be deleted, proceed ?`
    }, {}).subscribe(result => {
      this.api.batteryAction("deletetalentpool", {groupId: group.id}).subscribe((result) => {
        this.onChange.emit(null);
      });
    });
  }

  upsertPool(group: Group) {
    if (!group) {
      group = new Group();
    }
    this.modal.openModal(TalentPoolModalEditComponent, group).subscribe(result => {
      group.companyId = this.cs.company.companyId;
      this.api.batteryPostAction("upserttalentpool", group).subscribe((result) => {
        let pool: Group = result.responseObject;
        this.detectChanges();
        this.onChange.emit(pool);
        this.loading = false;
      });
    });
  }

  rowSelect({selected}) {
    this.poolSelected = this.rowsSelected[0];
    this.onRowSelect.emit(this.poolSelected);
  }

  ngOnInit() {
  }

}
