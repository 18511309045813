import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";

//https://embed.plnkr.co/J04rdB/

@Component({
  selector: 'child-cell',
  template: `
    <progressbar [animate]="true" [value]="value">{{value}}</progressbar>
  `
})

export class CellProgressbarComponent implements ICellRendererAngularComp {

  refresh(params: any): boolean {
    return undefined;
  }

  public params: any;
  public value: number;

  agInit(params: any): void {
    this.params = params;
    this.getValue();
  }

  public getValue() {
    if (typeof this.params.getValue === 'function') {
      this.value = this.params.getValue(this.params.data);
    }
  }

}
