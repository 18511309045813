import {Component, EventEmitter, Input, Output} from '@angular/core';
import {GenericList} from "../../model/list.generic.model";
import {Battery} from "../../model/battery.model";
import {ModalConfirmComponent} from "../../../shared/modal/modal.confirm";
import {BusinessBaseComponent} from "../business.base.component";

@Component({
  selector: 'search-result-add-project',
  templateUrl: 'search.result.add.project.html'
})

export class SearchResultAddProject extends BusinessBaseComponent {
  projects: GenericList<Battery> = new GenericList<Battery>();

  saving: boolean = false;
  saved: boolean = false;
  show: boolean = true;
  @Input() candidateId: number;
  @Input() candidateName: string;
  @Output() onDone: EventEmitter<any> = new EventEmitter<any>();

  addCandidateToProject(project: Battery) {
    this.show = false
    this.modal.openModal(ModalConfirmComponent, {
      title: 'Confirm',
      yes: 'Proceed',
      message: 'Invite "' + this.candidateName + '" to project "' + project.name + '"?'
    }, {}).subscribe(result => {
      if (result.data == true) {
        this.saving = true;
        this.api.batteryAction("addsearchresultcand", {
          companyKey: this.cs.company.companyKey,
          batteryId: project.id,
          candidateId: this.candidateId
        }).subscribe((result) => {
          setTimeout(() => {
            this.saving = false;
            this.saved = true;
            this.onDone.emit();
          }, 1000);
        });
      }
    });


  }

  ngOnInit() {
    this.getProjects();
    this.cs.OnChange().subscribe((company) => {
      this.getProjects();
    });
  }

  getProjects() {
    if (this.cs.company) {
      this.loading = true;
      this.api.batteryAction("list", {companyKey: this.cs.company.companyKey}).subscribe((result) => {
        this.projects.clear();
        this.projects.set(<Array<Battery>>result.responseObject);
        this.loading = false;
        this.detectChanges();
      });
    }
  }

}
