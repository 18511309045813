/**
 * see SearchData.java
 */
import {Competency} from "./competency.model";
import {TestFormData} from "../../../shared/model/chem1/testformdata.model";

export class SearchData {

  competencies: Array<Competency> = new Array<Competency>();

  countries: string[];

  towns: string[];

  gender: string[];

  ethnicity: string[];

  travel: string[];

  relocate: string[];

  workgen: string[];

  workor: string[];

  workir: string[];

  worklev: string[];

  frmData: TestFormData = new TestFormData();

  rankCutoff?: number;

  resultLimit: number;

  companyKey: string;

  groupKey: string;

  scopeWhere: string;

  scopeBatteryKeys: string[];

}
