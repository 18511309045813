import {Component, HostListener, Input} from '@angular/core';
import {BusinessBaseComponent} from "../business.base.component";
import {GenericList} from "../../model/list.generic.model";
import {TestProfile} from "../../model/testprofile.model";
import {Battery} from "../../model/battery.model";
import {StringCodeModel} from "../../../shared/model/string.code.model";
import {Observable} from "rxjs";

@Component({
  selector: 'battery-tests-add',
  templateUrl: 'battery.tests.add.html'
})

export class BatteryTestsAdd extends BusinessBaseComponent {

  _batteryTests: GenericList<TestProfile>;
  allTests: GenericList<TestProfile>;
  testProfiles: Array<StringCodeModel>;
  innerWidth: number = window.innerWidth;

  @Input() battery: Battery;
  @Input() readOnly: boolean;

  @Input() set batteryTests(val) {
    this._batteryTests = val;
    this.buildTestList();
  }


  @HostListener('window:resize', ['$event'])

  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  //available cliquidity tests, pre-select tests already in battery
  buildTestList() {

    this.checkGetTestProfiles().subscribe(() => {

      let tests = new GenericList<TestProfile>();

      this.testProfiles.forEach((cl) => {
        let split = cl.keyValue.split('-');

        let testProfile: TestProfile = {
          name: cl.message,
          testType: split[0],
          reportType: split[1]
        };

        let showTest = true;

        if (this._batteryTests) {
          let filtered = this._batteryTests.filter(t => t.reportType == testProfile.reportType && t.selected);
          let isBatteryTest = (filtered.length > 0);
          if (!isBatteryTest) {
            if (this.cs.hasPrefHideTest(testProfile.testType)) {
              showTest = false;
            }
          }
          testProfile.selected = isBatteryTest;
        } else {
          if (this.cs.hasPrefHideTest(testProfile.testType)) {
            showTest = false;
          }
        }

        if (showTest) {
          tests.add(testProfile);
        }

      });

      this.allTests = tests;

    });
  }

  checkGetTestProfiles() {
    return new Observable(observer => {
      if (this.testProfiles) {
        observer.next();
        observer.complete();
      } else {
        this.api.getSystemStrings("TESTPROFILE").subscribe((result) => {
          this.testProfiles = <Array<StringCodeModel>>result.responseObject;
          observer.next();
          observer.complete();
        });
      }
    })
  }

  testClick(test) {
    if (this.readOnly) {
      return;
    }
    test.selected = !test.selected;

    if (this.battery) {
      this.battery.tests = <GenericList<TestProfile>>this.allTests.filter(t => t.selected);
    }

    this.onRowSelect.emit(this.allTests);
  }

  ngOnInit() {
    this.buildTestList();
  }

}
