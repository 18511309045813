import {TestProfile} from "./testprofile.model";
import {BaseObject} from "./baseobject.model";
import {Candidate} from "./candidate.model";
import {GenericList} from "./list.generic.model";

/**
 * See Battery.java
 */
export class Battery extends BaseObject {

  name: string;

  candidates: GenericList<Candidate>;

  tests?: GenericList<TestProfile>;

  importData?: any;

  memberId?: number;

  company: any;

  companyId?: number;

  companyKey?: string;

  inviteMessage?: string;

  //read only
  inviteMessageAsHTML?: string;

  emailKey?: string;

  useExternalMailer?: boolean;

  // see ImportParameters.java#autoRegisterCandidates
  autoRegisterCandidates?: boolean;

  // see ImportParameters.java#autoAcceptInvites
  autoAcceptInvites?: boolean;


  //transient, item should be hidden on battery list
  hidden: boolean = false;

  constructor(from?: any) {
    super();
    this.candidates = new GenericList<Candidate>();
    this.tests = new GenericList<TestProfile>();
    if (from) {
      this.name = from.name;
      if (from.title) // from BatteryJson.java
        this.name = from.title;
      this.companyId = from.companyId;
    }
  }

  fromOther(other: any) {
    this.id = other.id;
    this.dateCreated = other.dateCreated;
    this.name = other.name;
    this.candidates = other.candidates;
    this.tests = other.tests;
    this.importData = other.importData;
    this.memberId = other.memberId;
    this.company = other.company;
    this.companyId = other.companyId;
    this.inviteMessage = other.inviteMessage;
    this.inviteMessageAsHTML = other.inviteMessageAsHTML;
    this.emailKey = other.emailKey;
    this.useExternalMailer = other.useExternalMailer;

    return this;
  }

  get testsSelected(): Array<TestProfile> {
    let result = this.tests.filter(test => test.selected);
    return result;
  };

  //does the battery have this test selected
  hasTest(testType: string): boolean {
    let result = this.tests.filter(test => test.selected && test.testType == testType);
    return (result.length > 0);
  }
}

