import {Metadata} from "./metadata.model";

/**
 * See Company.java
 */
export class Company {

  id?: number;

  key?: string;

  apiKey: string;

  companyId?: number;

  companyName?: string;

  companyReg?: string;

  companyKey?: string;

  companyType?: string;

  billingType?: string;

  dateCreated?: any;

  dateUpdated?: any;

  metaData?: Metadata;

  preferences?: Array<string>;

  hasPreference(prefCode: string) {
    return (this.preferences.indexOf(prefCode) >= 0);
  }

  togglePreference(prefCode: string) {
    let idx = this.preferences.indexOf(prefCode);
    if (idx >= 0) {
      this.preferences.splice(idx, 1);
    } else {
      this.preferences.push(prefCode);
    }
  }

  setFromNative(from: any) {
    if (from) {
      this.id = from.id;
      this.key = from.key;
      this.apiKey = from.apiKey;
      this.companyName = from.companyName;
      this.companyKey = from.companyKey;
      this.companyId = from.companyId;
      this.companyType = from.companyType;
      this.preferences = from.preferences;
      this.metaData = from.metaData;
      if (!this.preferences) this.preferences = new Array<string>();
    }
    return this;
  }

}
