import {GenericList} from "../../business/model/list.generic.model";
import {TestChemJson} from "./api/test.chem.json";

export class CandidateTests {

  public tests: GenericList<TestChemJson>;

  getTestForType(type: string): TestChemJson {
    if (!this.tests) return null;
    let filtered = this.tests.filter(t => t.testType == type);
    if (filtered) {
      return filtered[0];
    }
    return null;
  }

  hasTestForType(type: string): boolean {
    return this.getTestForType(type) != null;
  }

  fromApi(tests: TestChemJson[]) {
    this.tests = new GenericList<TestChemJson>();
    this.tests.set(tests);
    return this;
  }

}
