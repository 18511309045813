import {AppConfig} from "../../admin/app.config";
import {Injectable} from "@angular/core";

@Injectable()
export class AppService {

  //absolute url where the angular app is deployed to
  public get deployUrl(): string {
    if (AppConfig.env == 'serve') {
      return '';
    }
    return AppConfig.links.appUrl + '/' + AppConfig.appDeployPath;
  }

}
