import {SessionService} from "../../shared/service/session/session.service";
import {Injectable} from "../../../node_modules/@angular/core";

@Injectable()
export class BusinessLoginService {

  public loginClick() {
    this.sess.onLoginClick().then((result: any) => {
      window.location.href = '#projects';
    });
  }

  public logoutClick() {
    this.sess.onLogoutClick().then((result: any) => {
      window.location.href = '#login';
    });
  }

  constructor(protected sess: SessionService) {
  }

}
