import {BusinessBaseComponent} from "../business.base.component";
import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ModalConfirmComponent} from "../../../shared/modal/modal.confirm";
import {SearchCandidateDetailsModal} from "../search/search.candidate.details.modal";

//Deprecated

@Component({
  selector: 'talent-pool-candidates',
  templateUrl: 'talent.pool.candidates.html'
})

export class TalentPoolCandidatesComponent extends BusinessBaseComponent {

  _results = [];

  @Output() onRemoveFromGroup = new EventEmitter();

  // results is a linked hashmap of type BigQueryResult.java->results
  @Input() set results(results: any) {
    this.setResults(results);
  }

  viewProfile(memberId) {
    let promise = this.api.batteryAction("searchgetcandidatedetails", {
      companyKey: this.cs.company.companyKey,
      id: memberId
    });
    this.modal.openModal(SearchCandidateDetailsModal, {
      promise: promise,
      memberId: memberId
    }, {class: 'modal-lg'}).subscribe(result => {
    });
  }

  setResults(resultsMap: any) {
    this._results = [];
    for (let entryKey in resultsMap) {
      let entryVal = resultsMap[entryKey];
      this._results.push({
        key: entryKey,
        value: entryVal
      });
    }
    this.detectChanges();
  }

  removeFromGroup(item: any) {
    this.modal.openModal(ModalConfirmComponent, {
      yes: 'Remove',
      message: `Candidate "${(item.member_name ? item.member_name : item.member_alias)}" will be removed, proceed ?`
    }, {}).subscribe(result => {
      this._results = this._results.filter(r => r.key != item.member_id);
      this.onRemoveFromGroup.emit(item);
    });
  }

}
