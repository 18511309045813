import {Observable, Subject, timer as observableTimer} from 'rxjs';
import {ChemAPIJs} from "../../../shared/service/native/chem.api.js.js";

import {SessionInfo} from "./session.info.model";
import {CompanyService} from "../../../business/comp/service/company.service";
import {AppConfig} from "../../../admin/app.config";
import {WindowRef} from "../../window.ref";
import * as moment from "moment";
import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class SessionService {

  serveEnvironment = 'serve';

  public info: SessionInfo = new SessionInfo();
  public pendingUrl: string = null; //return here after login, handled by app

  cs: CompanyService;
  chem: ChemAPIJs;
  onAuthResult: EventEmitter<any> = new EventEmitter();
  onAuthLogin: Subject<any> = new Subject();
  onAuthLoggedOut: Subject<any> = new Subject();

  _loading: boolean = true; //logging in
  loginCheckTimer;
  showSecureAlert: boolean = true; //show not allowed alert when not logged in

  //only one subscriber allowed at a given time
  public OnAuthResult() {
    return this.onAuthResult;
  }

  public OnAuthLogin() {
    return this.onAuthLogin;
  }

  public OnAuthLoggedOut() {
    return this.onAuthLoggedOut;
  }

  public get loading() {
    return this._loading;
  }

  public get user() {
    return this.info.user;
  }

  public get hasPermission(): boolean {
    return this.info.hasPermission;
  }

  public get hasPreferenceHideCARA_CAND(): boolean {
    return this.info.hasPreference(AppConfig.member.prefs.HIDE_CARA);
  }

  public get hasPreferenceHideEPG_CAND(): boolean {
    return this.info.hasPreference(AppConfig.member.prefs.HIDE_EPG);
  }

  public get isBrowserSupported(): boolean {
    return this.info.isBrowserSupported;
  }

  //running local npm mode on http://localhost:4200
  public get isNpmServeEnv(): boolean {
    return (AppConfig.env == this.serveEnvironment);
  }

  public get loggedIn(): boolean {
    return this.info.loggedIn;
  }

  public get allGood(): boolean {
    return (this.loggedIn && this.isBrowserVersionSupported());
  }

  public get isBasicProfileCompleted(): boolean {
    return (this.info.user.prefs == true);
  }

  public get CHEM_TRANS() {
    return this.info.CHEM_TRANS;
  }

  public get TRANS() {
    return this.info.TRANS;
  }

  public get APP_URL() {
    return this.info.SURL;
  }

  public get WEB_URL() {
    return this.info.LURL;
  }

  public get SA(): boolean {
    return (typeof this.info.SA != "undefined");
  }

  //get the main domain across deployments
  get WEB_DOMAIN() {
    let domain = AppConfig.mainDomain;
    if (AppConfig.env == 'serve' || AppConfig.env == 'local') {
      domain = 'cliquidity.local';
    }
    return domain;
  }

  public get nativeUtils() {
    return this.info.native_utils;
  }

  public initialise(chem: ChemAPIJs, cs: CompanyService) {
    this.chem = chem;
    this.cs = cs;
    this.nativeAuthCheck();
    const observable = observableTimer(1000, 2000);
    this.loginCheckTimer = observable.subscribe(t => {
      this.checkLoggedIn();
    });
  }

  //check periodically of the user has an active backend session
  checkLoggedIn() {
    let info: SessionInfo = this.chem.getNativeInfo();
    if (info.loggedIn) {
      this.loginCheckTimer.unsubscribe();
      this.info.copyFrom(info);
      this.onAuthLogin.next({
        loggedIn: this.loggedIn,
        hasPermission: this.hasPermission,
        isBrowserSupported: this.isBrowserVersionSupported()
      });
    }
  }

  loadNativeJsScripts(): Observable<any> {
    return this.chem.loadAllScripts();
  }

  nativeAuthCheck(backendCheck: boolean = false) {
    this.chem.nativeAuthCheckAsync(this.isNpmServeEnv, backendCheck).subscribe((sessionInfo: SessionInfo) => {
      this.info.copyFrom(sessionInfo);

      this.cs.getCompaniesAsPromise().subscribe((response) => {

        if (!this.loggedIn) {
          if (this.showSecureAlert && !this.isNpmServeEnv) {
            let native = this.winRef.nativeWindow;
            native.popupLoginWarning();
          }
        }

        this._loading = false;

        this.onAuthResult.next({
          loggedIn: this.loggedIn,
          hasPermission: this.hasPermission,
          isBrowserSupported: this.isBrowserSupported
        });

      });

    });
  }

  public onSignupClick() {
    this.info.CHEM_API.popupLogin(true, null, true);
  }

  public onLoginClick(pendingUrl?: string): Promise<any> {
    this.pendingUrl = pendingUrl;
    this.info.CHEM_API.popupLogin(null, null);
    return new Promise((resolve) => {
      this.info.CHEM_API.addLoginAction(user => {
        this.info.user = user;
        this.nativeAuthCheck();
        resolve(this.info.user);
      });
    });
  }

  public onLogoutClick(): Promise<any> {
    return new Promise((resolve) => {
      this.info.CHEM_API.logoutChemistry((x) => {
        this.info.user = null;
        this.info.hasPermission = false;
        this.cs.clearCompanies();
        this.nativeAuthCheck();
        this.chem.nativeLogout();
        this._loading = false;
        this.onAuthLoggedOut.next({});
        resolve(this.info);
      });
    });
  }

  public isBrowserVersionSupported() {
    let native = this.winRef.nativeWindow['native_utils'];
    return native.native_detect_browser();
  }

  //set a cookie on the website domain
  public setWebsiteCookie(cookieName: string, cookieValue: string) {
    let durationMins = 60;
    let cookieDate = new Date(moment().add(durationMins, 'minutes').toDate());
    let cookieStr = cookieName + "=" + cookieValue + ";expires=" + cookieDate + ";domain=" + "." + this.WEB_DOMAIN + ";path=/";
    document.cookie = cookieStr;
  }

  //clear a cookie on the website domain
  public clearWebsiteCookie(cookieName: string) {
    document.cookie = cookieName + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + "." + this.WEB_DOMAIN + ";path=/";
  }

  constructor(protected winRef: WindowRef) {
  }

}
