import {ChangeDetectorRef, Component, EventEmitter, Injector, Input, NgZone, Output} from '@angular/core';
import {Competency} from "../../model/search/competency.model";
import {EMPTY, Observable, Subject} from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators'
import {ScoreLevel} from "../../model/search/scorelevel.model";
import {BusinessBaseComponent} from "../business.base.component";
import {FormBuilder} from '@angular/forms';
import {BaseBaseComponent} from "../../../shared/base/base.base.component";
import {BusinessApiResource} from "../../api/api.business.service";
import {ModalService} from "../../../shared/modal/modal.service";
import {CompanyService} from "../service/company.service";
import {SessionService} from "../../../shared/service/session/session.service";
import {WindowRef} from "../../../shared/window.ref";
import {SelectProvider} from "../../../shared/service/select.provider";

@Component({
  selector: 'search-select',
  templateUrl: 'search.select.html'
})

export class SearchSelectComponent extends BaseBaseComponent {

  _model: Competency;
  selectedItems: any = [];
  preselectItemCodes: string[] | string;
  searchTypeAhead = new Subject<string>();
  _itemProvider: Observable<any>;
  _typeAheadItemProvider: SelectProvider;
  _selectItems: ScoreLevel[] = null;
  @Output() modelChange = new EventEmitter();
  @Output() onSelectChange = new EventEmitter();
  @Input() multiple = true;
  @Input() searchable = false;
  @Input() clearable = true;
  @Input() showDescription = true;
  @Input() loading: boolean;

  get model(): Competency {
    return this._model;
  }

  checkSetPreselectItems() {

    if (!this.preselectItemCodes) return;
    if (!this._selectItems) return;

    if (!this.multiple && this._selectItems.length == 1) {
      //for single select with backend autocomplete
      let item = this._selectItems[0];
      if (item['k'] && this.preselectItemCodes.indexOf(item['k']) >= 0) {
        this.selectedItems = this._selectItems[0];
      }
    } else {
      this._selectItems.forEach((item) => {
        if (!Array.isArray(this.preselectItemCodes)) {
          //for single select
          if (item['code'] && this.preselectItemCodes == item['code']) {
            this.selectedItems = item;
          }
        } else {
          //for multi select scenario 1, mostly for bio fields, also for backend autocomplete fields
          if (item['k'] && this.preselectItemCodes.indexOf(item['k']) >= 0) {
            this.selectedItems.push(item);
          }
          //for country select
          if (item['d'] && this.preselectItemCodes.indexOf(item['d']) >= 0) {
            this.selectedItems.push(item);
          }
          //for multi select scenario 2, from google sheets
          if (item['code'] && this.preselectItemCodes.indexOf(item['code']) >= 0) {
            this.selectedItems.push(item);
          }
          //for range scores, for eg CARA speed
          if (item['level'] && this.preselectItemCodes.indexOf(item['level'].toString()) >= 0) {
            this.selectedItems.push(item);
          }
        }
      })
    }

    this.preselectItemCodes = null;
    this._model.selectedItems = this.selectedItems;
    this.modelChange.emit(this._model);

  }

  @Input()
  set model(comp: Competency) {

    this._model = new Competency(comp.categoryCode, comp.codeList, comp.description, comp.related, null, null, null, null);

    comp.clearSelected.subscribe(() => {
      this.clearSelected();
    });

    if (comp.items) {
      this._selectItems = comp.items;
      this._selectItems.forEach((item) => {
        if (item.from > 0) {
          item.d2 = item.d + ' (' + (item.from != item.to ? item.from + '-' + item.to : item.from) + ')';
        }
      })
    } else if (comp.provider) {
      this._itemProvider = comp.provider;
      this._itemProvider.subscribe((result) => {
        this._selectItems = result;
      });
    } else if (comp.itemsProvider) {
      this._typeAheadItemProvider = comp.itemsProvider;
      this.setTypeAhead();
    }

    if (comp.defaultSelectItem) {
      this.preselectItemCodes = comp.defaultSelectItem.k;
      this.checkSetPreselectItems();
    }

    comp.setSelectedItems.subscribe((itemCodes) => {
      if (itemCodes) {
        this.preselectItemCodes = itemCodes;
      }
      this.clearSelected();
      this.checkSetPreselectItems();

      if (this._typeAheadItemProvider) {
        if (this.preselectItemCodes) {
          for (let i = 0; i < this.preselectItemCodes.length; i++) {
            this.searchTypeAhead.next(this.preselectItemCodes[i]);
            this.loading = false;
          }
        }
      }

    });

  }

  //when auto-completing from backend for eg. work experience
  setTypeAhead() {
    this.searchTypeAhead.pipe(
      debounceTime(500),
      switchMap(term => {
        if (term && (Number(term) > 0 || term.length >= 2)) {
          this.loading = true;
          return this._typeAheadItemProvider.autoComplete(this.model, term);
        } else {
          this.loading = false;
          return EMPTY;
        }
      }),
    ).subscribe(result => {
      this._selectItems = result;
      this.checkSetPreselectItems();
      this.loading = false;
      this.ref.markForCheck();
    }, () => {
      this._selectItems = [];
    });
  }

  onSelect() {
    this._model.selectedItems = this.selectedItems;
    this.modelChange.emit(this._model);
    this.onSelectChange.emit(this._model);
  }

  clearSelected() {
    this.selectedItems = [];
    this._model.selectedItems = this.selectedItems;
    this.modelChange.emit(this._model);
  }

  ngOnInit() {
    this.loading = false;
  }

  constructor(
    public api: BusinessApiResource,
    public modal: ModalService,
    public cs: CompanyService,
    public sess: SessionService,
    public fb: FormBuilder,
    public zone: NgZone,
    public ref: ChangeDetectorRef,
    public winRef: WindowRef,
    public injector: Injector
  ) {
    super(fb, zone, ref, winRef, injector);
    BusinessBaseComponent.injector = injector;
  }

}
