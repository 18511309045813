import {GenericList} from "../../model/list.generic.model";
import {AppConfig} from "../../../admin/app.config";
import {Component, Input} from "@angular/core";
import {MemberInvitesExtendedJson} from "../../../shared/model/memberinvitesextendedjson.model";

@Component({
  selector: 'invite-filter',
  templateUrl: 'invite.filter.html'
})

export class InviteFilterComponent {

  config = AppConfig;
  _allRows: GenericList<MemberInvitesExtendedJson> = new GenericList<MemberInvitesExtendedJson>();
  @Input() onChange: Function;
  @Input() onRefresh: Function;
  @Input() showTestFilter: boolean = true;

  filterOptions: any = {
    statuses: {},
    tests: {},
    progress: this.config.admin.progressCodes,
    candidates: null
  };

  filterOnValues: any = {
    statuses: [],
    tests: [],
    candidates: [],
    progress: []
  };

  @Input() set allRows(val) {
    if (val) {
      this._allRows = val;
      //this.clearFilter();
      this.updateFilterOptions();
      this.filterData();
    }
  }

  ngOnInit() {
    Object.assign(this.filterOptions.tests, this.config.admin.testShortNames);
    Object.assign(this.filterOptions.statuses, this.config.admin.statusCodes);
    delete this.filterOptions.tests['BATT'];
    delete this.filterOptions.statuses['DA'];
    delete this.filterOptions.statuses['NI'];
    delete this.filterOptions.statuses['B'];
  }

  refresh() {
    if (this.onRefresh) {
      this.onRefresh();
    }
  }

  clearFilter() {
    this.filterOnValues.candidates.length = 0;
    this.filterOnValues.candidates = [...this.filterOnValues.candidates];
  }

  updateFilterOptions() {
    if (this._allRows.length > 0) {
      let map = new Map<string, any>();
      this._allRows.forEach((session) => {
        if (session.m && session.m.id) {
          map.set(session.m.id.toString(), {email: session.m.email, name: session.m.alias});
        }
      })
      this.filterOptions.candidates = Array.from(map.values());
    }
  }

  filterData(): MemberInvitesExtendedJson[] {
    const filteredData = this._allRows.filter((session) => {

        let filter1: boolean = false;
        let filter2: boolean = false;
        let filter3: boolean = false;
        let filter4: boolean = false;

        if (this.filterOnValues.tests.length > 0) {
          for (let testFilter of this.filterOnValues.tests) {
            filter1 = filter1 || (session.i.inviteType == testFilter);
          }
        } else {
          filter1 = true;
        }

        if (this.filterOnValues.statuses.length > 0) {
          for (let statusFilter of this.filterOnValues.statuses) {
            //see getAllVirtualInvitesForBatteryUI
            let vs = session.i.virtualStatus;
            if (vs) {
              filter2 = filter2 || (statusFilter == AppConfig.invites.INVITE_STATUS_ACCEPTED && vs.countAccepted > 0);
              filter2 = filter2 || (statusFilter == AppConfig.invites.INVITE_STATUS_DECLINED && vs.countDeclined > 0);
              filter2 = filter2 || (statusFilter == AppConfig.invites.INVITE_STATUS_PENDING && vs.countPending > 0);
            }
            filter2 = filter2 || (session.i.status == statusFilter);
          }
        } else {
          filter2 = true;
        }

        if (this.filterOnValues.candidates.length > 0) {
          for (let candFilter of this.filterOnValues.candidates) {
            filter3 = filter3 || (session.m && session.m.alias && session.m.alias.indexOf(candFilter.name) !== -1);
          }
        } else {
          filter3 = true;
        }

        if (this.filterOnValues.progress.length > 0) {
          for (let progressFilter of this.filterOnValues.progress) {
            if (session.i.status == 'A' && session.t) {
              filter4 = filter4 || (session.t.statusCode == progressFilter);
            }
          }
        } else {
          filter4 = true;
        }

        return filter1 && filter2 && filter3 && filter4;

      }
    );

    if (this.onChange) {
      this.onChange(filteredData);
    }

    return filteredData;

  }

}
