import {Component, Input, ViewChild} from '@angular/core';
import {VersionBaseComponent} from "./version.base.control.component";
import {ApiResult} from "../../model/api.result.model";
import {TestChemJson} from "../../../individual/model/api/test.chem.json";
import {GenericList} from "../../../business/model/list.generic.model";
import {AppConfig} from "../../../admin/app.config";
import * as moment from "moment-timezone";
import {MemberTest} from "../../model/membertest.model";
import {ModalConfirmComponent} from "../../modal/modal.confirm";
import {interval, Subscription} from "rxjs";
import {Duration} from "moment";
import {TabsetComponent} from "ngx-bootstrap";

@Component({
  selector: 'version-control',
  templateUrl: './version.control.template.html',
  styleUrls: ['../../css/version.scss'],
})

export class VersionControl extends VersionBaseComponent {
  protected autoStartFirstVersion: boolean = false;           // auto start test if no version yet
  protected autoStartNewVersion: boolean = false;             // auto start a new version of the test but only if not in progress
  subscription: Subscription;
  protected allVersions: GenericList<MemberTest>;             // excluded expired versions
  protected latestVersion: MemberTest;
  protected redoDuration: Duration = moment.duration(0);
  protected resumeDuration: Duration = moment.duration(0);
  protected newVersionResult = {};

  public isVisible: boolean = true;
  public isLoggedIn: boolean;
  public onStart: Function;
  public onResume: Function;
  public onStartOrResume: Function;
  public showTest = false;
  public testStatus: TestChemJson;
  f_translations: {};

  @Input() protected testType;
  @ViewChild('vcTabSet') vcTabSet: TabsetComponent;

  selectStartTab() {
    this.vcTabSet.tabs[this.vcTabSet.tabs.length - 1].active = true;
  }

  getShortTestName(testType) {
    let testCode = this.config.admin.testCodeMapping[testType.toUpperCase()];
    return this.config.admin.testShortNames[testCode];
  }

  private ngAfterViewInit(): void {

    this.sess.OnAuthLogin().subscribe((result: any) => {
      if (this.sess.allGood) {
        this.isLoggedIn = true;
      }
    });

    this.sess.OnAuthResult().subscribe((result: any) => {
      if (!this.sess.allGood && this.sess.isNpmServeEnv) {
        alert(AppConfig.member.messages.devmode_not_logged_in);
      }
    });
  }

  protected sortVersionDesc(allVersions: GenericList<MemberTest>) {
    allVersions.sort((a, b) => b.versionNumber - a.versionNumber);
  }

  protected startPendingTest() {
    this.isVisible = false;
    this.showTest = true;
    if (this.onStart) this.onStart(this.testStatus);
    if (this.onStartOrResume) this.onStartOrResume(this.testStatus);
  }

  protected resumeTest() {
    this.isVisible = false;
    this.showTest = true;
    if (this.onResume) this.onResume(this.testStatus);
    if (this.onStartOrResume) this.onStartOrResume(this.testStatus);
  }

  protected updateDuration() {
    const source = interval(60000);
    this.subscription = source.subscribe(val => {
      if (this.testStatus.dateRedoAllowed != null) this.redoDuration = this.getTimeLeft(this.testStatus.dateRedoAllowed);
      if (this.testStatus.dateResumeUntil != null) this.resumeDuration = this.getTimeLeft(this.testStatus.dateResumeUntil);
    });
  }

  protected debugDeleteVersion(version: MemberTest) {
    this.modal.openModal(ModalConfirmComponent, {
      yes: 'Delete',
      title: 'Warning',
      message: `Version ${version.versionNumber} will be delete from the database.`
    }, {}).subscribe(result => {
      this.api.testAction("debug", {
        versionNumber: version.versionNumber,
        type: this.testType,
        subAction: "deleteversion"
      }).subscribe((result) => {
        alert(result.status);
        this.getTestStatus();
      })
    });
  }

  protected debugDeleteAllVersions() {
    this.modal.openModal(ModalConfirmComponent, {
      yes: 'Delete',
      title: 'Warning',
      message: `All versions will be deleted from the database.`
    }, {}).subscribe(result => {
      this.api.testAction("debug", {
        type: this.testType,
        subAction: "deleteallversions"
      }).subscribe((result) => {
        alert(result.status);
        this.getTestStatus();
      })

    });
  }

  protected getTestStatus() {
    this.loading = true;
    var urlParams = window.location.href.slice(window.location.href.indexOf('?') + 1);
    this.api.getTestStatus(this.testType, new URLSearchParams(urlParams).get('apiParam')).subscribe((result) => {
      let apiResult = new ApiResult(result);
      if (apiResult.valid) {
        this.testStatus = new TestChemJson().fromOther(result.responseObject);

        if (this.testStatus.allVersions != null) {
          this.allVersions = new GenericList<MemberTest>().set(this.testStatus.allVersions.filter(mt => !mt.isExpired));
          this.sortVersionDesc(this.allVersions);
          this.latestVersion = this.allVersions[0];
        }
        if (this.testStatus.dateRedoAllowed != null) this.redoDuration = this.getTimeLeft(this.testStatus.dateRedoAllowed);
        if (this.testStatus.dateResumeUntil != null) this.resumeDuration = this.getTimeLeft(this.testStatus.dateResumeUntil);

        this.updateDuration();

        this.tryAutoStartFirstVersion();
        this.tryAutoStartNewVersion();

        this.loading = false;

      } else {
        this.loading = false;
      }
    });

  }

  protected formatDate(rawDate: Date, withTime?: boolean) {
    if (withTime) return moment(rawDate).format('h:mm A D MMMM YYYY');
    return moment(rawDate).format('D-MMM-YY [at] H:mm')
  }

  protected checkCreateNewVersion() {
    this.loading = true;
    this.api.checkCreateNewVersion(this.testType).subscribe((result) => {
      let apiResult = new ApiResult(result);
      if (apiResult.valid) {
        this.newVersionResult = apiResult.responseObject;
        this.getTestStatus();
        this.startPendingTest();
      } else {
        alert(`${apiResult.status} (${apiResult.resultCode}) `);
      }
    });
  }

  //do first version from invite wizard
  protected tryAutoStartFirstVersion() {
    if (this.autoStartFirstVersion) {
      if (this.testStatus.allVersions == null || this.testStatus.allVersions.length == 0) {
        this.checkCreateNewVersion();
      } else if (this.testStatus.allVersions.length == 1 && this.latestVersion.statusCode == AppConfig.admin.testStatus.PENDING) {
        this.startPendingTest();
      }
    }
  }

  //redo from invite wizard
  protected tryAutoStartNewVersion() {
    if (this.autoStartNewVersion) {
      if (this.latestVersion && this.latestVersion.statusCode == AppConfig.admin.testStatus.COMPLETED) {
        this.checkCreateNewVersion();
      } else if (this.latestVersion && this.latestVersion.statusCode == AppConfig.admin.testStatus.PENDING) {
        this.startPendingTest();
      }
    }
  }

  protected debugForceCreateNewVersion() {
    this.api.testAction("debug", {
      type: this.testType,
      subAction: "forcenewversion"
    }).subscribe((result) => {
      this.getTestStatus();
      alert('Created version ' + result.responseObject.versionNumber)
    });
  }

  protected debugSetResumeExpired() {
    this.api.testAction("debug", {
      type: this.testType,
      subAction: "setresumeexpired"
    }).subscribe((result) => {
      this.getTestStatus();
      alert(result.responseObject)
    });
  }

  protected getTimeLeft(rawDate) {
    return moment.duration(moment(rawDate).diff(moment()));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  protected ngOnInit() {
    this.ts.getTranslationFile().subscribe( (data) => {
      if (this.f_translations === undefined){
        this.f_translations = {}
      }
      for (let i = 1; i <= 44; i++) {
        this.storeTranslation('SITE-G1.' + i.toString(), data);
      }
      this.storeTranslation('SITE-A1.21', data);
      console.log(this.f_translations);
    })
    this.autoStartFirstVersion = (window.location.search.indexOf('autostartfirstversion') >= 0);
    this.autoStartNewVersion = (window.location.search.indexOf('autostartnewversion') >= 0);
    this.getTestStatus();
  }

  storeTranslation(translationName: string, data: any) {
    this.f_translations[translationName] = this.ts.transByKey(translationName, data);
    // this.translations[translationName] = this.ts.trans(translationName, '',null,true).translated;
  }

}
