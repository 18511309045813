import {BaseObject} from "../../business/model/baseobject.model";
import {GenericList} from "../../business/model/list.generic.model";
import {MemberBasicJson} from "./memberbasicjson.model";

/**
 * See Group.java
 */
export class Group extends BaseObject {

  name?: string;

  type?: string;

  description?: string;

  memberId?: number;

  companyId?: number;

  searchCriteria?: any;

  members?: GenericList<MemberBasicJson> = null;

  fromOther(other: any) {
    this.id = other.id;
    this.key = other.key;
    this.name = other.name;
    this.type = other.type;
    this.description = other.description;
    this.memberId = other.memberId;
    this.companyId = other.companyId;
    this.searchCriteria = other.searchCriteria;

    return this;

  }
}
