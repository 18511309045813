import {Injectable, Input} from '@angular/core';
import {BusinessApiResource} from "../../api/api.business.service";
import {GenericList} from "../../model/list.generic.model";
import {Company} from "../../../shared/model/company.model";
import {BehaviorSubject, Observable} from "rxjs";
import {SessionService} from "../../../shared/service/session/session.service";
import {AppConfig} from "../../../admin/app.config";

/**
 * Keep track of the active (selected) company of the consultant or reseller
 */
@Injectable()
export class CompanyService {

  _company: Company;
  _selectedIndex: number;
  config = AppConfig;
  _companies: GenericList<Company> = new GenericList<Company>();
  onChange: BehaviorSubject<Company> = new BehaviorSubject<Company>(null);
  onLoaded: BehaviorSubject<GenericList<Company>> = new BehaviorSubject<GenericList<Company>>(null);

  public clearCompanies() {
    this._company = null;
    this._companies = new GenericList<Company>();
  }

  //n-th item selected on list
  get selectedIndex(): number {
    return this._selectedIndex;
  }

  get hasCompanies(): boolean {
    return (this._companies.length > 0);
  }

  get singleCompany(): boolean {
    return (this._companies.length == 1);
  }

  get company(): Company {
    return this._company;
  }

  get companies(): GenericList<Company> {
    return this._companies;
  }

  @Input() set company(val: Company) {
    if (val) {
      this._company = val;
      this._selectedIndex = this._companies.indexOf(val);
      this.onChange.next(this._company);
    }
  }

  hasPrefHideTest(testType: string): boolean {
    if (this.company) {
      if (testType == this.config.admin.testTypes.EPG) {
        return true;
      }
      if (testType == this.config.admin.testTypes.CARA && this.company.hasPreference(AppConfig.company.prefs.HIDE_CARA)) {
        return true;
      }
      if (testType == this.config.admin.testTypes.RP) {
        return true;
      }
    }
    return false;
  }

  //only one subscriber allowed at a given time
  public OnChange() {
    return this.onChange;
  }

  //allow multiple subscribers
  public OnLoaded() {
    return this.onLoaded;
  }

  public getCompanies() {
    this.getCompaniesAsPromise().subscribe(() => {
    });
  }

  //returns a promise
  public getCompaniesAsPromise() {
    return Observable.create(observer => {
      if (this.sess.loggedIn) {
        this.api.getCompanies().subscribe((result) => {
          this._companies.clear();
          let list = <GenericList<Company>>result.responseObject;

          list.forEach((c) => {
            this._companies.push(new Company().setFromNative(c));
          })

          if (this._companies && this._companies.length > 0) {
            this.company = this._companies[0];
          } else {
            this.company = null;
          }

          this.onLoaded.next(this.companies);
          observer.next(this.companies);
          observer.complete();
        });
      } else {
        observer.next(this.companies);
        observer.complete();
      }
    })
  }

  constructor(protected api: BusinessApiResource, protected sess: SessionService) {
    this.getCompanies();
  }

}
