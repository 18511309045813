/**
 * Report PDF download or Web view
 */
export class DownloadService {

  //see TestJson.java
  getLink(testJson: any) {
    return this._makeReportLink(testJson);
  }

  hasLink(testJson: any): boolean {
    return (this._makeReportLink(testJson) != null);
  }

  _makeReportLink(testJson: any) {
    if (!testJson) {
      return null;
    }
    let link = (testJson.completed == true ? testJson.webReportLink : null);
    return link;
  }

}
