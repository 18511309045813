/**
 * See BaseObject.java
 */
export class BaseObject {

  id?: number;

  key?: string;

  dateCreated?: any;

  dateUpdated?: any;

}
