import {AssessmentStepJson} from "./assessment.step.json";

export class AssessmentsJson {
  steps: AssessmentStepJson[];

  //  See DashboardStep.java
  public STEP_TYPE_PERSONALITY: string = 'PERSONALITY';
  public STEP_TYPE_MOTIVATION: string = 'MOTIVATION';
  public STEP_TYPE_CARA: string = 'CARA';
  public STEP_TYPE_VI: string = 'VI';
  public STEP_TYPE_RP: string = 'RP';

  public ACTION_DO_ASSESSMENT_1: string = "ASSESS_1"; // CHEM1
  public ACTION_DO_ASSESSMENT_2: string = "ASSESS_2"; // CHEM2
  public ACTION_DO_ASSESSMENT_3: string = "ASSESS_3"; // CARA
  public ACTION_DO_ASSESSMENT_4: string = "ASSESS_4"; // VI
  public ACTION_DO_ASSESSMENT_5: string = "ASSESS_5"; // RP
  public ACTION_DO_ASSESSMENT_6: string = "ASSESS_6"; // NR
  public ACTION_DO_ASSESSMENT_7: string = "ASSESS_7"; // EPG

  public getStep(stepType: string): AssessmentStepJson {
    let step: AssessmentStepJson = this.steps.filter(p => {
      if (p) {
        return p.stepType == stepType
      }
    })[0];
    return step;
  }

  public setFromNative(fromNative: any) {
    this.steps = fromNative.steps;
    return this;
  }

  constructor() {
  }

}
