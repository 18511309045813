import {ChangeDetectorRef, EventEmitter, Injectable, Injector, NgZone, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {SharedApiResource} from "../../api/api.shared.service";
import {WindowRef} from "../../window.ref";
import {BaseBaseComponent} from "../../base/base.base.component";
import {SessionService} from "../../service/session/session.service";
import {Subject} from 'rxjs';
import {AssessmentService} from "../../../individual/service/assessment.service";
import {VersionApiResource} from "../../api/api.version.service";
import {ModalService} from "../../modal/modal.service";
import {TranslationService} from "../../service/translation.service";

@Injectable()
export class VersionBaseComponent extends BaseBaseComponent {


  static injector: Injector;
  validationDebounce = 100;
  debounceSubject = new Subject<boolean>();
  @Output() onValidationChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public sharedApi: SharedApiResource,
    public fb: FormBuilder,
    public api: VersionApiResource,
    public as: AssessmentService,
    public zone: NgZone,
    public ref: ChangeDetectorRef,
    public winRef: WindowRef,
    public injector: Injector,
    public sess: SessionService,
    public modal: ModalService,
    protected ts: TranslationService
  ) {
    super(fb, zone, ref, winRef, injector);
    VersionBaseComponent.injector = injector;

  }

}
