import {Inject, Injectable} from "@angular/core";
import {ApiBase} from "../../shared/api/api.base.service";
import {Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Cacheable} from "ngx-cacheable";

export const $clearBatteryActionCache = new Subject<void>();

@Injectable()
@Inject(HttpClient)
export class BusinessApiResource extends ApiBase {

  groupAction(action: string, payload: any): Observable<any> {
    return this.get(this.apiUrl + "session/groups/" + action + '/', payload);
  }

  groupPostAction(action: string, payload: any): Observable<any> {
    return this.post(this.apiUrl + "session/groups/" + action + '/', payload);
  }

  batterySearch(payload: any): Observable<any> {
    return this.post(this.apiUrl + 'session/battery/search' + '/', payload);
  }

  batterySave(payload: any): Observable<any> {
    return this.post(this.apiUrl + 'session/battery/save' + '/', payload);
  }

  // see RestList.java
  getCodeList(type: string, filter: string): Observable<any> {
    return this.get(this.apiUrl + 'session/list/' + type + '/', {filter: filter});
  }

  getSystemStrings(codeList: string): Observable<any> {
    return this.getCodeList('strings', codeList);
  }

  // see RestSmallActions.java, for eg auto-complete for languages
  getSmallAction(smallAction: string, wildcard: string): Observable<any> {
    return this.post(this.apiUrl + 'session/action/' + smallAction + '/' + wildcard + '/', []);
  }

  updateCompany(payload: any): Observable<any> {
    return this.get(this.apiUrl + "companies/updateCompany/", payload);
  }

  // for the logged in consultant/reseller
  getCompanies(): Observable<any> {
    return this.get(this.apiUrl + "companies/retrieve/", {});
  }

  batteryAction(action: string, payload: any): Observable<any> {
    return this.get(this.apiUrl + "session/battery/" + action + '/', payload);
  }

  batteryActionWithCache(action: string, payload: any, useCache: boolean) {
    if (useCache) {
      return this._batteryActionCached(action, payload);
    } else {
      return this.batteryAction(action, payload);
    }
  }

  batteryPostAction(action: string, payload: any): Observable<any> {
    return this.post(this.apiUrl + "session/battery/" + action + '/', payload);
  }

  batteryPostActionWithCache(action: string, payload: any, useCache: boolean) {
    if (useCache) {
      return this._batteryPostActionCached(action, payload);
    } else {
      return this.batteryPostAction(action, payload);
    }
  }

  @Cacheable({
    maxAge: 240000,
    maxCacheCount: 5000
  })
  _batteryPostActionCached(action: string, payload: any) {
    return this.batteryPostAction(action, payload);
  }

  @Cacheable({
    maxAge: 240000,
    maxCacheCount: 5000,
    cacheBusterObserver: $clearBatteryActionCache
  })
  _batteryActionCached(action: string, payload: any) {
    return this.batteryAction(action, payload);
  }

  // see RestInvite.java
  inviteAction(inviteKey: string, inviteAction: string): Observable<any> {
    return this.post(this.apiUrl + "session/invite/update/internal/" + inviteKey + '/' + inviteAction, {});
  }

  constructor(public http: HttpClient) {
    super(http);
  }

}
