import {Component, Input} from "@angular/core";

@Component({
  selector: 'search-criteria-icon',
  template: `
    <span *ngIf="(fieldCount > 0)" style="margin-left: 5px;">
        <svg width="10" height="10">
            <circle cx="5" cy="5" r="5" stroke-width="0" fill="#9b5ebf"/>
        </svg>
    </span>
  `
})

export class SearchCriteriaIcon {

  @Input() counts: number[];

  //number of fields with at least one option selected
  get fieldCount(): number {
    let n = 0;
    if (this.counts) {
      for (let i = 0; i < this.counts.length; i++) {
        if (this.counts[i] > 0) {
          n++;
        }
      }
    }
    return n;

  }
}
