import {of as observableOf, merge as observableMerge, Observable, Subscription} from 'rxjs';

import {map} from 'rxjs/operators';
//https://hackernoon.com/create-reuseable-validation-directive-in-angualr-2-dcb0b0df2ce8
//https://plnkr.co/edit/JIwB1WeftdEfa0G2vRze?p=preview

import {Directive, ElementRef, Input, OnDestroy, Renderer2} from '@angular/core'
import {AbstractControl, ControlContainer, FormGroupDirective} from '@angular/forms';

@Directive({
  selector: '[invalidmessage]'
})
export class InvalidmessageDirective implements OnDestroy {

  @Input() invalidmessage: string;
  control: AbstractControl;
  hasView = false;
  controlValue$: Observable<any>;
  controlSubscription: Subscription;
  hasSubmitted: boolean;

  constructor(private _fg: ControlContainer, private _el: ElementRef, private _renderer: Renderer2) {
  }

  ngOnInit() {
    this._renderer.addClass(this._el.nativeElement, 'invalid');
    this.control = this.form.get(this.invalidmessage);
    let formSubmit$ = (<FormGroupDirective>this._fg).ngSubmit.pipe(map(() => {
      this.hasSubmitted = true;
    }));
    this.controlValue$ = observableMerge(this.control.valueChanges, observableOf(''), formSubmit$);
    this.controlSubscription = this.controlValue$.subscribe(() => {
      this.setVisible();
    });
  }

  setVisible() {
    if (this.control.invalid && (this.control.dirty || this.hasSubmitted)) {
      this._renderer.removeStyle(this._el.nativeElement, 'display');
    } else {
      this._renderer.setStyle(this._el.nativeElement, 'display', 'none');
    }
  }

  match(error: string) {
    if (this.control && this.control.errors) {
      if (Object.keys(this.control.errors).indexOf(error) > -1) {
        return true;
      }
    }
    return false;
  }

  get form() {
    return this._fg.formDirective ? (this._fg.formDirective as FormGroupDirective).form : null;
  }

  ngOnDestroy() {
    this.controlSubscription.unsubscribe();
  }
}
