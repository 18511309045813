import {Component} from "@angular/core";
import {ModalService} from "./modal.service";

@Component({
  templateUrl: './modal.confirm.html'
})

export class ModalConfirmComponent extends ModalService implements IModalService {

  yes1() {
    super.notify(true);
    super.close();
  }

  yes2() {
    super.notify('yes2');
    super.close();
  }

}
