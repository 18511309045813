import {GenericList} from "../../business/model/list.generic.model";

export class CandidateExperience {

  companyName?: string;       // Company Name

  jobTitle?: string;          // Job Title

  startDate?: Date;           // Start date of a job

  endDate?: Date;             // End date of a job

  present: boolean;           // current job

  workField?: string;         // Field or industry of work

  workFieldID?: string;       // Work Field ID

  reasonForLeaving?: string;  // Reasons for leaving

  keyResponsibilities?: GenericList<string>;  // Key Responsibilities

  makeCopy() {
    return new CandidateExperience(this.companyName, this.jobTitle, this.startDate, this.endDate, this.present, this.workField, this.workFieldID, this.keyResponsibilities.clone(), this.reasonForLeaving);
  }

  constructor(companyName?: string, jobTitle?: string, startDate?: Date, endDate?: Date, present?: boolean, workField?: string, workFieldID?: string, keyResponsibilities?: GenericList<string>, reasonForLeaving?: string) {
    this.companyName = companyName;
    this.jobTitle = jobTitle;
    this.startDate = startDate;
    this.endDate = endDate;
    this.present = present;
    this.workField = workField;
    this.workFieldID = workFieldID;
    this.keyResponsibilities = keyResponsibilities;
    this.reasonForLeaving = reasonForLeaving;
  }

}
