import {Component} from "@angular/core";
import {ModalService} from "../../../shared/modal/modal.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  templateUrl: './battery.rename.modal.template.html'
})

export class BatteryRenameModalComponent extends ModalService implements IModalService {

  form: FormGroup;
  name: string = "";

  ngOnInit() {
    this.name = this.data.name;
    this.buildForm();
  }

  submit() {
    if (this.form.valid) {
      super.notify({name: this.name});
      super.close();
    }
  }

  buildForm() {
    this.form = new FormGroup({
      name: new FormControl(this.name, [Validators.required, Validators.maxLength(30)]),
    });
  }

  closeCross() {
    super.close();
  }

}
