import {Component, Input} from "@angular/core";
import {InvitesApiResource} from "../../individual/api/api.invites.service";
import {InvitesService} from "../../individual/service/invites.service";
import {MemberInviteJson} from "../model/memberinvitejson.model";

@Component({
  selector: 'test-finished-ask-decline',
  template: `
    <div *ngIf="(invite && (invite.status == 'A' ||  invite.status == 'D'))" class="card" style="font-size:0.8em; max-width: 600px">
      <div class="card-body center-horizontal">
        <ng-container *ngIf="(invite.status == 'A')">
          Your results will be shared with {{invite.companyName}}.
          If you don't want to share it with them, you can decline the invite.
          <br/><br/>
          <button class="btn btn-cliq" (click)="declineInvite()">Decline</button>
        </ng-container>
        <ng-container *ngIf="(invite.status == 'D')">
          Your results will not be shared with {{invite.companyName}}.
        </ng-container>
      </div>
    </div>
  `
})

export class TestFinishedAskDeclineComponent {

  invite: MemberInviteJson;
  _inviteKey: string;

  @Input() set inviteKey(val: string) {
    this._inviteKey = val;
    this.getInviteDetails();
  };

  getInviteDetails() {
    this.apiInvites.getInvite(this._inviteKey).subscribe(result => {
      this.invite = new MemberInviteJson(result.responseObject);
      console.log("   this.invite ", this.invite);
    })
  }

  declineInvite() {
    this.is.declineInvite(this.invite.key).then(() => {
      this.getInviteDetails();
    });
  }

  constructor(
    public is: InvitesService,
    public apiInvites: InvitesApiResource) {
  }

}
