import {Component, Input} from "@angular/core";
import {ValidationResult} from "./validation.result.model";

@Component({
  selector: 'validation-message',
  template:
    `
      <div class="alert alert-danger validation" role="alert" [hidden]="validation.valid || validation.detailMessage == null">
        <div *ngIf="message" [innerHTML]="message"></div>
        <div [innerHTML]="validation.detailMessage"></div>
      </div>
    `
})

export class ValidationComponent {
  @Input() validation: ValidationResult = new ValidationResult().setInvalid();
  @Input() message: string = null;
}
