/**
 * concepts from https://stackoverflow.com/questions/34489916/how-to-load-external-scripts-dynamically-in-angular
 */

import {Injectable} from "@angular/core";
import {NativeIncludeItems} from "./native.includes.items";
import {NativeIncludeItem} from "./native.include.item";

declare var document: any;

@Injectable()
export class NativeIncludeService {

  loadIncludes(includes: NativeIncludeItem[]) {
    let promises: any[] = [];
    includes.forEach((include) => promises.push(this.loadInclude(include)));
    return Promise.all(promises);
  }

  loadIncludesByName(...names: string[]) {
    let promises: any[] = [];
    names.forEach((name) => {
      let found = NativeIncludeItems.filter(inc => inc.name == name);
      if (found.length > 0) {
        promises.push(this.loadInclude(found[0]))
      }
    });
    return Promise.all(promises);
  }

  private loadInclude(include: NativeIncludeItem) {

    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (include.loaded) {
        resolve({nclude: include, loaded: true, status: 'Already Loaded'});
      } else {
        let tag = null;

        if (include.type == 'js') {
          tag = document.createElement('script');
          tag.type = 'text/javascript';
          tag.src = include.src;
        } else if (include.type == 'css') {
          tag = document.createElement('link');
          tag.type = 'text/css';
          tag.rel = "stylesheet";
          tag.href = include.src;
        }

        //console.log("including:", tag);

        if (tag.readyState) {  //internet explorer
          tag.onreadystatechange = () => {
            if (tag.readyState === "loaded" || tag.readyState === "complete") {
              tag.onreadystatechange = null;
              include.loaded = true;
              resolve({nclude: include, loaded: true, status: 'Loaded'});
            }
          };
        } else {  //Other browsers
          tag.onload = () => {
            include.loaded = true;
            resolve({nclude: include, loaded: true, status: 'Loaded'});
          };
        }
        tag.onerror = (error: any) => resolve({nclude: include.name, loaded: false, status: 'Loaded'});

        document.getElementsByTagName('head')[0].appendChild(tag);

      }

    });
  }

}
