/**
 * See MemberTest.java
 *
 */
import {AppConfig} from "../../admin/app.config";

export class MemberTest {

  testType?: string;

  reportType?: string;

  versionNumber?: number;

  dateCreated?: Date;

  dateUpdated?: Date;

  dateCompleted?: Date;

  statusCode?: string;

  completed?: boolean;

  testId?: number;

  versionRole?: any;

  get isExpired() {
    return this.statusCode == AppConfig.admin.testStatus.EXPIRED;
  }

  get isCompleted(): boolean {
    return (this.completed == true);
  }

  fromOther(other: any) {
    this.testType = other.testType;
    this.reportType = other.reportType;
    this.versionNumber = other.versionNumber;
    this.dateCreated = other.dateCreated;
    this.dateUpdated = other.dateUpdated;
    this.dateCompleted = other.dateCompleted;
    this.statusCode = other.statusCode;
    this.completed = other.completed;
    this.testId = other.testId;
    this.versionRole = other.versionRole;

    return this;
  }

}
