import {ModalService} from "../../../shared/modal/modal.service";
import {Component} from "@angular/core";
import {GenericList} from "../../model/list.generic.model";
import {Battery} from "../../model/battery.model";
import {Company} from "../../../shared/model/company.model";

@Component({
  template: `
    <modal-template [title]="'Invite candidates'" [disableClose]="saving" (onClose)="close()">

      <div style="min-height: 10em">

        <loading-indicator [show]="saving" [showBackdrop]="false"></loading-indicator>

        <div *ngIf="!saving">
          The selected candidates will be send an invite to share their results
          with {{company.companyName}}.
          <br/> <br/>
          <h6>Add candidates to this project:</h6>
          <ng-select
            style="width:300px;"
            [disabled]="false"
            [items]="projects"
            bindLabel="name"
            appendTo="body"
            [searchable]="false"
            [clearable]="false"
            [(ngModel)]="selectedProject">
          </ng-select>

          <br/>

          <div *ngIf="(selectedProject)">
            <button class="btn btn-primary" (click)="invite()">Invite candidates</button>
          </div>

        </div>
      </div>

    </modal-template>
  `
})

export class SearchInviteCandidatesModal extends ModalService implements IModalService {

  loading = true;
  selectedRows: any[];
  company: Company;
  projects: GenericList<Battery>;
  selectedProject: Battery;

  get candidateCount() {
    return this.selectedRows ? Object.keys(this.selectedRows).length : 0;
  }

  ngOnInit() {
    this.selectedRows = this.data.selectedRows;
    this.company = this.data.company;
    this.projects = this.data.projects;
    this.loading = false;
  }

  invite() {
    this.saving = true;
    let candidateIds = this.selectedRows.map(function (item) {
      return item['member_id'];
    });
    this.data.inviteCandidates(this.company.companyKey, this.selectedProject.id, candidateIds).subscribe((result => {
      this.saving = false;
      this.close();
    }));

  }
}
