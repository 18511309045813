import {Directive, ElementRef, Input} from '@angular/core';
import * as Inputmask from 'inputmask';

@Directive({
  selector: '[input-mask]',
})
export class InputMaskDirective {

  regexMap = {
    safetext: '[a-zA-Z0-9\\@\\&\\-\\,\\.\\:\\!\\?\\" ]+'
  };

  constructor(private el: ElementRef) {
  }

  @Input('input-mask')
  public set defineInputType(type: string) {
    Inputmask({regex: this.regexMap[type], placeholder: ''}).mask(this.el.nativeElement);
  }

}
