import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'cliquidity-checkbox',
  template: `
    <div class="cliquidity-checkbox" (click)="onClick()"
         [ngClass]="{'checked': checked, 'notchecked': !checked, 'readonly': readonly}">
      <i class="fa fa-check" aria-hidden="true"></i>
    </div>
  `,
  styles: []
})

export class CliquidityCheckbox {

  @Input() checked: boolean = false;
  @Input() readonly: boolean = false;
  @Input() item: any;
  @Output() beenClicked = new EventEmitter<any>();

  onClick() {
    if (this.item) {
      if (this.item.children && this.checked) {
        let hasElement = false;
        for (let i = 0; i < this.item.children.length; i++) {
          if (this.item.children[i].checked) {
            hasElement = true;
          }
        }
        if (!hasElement) {
          this.checked = !this.checked;
          this.item.checked = !this.item.checked;
        } else {
          for (let i = 0; i < this.item.children.length; i++) {
            this.item.children[i].checked = false;
          }
          this.checked = false;
          this.item.checked = false;
        }
      } else {
        this.checked = !this.checked;
        this.item.checked = !this.item.checked;
      }
      this.beenClicked.next(this.item.checked);
    } else {
      this.checked = !this.checked;
      this.beenClicked.next(this.checked);
    }
  }
}
