import {AgGridColumn} from "ag-grid-angular";

/**
 * For more properties, see https://www.ag-grid.com/javascript-grid-column-properties/
 */

export class ColumnBuilder extends AgGridColumn {

  public field: string;

  public headerName: string;

  //https://www.ag-grid.com/javascript-grid-column-definitions/index.php#gsc.tab=0
  public type;

  //https://www.ag-grid.com/javascript-grid-cell-styles/
  public cellStyle;

  //https://www.ag-grid.com/javascript-grid-cell-styles/#cell-class
  public cellClass;

  //https://www.ag-grid.com/javascript-grid-cell-styles/#cell-class
  public cellClassRules;

  //https://www.ag-grid.com/javascript-grid/column-properties/
  public headerClass;

  //https://www.ag-grid.com/javascript-grid-filter-number/#gsc.tab=0
  public filter;

  //https://www.ag-grid.com/javascript-grid-filter-text/#params
  public filterParams: any;

  public width: string;

  public suppressSizeToFit: boolean = false;

  public hide: boolean = false;

  public cellRenderer;

  public cellRendererFramework;

  public cellRendererParams;

  public sort: string;

  // should return a function
  public tooltip;

  public headerCheckboxSelection: boolean;

  public headerCheckboxSelectionFilteredOnly: boolean;

  public checkboxSelection: boolean;

  public rowMultiSelectWithClick: boolean;

  //https://www.ag-grid.com/javascript-grid-pinning/
  public pinned: string;

  make(_headerName, _field?) {
    this.headerName = _headerName;
    if (_field) {
      this.field = _field;
    }
    return this;
  }

  sortOrder(_sortingOrder: string) {
    this.sort = _sortingOrder;
    return this;
  }

  setCellTooltipFunction(_tooltipFunction: any) {
    this.tooltip = _tooltipFunction;
    return this;
  }

  setWidth(_width: any) {
    this.width = _width;
    this.suppressSizeToFit = true;
    return this;
  }

  setCellRenderer(_cellRenderer: any) {
    this.cellRenderer = _cellRenderer;
    return this;
  }

  setCellRendererFramework(_cellRendererFramework: any) {
    this.cellRendererFramework = _cellRendererFramework;
    return this;
  }

  setCellRendererParams(_cellRendererParams: any) {
    this.cellRendererParams = _cellRendererParams;
    return this;
  }

  setType(_type: string) {
    this.type = _type;
    return this;
  }

  // for eg {textAlign: "right"}
  setCellStyle(style: any) {
    this.cellStyle = style;
    return this;
  }

  // CSS class name or function
  setCellClass(className: any) {
    this.cellClass = className;
    return this;
  }

  // CSS class rule
  setCellClassRules(cellClassRules: any) {
    this.cellClassRules = cellClassRules;
    return this;
  }

  // CSS class name
  setHeaderClass(className: any) {
    this.headerClass = className;
    return this;
  }

  setTextColumnFilter() {
    this.filter = 'agTextColumnFilter';
    return this;
  }

  setCustomTextWithFilter(valueGetter) {
    this.setTextColumnFilter();
    this.filterParams =
      {
        textCustomComparator: function (filter, value, filterText) {
          return (value.indexOf(filterText) >= 0);
        },
        valueGetter: function (params) {
          return valueGetter(params);
        }
      }
    this.setCellRenderer(function (params) {
      return valueGetter(params);
    })

    return this;
  }

  setFilterParams(_params) {
    this.filterParams = _params;
    return this;
  }

  setFilter(_filter: string) {
    this.filter = _filter;
    return this;
  }

  setHidden(_hidden: boolean) {
    this.hide = _hidden;
    return this;
  }

  setCheckboxSelect() {
    this.headerCheckboxSelection = true;
    this.headerCheckboxSelectionFilteredOnly = true;
    this.checkboxSelection = true;
    this.rowMultiSelectWithClick = true;
    return this;
  }

  //position for eg "left"
  setPinned(position: string) {
    this.pinned = position;
    return this;
  }

  formatNumber(decimals: number) {
    this.cellRenderer = function (params) {
      return Number(params.value).toFixed(decimals)
    }
    return this;
  }

}
