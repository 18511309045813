// https://github.com/CaliStyle/ng-intercom
import {Component, Input} from "../../../node_modules/@angular/core";
import {CompanyService} from "../../business/comp/service/company.service";
import {SessionService} from "../service/session/session.service";
import {Intercom} from "../../../node_modules/ng-intercom";
import {SharedApiResource} from "../api/api.shared.service";
import {AppConfig} from "../../admin/app.config";

@Component({
  selector: 'intercom',
  template: `
  `
})
export class IntercomComponent {

  @Input() utmSource: string;

  ngOnInit() {

    this.api.smallActionPost('intercom', 'params', null).subscribe((result) => {
      let params = result.responseObject;
      if (params && params.user_hash) {
        this.intercom.boot({
          app_id: AppConfig.intercom_app_id,
          name: params.name,
          email: params.email,
          user_id: params.user_id,
          user_hash: params.user_hash,
          utm_source: this.utmSource,
        });
      } else {
        this.intercom.boot({
          app_id: AppConfig.intercom_app_id,
          utm_source: this.utmSource,
        })
        ;
      }
    })

  }

  constructor(
    public  intercom: Intercom,
    public  cs: CompanyService,
    public  sess: SessionService,
    public  api: SharedApiResource
  ) {
  }

}
