import {Competency} from "../../model/search/competency.model";
import {BusinessApiResource} from "../../api/api.business.service";
import {SearchData} from "../../model/search/search.data.model";
import {BusinessBaseComponent} from "../business.base.component";
import {SelectProvider} from "../../../shared/service/select.provider";
import {SearchCodes} from "./search.codes";
import {CompanyService} from "../service/company.service";
import {AppConfig} from "../../../admin/app.config";

/**
 * See SearchData.java
 */
export class SearchCriteria {

  competencyMap = {};         // old competency report scores, not used in search, to be removed
  softSkillsMap = {};
  softSkillsMatrixMap = {};
  workRelatedMap = {};
  chem1CompMap = {};

  selectProvider: SelectProvider = BusinessBaseComponent.injector.get(SelectProvider);

  results_limit = new Competency('SEARCH', 'LIMIT', 'Limit results to', null, SearchCodes.searchResultLimit, {
    k: this.scopeResultLimitDefault.code, d: this.scopeResultLimitDefault.d
  }, null, null);

  scope_where = new Competency('SEARCH', 'WHERE', 'Where to search', null, SearchCodes.searchScopeItems, {
    k: this.scopeWhereDefault.code, d: this.scopeWhereDefault.d
  }, null, null);

  scope_projects = new Competency('SEARCH', '', 'Search in specific Projects', null, null,
    null, this.selectProvider.getSmallAction('search-projects', null), null);

  // Biographic
  country = new Competency('BIO', 'country', 'Country', null, null, null, this.selectProvider.codeList("COUNTRY"), null);
  city = new Competency('BIO', 'town', 'City/Town', null, null, null, null, null);
  age = new Competency('BIO', 'agerange', 'Age', 'agerange', null, null, this.selectProvider.codeList("AGE_RANGE"), null);
  language = new Competency('BIO', 'lang', 'Language', 'search-language', null, null, null, this.selectProvider);
  gender = new Competency('BIO', 'gen', 'Gender', 'gen', null, null, this.selectProvider.codeList("GENDER_CODE"), null);
  maritalstatus = new Competency('BIO', 'mstatus', 'Relationship Status', 'mstatus', null, null, this.selectProvider.codeList("MARITAL_STATUS"), null);

  // Employment Equity
  ethnicity = new Competency('EQU', 'ethn', 'Ethnicity', 'ethn', null, null, this.selectProvider.codeList("ETHNICITY"), null);
  eyesight = new Competency('EQU', 'esight', 'Eye sight', 'esight', null, null, this.selectProvider.codeList("EYE_SIGHT"), null);
  disability = new Competency('EQU', 'dis', 'Disability', 'dis', null, null, this.selectProvider.codeList("DISABILITY"), null);

  // Work Preferences
  travel = new Competency('PREF', 'jobTravel', 'Possibility of travel', 'jobTravel', null, null, this.selectProvider.codeList("JOB_TRAVEL"), null);
  relocate = new Competency('PREF', 'jobRelocate', 'Possibility of relocation', 'jobRelocate', null, null, this.selectProvider.codeList("JOB_RELO"), null);
  worklev = new Competency('PREF', 'worklev', 'Type of work', 'worklev', null, null, this.selectProvider.codeList("WORK_LEVEL"), null);
  workgen = new Competency('PREF', 'workgen', 'Work preferences', 'workgen', null, null, this.selectProvider.codeList("WORK_GEN"), null);
  workor = new Competency('PREF', 'workor', 'Intellectual orientation at work', 'workor', null, null, this.selectProvider.codeList("WORK_OR"), null);
  workir = new Competency('PREF', 'workir', 'Interpersonal orientation at work', 'jobReworkirlocate', null, null, this.selectProvider.codeList("WORK_IR"), null);

  // Education
  education = new Competency('EDU', 'code', 'Educational field', 'search-education', null, null, null, this.selectProvider);
  educationLevel = new Competency('EDU', 'level', 'Educational level', 'level', null, null, this.selectProvider.codeList("EDU_FIELD"), null);
  workExp = new Competency('EDU', 'workind', 'Work experience field', 'search-industries', null, null, null, this.selectProvider);

  // CARA
  cara_learn = new Competency('CARA', 'L', 'Learning', null, SearchCodes.score4LevelItems, null, null, null);
  cara_logical = new Competency('CARA', 'LAR', 'Logical Analytical', null, SearchCodes.score4LevelItems, null, null, null);
  cara_speed = new Competency('CARA', 'S', 'Speed', null, SearchCodes.score4LevelItems, null, null, null);

  // VI
  vi_holland1 = new Competency('VI', 'HOLL1', 'VI 1st', null, SearchCodes.viHollandItems, null, null, null);
  vi_holland2 = new Competency('VI', 'HOLL2', 'VI 2nd', null, SearchCodes.viHollandItems, null, null, null);
  vi_holland3 = new Competency('VI', 'HOLL3', 'VI 3rd', null, SearchCodes.viHollandItems, null, null, null);

  // CHEM2
  chem2_life = new Competency('CHEM2', 'LIFE1', 'Life', null, SearchCodes.chem2LifeItems, null, null, null);
  chem2_enn = new Competency('CHEM2', 'ENN-R1', 'Enneagram', null, SearchCodes.chem2Enn, null, null, null);
  chem2_work_attitude = new Competency('CHEM2', 'WORK-ATT', 'Attitude', null, SearchCodes.chem2ATTItems, null, null, null);
  chem2_work_head = new Competency('CHEM2', 'WORK-HEAD', 'Head (Intellectual)', null, SearchCodes.chem2HEADItems, null, null, null);
  chem2_work_heart = new Competency('CHEM2', 'WORK-HEART', 'Heart (Involvement)', null, SearchCodes.chem2HEARTItems, null, null, null);
  chem2_work_feet = new Competency('CHEM2', 'WORK-FEET', 'Feet (Implementation)', null, SearchCodes.chem2FEETItems, null, null, null);

  // NR
  nr_level = new Competency('NR', 'NR-LEVEL', 'Level', null, SearchCodes.scoreLeveltems, null, null, null);

  get scopeResultLimitDefault() {
    return SearchCodes.searchResultLimit[0];
  }

  get scopeWhereDefault() {
    return SearchCodes.searchScopeItems[0];
  }

  get scopeCounts(): number[] {
    return [
      1
    ]
  }

  get biographicCounts(): number[] {
    return [
      this.country.selectedItems.length,
      this.city.selectedItems.length,
      this.age.selectedItems.length,
      this.language.selectedItems.length,
      this.gender.selectedItems.length,
      this.maritalstatus.selectedItems.length,
    ];
  }

  get employmentEquityCounts(): number[] {
    return [
      this.ethnicity.selectedItems.length,
      this.eyesight.selectedItems.length,
      this.disability.selectedItems.length
    ]
  };

  get workPrefsCounts(): number[] {
    return [
      this.travel.selectedItems.length,
      this.relocate.selectedItems.length,
      this.worklev.selectedItems.length,
      this.workgen.selectedItems.length,
      this.workor.selectedItems.length,
      this.workir.selectedItems.length
    ]
  }

  get educationCounts(): number[] {
    return [
      this.education.selectedItems.length,
      this.educationLevel.selectedItems.length,
      this.workExp.selectedItems.length
    ];
  }

  get personalityCounts(): number[] {
    let counts: number[] = [];
    Object.keys(this.chem1CompMap).forEach((key) => {
        counts.push(this.chem1CompMap[key].selectedItems.length);
      }
    )
    return counts;
  }

  get motivationCounts(): number[] {
    return [
      this.chem2_life.selectedItems.length,
      this.chem2_enn.selectedItems.length,
      this.chem2_work_attitude.selectedItems.length,
      this.chem2_work_head.selectedItems.length,
      this.chem2_work_heart.selectedItems.length,
      this.chem2_work_feet.selectedItems.length
    ];
  }

  get caraCounts(): number[] {
    return [
      this.cara_learn.selectedItems.length,
      this.cara_logical.selectedItems.length,
      this.cara_speed.selectedItems.length
    ];
  }

  get viCounts(): number[] {
    return [
      this.vi_holland1.selectedItems.length,
      this.vi_holland2.selectedItems.length,
      this.vi_holland3.selectedItems.length
    ];
  }

  get compCounts(): number[] {
    let counts: number[] = [];
    Object.keys(this.competencyMap).forEach((key) => {
        counts.push(this.competencyMap[key].selectedItems.length);
      }
    )
    return counts;
  }

  get softSkillsCounts(): number[] {
    let counts: number[] = [];
    Object.keys(this.softSkillsMap).forEach((key) => {
        counts.push(this.softSkillsMap[key].selectedItems.length);
      }
    )
    return counts;
  }

  get ssmCounts(): number[] {
    let counts: number[] = [];
    Object.keys(this.softSkillsMatrixMap).forEach((key) => {
        counts.push(this.softSkillsMatrixMap[key].selectedItems.length);
      }
    )
    return counts;
  }


  get higherDimWorkCounts(): number[] {
    let counts: number[] = [];
    Object.keys(this.workRelatedMap).forEach((key) => {
        counts.push(this.workRelatedMap[key].selectedItems.length);
      }
    )
    return counts;
  }

  get intCompCounts() {
    let list = [];
    SearchCodes.intCompetencies.forEach((c) => {
      list.push(this.competencyMap['COMP' + c.id].selectedItems.length);
    });
    return list;
  }

  get nrCounts() {
    return [
      this.nr_level.selectedItems.length
    ];
  }

  // return all search criteria fields as competencies
  private getAsCompetencies() {

    let list: Competency[] = [];

    // Biographic
    list.push(this.country);
    list.push(this.city);
    list.push(this.age);
    list.push(this.language);
    list.push(this.gender);
    list.push(this.maritalstatus);

    // Employment Equity
    list.push(this.eyesight);
    list.push(this.ethnicity);
    list.push(this.disability);

    // Work Preferences
    list.push(this.travel);
    list.push(this.relocate);
    list.push(this.worklev);
    list.push(this.workgen);
    list.push(this.workor);
    list.push(this.workir);

    // Education
    list.push(this.education);
    list.push(this.educationLevel);
    list.push(this.workExp);

    SearchCodes.intCompetencies.forEach((c) => {
      list.push(this.competencyMap['COMP' + c.id]);
    });

    SearchCodes.chem1Competencies.forEach((c) => {
      list.push(this.chem1CompMap[c.code]);
    });

    SearchCodes.higherDimSoftSkillCompetencies.forEach((c) => {
      list.push(this.softSkillsMap[c.code]);
    });

    Object.keys(this.softSkillsMatrixMap).forEach(function (key) {
      list.push(this.softSkillsMatrixMap[key]);
    }.bind(this))

    SearchCodes.higherDimWorkCompetencies.forEach((c) => {
      list.push(this.workRelatedMap[c.code]);
    });

    // CARA
    list.push(this.cara_learn);
    list.push(this.cara_speed);
    list.push(this.cara_logical);

    // VI
    list.push(this.vi_holland1);
    list.push(this.vi_holland2);
    list.push(this.vi_holland3);

    // CHEM2
    list.push(this.chem2_life);
    list.push(this.chem2_enn);
    list.push(this.chem2_work_attitude);
    list.push(this.chem2_work_head);
    list.push(this.chem2_work_heart);
    list.push(this.chem2_work_feet);

    // NR
    list.push(this.nr_level);

    return list;

  }

  getSearchData(): SearchData {

    let searchData: SearchData = new SearchData();
    searchData.rankCutoff = 1;

    this.cara_learn.scoreLevels = this.cara_learn.selectedItems;
    this.cara_speed.scoreLevels = this.cara_speed.selectedItems;
    this.cara_logical.scoreLevels = this.cara_logical.selectedItems;

    Object.keys(this.competencyMap).forEach((key) => {
        this.competencyMap[key].scoreLevels = this.competencyMap[key].selectedItems;
      }
    )

    Object.keys(this.chem1CompMap).forEach((key) => {
        this.chem1CompMap[key].scoreLevels = this.chem1CompMap[key].selectedItems;
      }
    )

    Object.keys(this.softSkillsMap).forEach((key) => {
        this.softSkillsMap[key].scoreLevels = this.softSkillsMap[key].selectedItems;
      }
    )

    Object.keys(this.softSkillsMatrixMap).forEach((key) => {
        this.softSkillsMatrixMap[key].scoreLevels = this.softSkillsMatrixMap[key].selectedItems;
      }
    )

    Object.keys(this.workRelatedMap).forEach((key) => {
        this.workRelatedMap[key].scoreLevels = this.workRelatedMap[key].selectedItems;
      }
    )

    this.vi_holland1.scoreLevels = this.vi_holland1.selectedItems;
    this.vi_holland2.scoreLevels = this.vi_holland2.selectedItems;
    this.vi_holland3.scoreLevels = this.vi_holland3.selectedItems;

    this.chem2_life.scoreLevels = this.chem2_life.selectedItems;
    this.chem2_enn.scoreLevels = this.chem2_enn.selectedItems;
    this.chem2_work_attitude.scoreLevels = this.chem2_work_attitude.selectedItems;
    this.chem2_work_head.scoreLevels = this.chem2_work_head.selectedItems;
    this.chem2_work_heart.scoreLevels = this.chem2_work_heart.selectedItems;
    this.chem2_work_feet.scoreLevels = this.chem2_work_feet.selectedItems;

    this.nr_level.scoreLevels = this.nr_level.selectedItems;

    this.getAsCompetencies().forEach(
      (c) => {
        let comp = new Competency(c.categoryCode, c.codeList, c.description, c.related, c.items, null, null, null);
        comp.selectedItems = (Array.isArray(c.selectedItems) ? c.selectedItems : [c.selectedItems]);
        comp.scoreLevel = c.scoreLevel;
        comp.scoreLevels = (Array.isArray(c.scoreLevels) ? c.scoreLevels : [c.scoreLevels]);
        searchData.competencies.push(comp);
      })

    // search country name, we should actually search on country code, but member prefs location lookup does not know about country codes
    searchData.countries = this.country.selectedItems.map(function (item) {
      return item['d'];
    });

    searchData.towns = this.city.selectedItems.map(function (item) {
      return item['d'];
    });

    let frmDataFields = [
      {searchField: 'efield', thisfield: 'education'},
      {searchField: 'elev', thisfield: 'educationLevel'},
      {searchField: 'workexp', thisfield: 'workExp'},
      {searchField: 'age', thisfield: 'age'},
      {searchField: 'lang', thisfield: 'language'},
      {searchField: 'disability', thisfield: 'disability'},
      {searchField: 'eyesight', thisfield: 'eyesight'},
      {searchField: 'maritalstatus', thisfield: 'maritalstatus'}
    ]

    frmDataFields.forEach(f => {
      searchData.frmData[f.searchField] = this[f.thisfield].selectedItems.map(function (item) {
        return item['k'];
      });
    })

    let searchDataFields = [
      {searchField: 'gender', thisfield: 'gender'},
      {searchField: 'ethnicity', thisfield: 'ethnicity'},
      {searchField: 'travel', thisfield: 'travel'},
      {searchField: 'relocate', thisfield: 'relocate'},
      {searchField: 'workgen', thisfield: 'workgen'},
      {searchField: 'workor', thisfield: 'workor'},
      {searchField: 'workir', thisfield: 'workir'},
      {searchField: 'worklev', thisfield: 'worklev'}
    ]

    searchDataFields.forEach(f => {
      searchData[f.searchField] = this[f.thisfield].selectedItems.map(function (item) {
        return item['k'];
      });
    });

    //selectedItems is now a scalar
    searchData.scopeWhere = this.scope_where.selectedItems['code'];
    searchData.resultLimit = this.results_limit.selectedItems['code'];

    searchData.scopeBatteryKeys = this.scope_projects.selectedItems.map(function (item) {
      return item['k'];
    });

    return searchData;

  }

  get hasCriteria(): boolean {
    let count = 0;
    this.getAsCompetencies().forEach(
      (comp) => {
        if (comp.selectedItems && comp.selectedItems.length > 0) {
          count++;
        }
      });
    return (count > 0);
  }

  clearCriteria() {
    this.getAsCompetencies().forEach(
      (comp) => {
        if (!comp.defaultSelectItem) {
          comp.selectedItems.length = 0;
          comp.clearSelected.emit();
        }
      })
    this.scope_where.setSelectedItems.emit(this.scopeWhereDefault.code);
  }

  private setIntegratedCompetencies() {

    SearchCodes.intCompetencies.forEach((c) => {
      let comp: Competency = new Competency('COMP', 'COMP' + c.id, c.description, null, SearchCodes.levelItems, null, null, null);
      this.competencyMap['COMP' + c.id] = comp;
    });

    SearchCodes.chem1Competencies.forEach((c) => {
      let comp: Competency = new Competency('CHEM1', c.code, c.description, null, SearchCodes.score5LevelItems, null, null, null);
      this.chem1CompMap[c.code] = comp;
    });

    SearchCodes.higherDimSoftSkillCompetencies.forEach((c) => {
      let comp: Competency = new Competency('SSM', c.code, c.description, null, SearchCodes.score5LevelItems, null, null, null);
      this.softSkillsMap[c.code] = comp;
    })

    SearchCodes.ssm.rows.forEach((r) => {
      SearchCodes.ssm.cols.forEach((c) => {
        let code = r + '-' + c;
        let comp: Competency = new Competency('SSM', code, AppConfig.admin.ssmShortNames[r] + ' ' + AppConfig.admin.ssmShortNames[c], null, SearchCodes.score7LevelHighItems, null, null, null);
        this.softSkillsMatrixMap[code] = comp;
      })
    })

    SearchCodes.higherDimWorkCompetencies.forEach((c) => {
      let comp: Competency = new Competency('SSM', c.code, c.description, null, SearchCodes.score5LevelItems, null, null, null);
      this.workRelatedMap[c.code] = comp;
    })
  }

  // populate saved talent pool criteria
  populateFromCriteria(sd: SearchData) {

    if (!sd.scopeWhere) {
      sd.scopeWhere = 'C';
    }

    if (sd) {

      this.getAsCompetencies().forEach(
        (thisComp) => {

          if (['BIO', 'EQU', 'PREF', 'EDU'].indexOf(thisComp.categoryCode) >= 0) {
            return;
          }

          let sdComp = null;

          let find = sd.competencies.filter(c => c.categoryCode == thisComp.categoryCode && c.codeList == thisComp.codeList);
          if (find.length > 0) {
            sdComp = find[0];
          }

          let levelCodes = [];

          if (sdComp) {
            let levels = sdComp['scoreLevels'];
            if (Array.isArray(levels)) {
              for (let i = 0; i < levels.length; i++) {
                if (levels[i] && levels[i]['code']) {
                  levelCodes.push(levels[i]['code']);
                }
                if (levels[i] && levels[i]['level']) {
                  levelCodes.push(levels[i]['level'].toString());
                }
              }
            }
          }

          if (levelCodes.length > 0) {
            thisComp.setSelectedItems.emit(levelCodes);
          } else {
            thisComp.clearSelected.emit();
          }

        });

      // Biographic
      this.country.setSelectedItems.emit(sd.countries);

      // City/Town
      if (sd.towns && sd.towns.length > 0) {
        let city = sd.towns[0];
        this.city.selectedItems = [{d: city}];
        this.city.payload = city;
      } else {
        this.city.selectedItems = [];
        this.city.payload = null;
      }

      this.age.setSelectedItems.emit(sd.frmData.age);
      this.language.setSelectedItems.emit(sd.frmData.lang);
      this.gender.setSelectedItems.emit(sd.gender);
      this.maritalstatus.setSelectedItems.emit(sd.frmData.maritalstatus);

      // Employment Equity
      this.ethnicity.setSelectedItems.emit(sd.ethnicity);
      this.eyesight.setSelectedItems.emit(sd.frmData.eyesight);
      this.disability.setSelectedItems.emit(sd.frmData.disability);

      // Work Preferences
      this.travel.setSelectedItems.emit(sd.travel);
      this.relocate.setSelectedItems.emit(sd.relocate);
      this.worklev.setSelectedItems.emit(sd.worklev);
      this.workgen.setSelectedItems.emit(sd.workgen);
      this.workor.setSelectedItems.emit(sd.workor);
      this.workir.setSelectedItems.emit(sd.workir);

      // Education
      this.education.setSelectedItems.emit(sd.frmData.efield);
      this.educationLevel.setSelectedItems.emit(sd.frmData.elev);
      this.workExp.setSelectedItems.emit(sd.frmData.workexp);

      this.scope_where.setSelectedItems.emit(sd.scopeWhere);
      this.scope_projects.setSelectedItems.emit(sd.scopeBatteryKeys);
    }

  }

  updateScopeSelect(showGlobal: boolean) {
    let items = SearchCodes.searchScopeItems;
    if (!showGlobal) {
      items = items.filter(i => i.code != 'G');
    }
    this.scope_where.items = items;
  }

  private subscribeReloadScopeProjects() {
    this.cs.OnChange().subscribe((company) => {
      this.scope_projects.clearSelected.emit(null);
      this.scope_projects = new Competency(this.scope_projects.categoryCode, this.scope_projects.codeList, this.scope_projects.description, null, null, null, this.selectProvider.getSmallAction('search-projects', company.companyKey), null);
    });
  }


  constructor(protected api: BusinessApiResource, protected cs: CompanyService) {
    this.setIntegratedCompetencies();
    this.subscribeReloadScopeProjects();
  }

}
