import {Component, Input, ViewChild} from '@angular/core';

/**
 * https://valor-software.com/ngx-bootstrap/#/popover
 */

@Component({
  selector: 'help-tooltip',
  template: `
    <ng-template #htmlTooltip let-close="close">
      <div [innerHtml]="html"></div>
      <a href="javascript:void(0)" class="pull-right" (click)="close(popover)">close</a>
    </ng-template>
    <span
      #popover="bs-popover"
      [popover]="ngTemplate"
      [outsideClick]="true"
      placement="bottom"
      popoverTitle="{{title}}"
      [containerClass]="popoverClass"
      [popoverContext]="context"
      container="body"
      fa [name]="'info-circle'">
    </span>
  `,
  styles: ['span {display:inline-block; vertical-align: text-bottom; color:#909090; font-size:1.2rem; cursor:pointer }']
})

export class HelpTooltip {

  @Input() title: string = '';
  @Input() html: string = '';   // show html
  @Input() ngTemplate;          // or attach an ng-container template object
  @Input() popoverClass: string = '';

  @ViewChild('popover') popover;
  @ViewChild('htmlTooltip') htmlTooltip;

  context = {
    close(t) {
      t.hide();
    }
  };

  ngOnInit() {
    if (this.html != '') {
      this.ngTemplate = this.htmlTooltip;
    }

  }

}
