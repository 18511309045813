import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'KeyValuesToList'})

export class KeyValuesToListPipe implements PipeTransform {
  transform(value: any, args?: any[]): Object[] {

    let returnArray = [];

    for (let entryKey in value) {
      let entryVal = value[entryKey];
      returnArray.push({
        key: entryKey,
        value: entryVal
      });
    }

    return returnArray;

  }

}
