import {Component, Input} from '@angular/core';
import {MemberInviteVirtualStatus} from "../../../shared/model/memberInvitevirtualstatus.model";

class InviteSummary {
    countA?: number;  // accepted
    countP?: number;  // pending
    countD?: number;  // declined

    get percentage(): any {
        let sum: number = this.countA + this.countP + this.countD;
        return {
            A: Math.round(this.countA / sum * 100),
            P: Math.round(this.countP / sum * 100),
            D: Math.round(this.countD / sum * 100)
        };
    }
}

@Component({
    selector: 'candidate-invite-graph',
    template: `
        <div class="progress invite-graph">
            <div class="progress-bar accepted" role="progressbar" [style.width]="(is.percentage.A)+'%'"></div>
            <div class="progress-bar declined" role="progressbar" [style.width]="(is.percentage.D)+'%'"></div>
            <div class="progress-bar pending" role="progressbar" [style.width]="(is.percentage.P)+'%'"></div>
        </div>
    `
})

export class CandidateInviteGraph {

    _virtualStatus: MemberInviteVirtualStatus;

    @Input() set virtualStatus(val: MemberInviteVirtualStatus) {
        this._virtualStatus = val;

        if (this._virtualStatus) {
            this.is.countA = this._virtualStatus.countAccepted;
            this.is.countD = this._virtualStatus.countDeclined;
            this.is.countP = this._virtualStatus.countPending;
        }
    }

    is: InviteSummary = new InviteSummary();

}
