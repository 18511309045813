import {Component, Input} from '@angular/core';
import {DebugModalComponent} from "./debug.modal.component";
import {BusinessBaseComponent} from "../business.base.component";

@Component({
  selector: 'debug-view',
  templateUrl: 'debug.view.html'
})

export class DebugViewComponent extends BusinessBaseComponent {

  _model: any;

  @Input() set model(val) {
    if (val != null) {
      this._model = val;
    }
  }

  openDetails() {
    this.modal.openModal(DebugModalComponent, this._model);
  }

}
