import {ScoreLevel} from "./scorelevel.model";
import {Observable} from "rxjs";
import {Select} from "../../../shared/model/select.model";
import {SelectProvider} from "../../../shared/service/select.provider";


/**
 * see Competency.java
 */
export class Competency extends Select {
  scoreLevel?: ScoreLevel;                    // selected slider result, sent to bigquery
  scoreLevels?: ScoreLevel[];                 // selected dropdown levels, sent to bigquery
  items?: ScoreLevel[];                       // predefined list for dropdown items
  payload: any;

  constructor(categoryCode: string, codeList: string, description: string, related: any, items: ScoreLevel[], defaultSelectItem, provider: Observable<any>, itemsProvider: SelectProvider) {
    super(categoryCode, codeList, description, related, defaultSelectItem, provider, itemsProvider);
    this.items = items;
    this.scoreLevel = new ScoreLevel();
  }

}
