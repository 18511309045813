import {Component, Input} from '@angular/core';
import {AppConfig} from "../../admin/app.config";

@Component({
  selector: 'loading-indicator',
  template: `
    <div *ngIf="show" class="loading" [ngClass]="{'loading-relative':relative}" [ngStyle]="loadingStyle">
      <div [ngClass]="{'loading-backdrop': showBackdrop }"></div>
    </div>`
})

export class LoadingIndicator {

  @Input() show: boolean = false;
  @Input() showBackdrop: boolean = false;
  @Input() relative: boolean = false;

  get loadingStyle() {
    return {
      'background-image': 'url(' + AppConfig.links.appUrl + '/images/cliquidity-loading.gif' + ')'
    }
  }

  ngOnInit() {
  }

}
