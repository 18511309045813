import {Component, ElementRef, Input} from '@angular/core';
import {WindowRef} from "../window.ref";
import {AppConfig} from "../../admin/app.config";
import {TranslationService} from "../service/translation.service";
import {SessionService} from "../service/session/session.service";

@Component({
  selector: 'action-button',
  template: `<a (click)="doAction()">
    <div class="{{data.linkClass}}"></div>
    <span [innerHTML]="data.linkText"></span>
  </a>`,
  styles: ['a {width: 11em}']
})

export class ActionButtons {

  data = {
    action: '',
    linkText: '[unknown action]',
    linkClass: '',                // for eg a font-awesome class
    extraClass: '',               // to be injected later on
    doFunction: null
  }

  @Input() hasIcon: boolean = true;
  @Input() param: string;
  @Input() subparam: string;
  @Input() text: string;

  @Input() set action(val: string) {
    this.data.action = val;
    this.setAction();
  }

  doAction() {
    this.data.doFunction();
  }

  setAction() {
    let _winref = this.winRef;
    let _native = _winref.nativeWindow;

    switch (this.data.action) {

      case AppConfig.buttonActions.ACTION_TELL: {
        this.data = $.extend(this.data, {
          linkText: this.ts.trans('SHARED4', 'Tell your friends').translated,
          linkClass: 'fas fa-share-alt',
          doFunction: function () {
            _winref.nativeWindow["CHEMAPI"].writeToWall();
          }
        })
        break;
      }

      case AppConfig.buttonActions.ACTION_REPORT: {
        let _this = this;
        this.data = $.extend(this.data, {
          linkText: _this.text || _this.ts.trans('SHARED5', 'Get your report').translated,
          linkClass: _this.hasIcon ? 'fas fa-file-invoice' : '',
          doFunction: function () {
            _native.getTestReport(_this.param, _this.subparam);
          }
        })
        break;
      }

      case AppConfig.buttonActions.ACTION_CONTINUE: {
        this.data = $.extend(this.data, {
          linkText: this.ts.trans('SHARED6', 'Continue').translated,
          linkClass: 'fas fa-arrow-right',
          doFunction: function () {
          }
        });
        break;
      }

      case AppConfig.buttonActions.ACTION_DOTEST: {
        let _this = this;
        console.log(this.sess.info);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const lang = urlParams.get('lang');
        // alert(lang);
        _this.subparam = '?lang=' + lang;
        this.data = $.extend(this.data, {
          linkText: _this.text,
          doFunction: function () {
            _winref.nativeWindow["CHEMAPI"].setAction($(_this.el.nativeElement), _this.param, _this.subparam);
          }
        })
        break;
      }

      case AppConfig.buttonActions.ACTION_ASSESSMENTS: {
        this.data = $.extend(this.data, {
          linkText: this.ts.trans('SHARED7', 'Assessments').translated,
          linkClass: 'fas fa-cogs',
          doFunction: function () {
            window.location.href = '/individual/assessments';
          }
        })
        break;
      }

      case AppConfig.buttonActions.ACTION_RETURN_PROVIDER: {
        let _this = this;
        this.data = $.extend(this.data, {
          linkText: 'Continue',
          linkClass: 'fas fa-arrow-right',
          doFunction: function () {
            window.location.href = _this.param;
          }
        })
        break;
      }

      case AppConfig.buttonActions.ACTION_CONTINUE: {
        this.data = $.extend(this.data, {
          linkText: 'Continue',
          linkClass: 'fas fa-arrow-right',
          doFunction: function () {
          }
        });
        break;
      }

    }
  }

  constructor(
    private winRef: WindowRef,
    private el: ElementRef,
    private ts: TranslationService,
    protected sess: SessionService
  ) {
  }

}
