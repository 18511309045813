import {Observable} from "rxjs";
import {AdminApiResource} from "../api/admin.api.service";
import {AppConfig} from "../app.config";
import {ApiStatusModel} from "./api.status.model";
import {ApiResultModel} from "./api.result.model";

export class ApiCommand {

  appConfig: any;

  public result: ApiResultModel;

  public status: ApiStatusModel;

  commandWithPayload(resultType: string, command: string, payload: any) {
    return Observable.create(observer => {
      this.status[resultType] = new ApiStatusModel();
      this.status[resultType].loading = true;
      this.result[resultType] = new ApiResultModel();
      this.api.commandWithPayload(command, payload).subscribe((result) => {
        console.log("result:", result);
        if (result.statusCode == 'E') {
          this.result[resultType].error = result.status;
          observer.next(result);
        } else {
          this.result[resultType] = result.responseObject; // result.responseObject.resultObject;
          observer.next(result.responseObject);
        }
        console.log("this.result:", this.result);
        this.status[resultType].loading = false;
        observer.complete();
      });
    });
  }

  constructor(public api: AdminApiResource) {
    this.appConfig = AppConfig;
    this.result = new ApiResultModel();
    this.status = new ApiStatusModel();
  }

}
