import {TestChemJson} from "./api/test.chem.json";
import {MemberInvitesExtendedJson} from "../../shared/model/memberinvitesextendedjson.model";
import {TranslationService} from "../../shared/service/translation.service";

export class CandidateAssessment {

  typeCode?: string;                     // see Types.TEST_TYPE
  reportType?: string;                   // see Types.REPORT_TYPE
  longName?: string;
  imageSrc?: string;
  measure?: string;
  feedBack?: string;
  cost?: string;
  time?: string;
  infoURL?: string;
  visible?: boolean;
  redoAllowed?: boolean;
  candidateTest?: TestChemJson;
  action: string;                        // see DashboardStep.ACTION_*

  mie: MemberInvitesExtendedJson;

  /**
   * [Invite status code][Complete or not completed]
   */
  get combinedStatus(): string {
    return this.mie.i.status + (this.testCompleted ? 'C' : 'N')
  }

  get testCompleted(): boolean {
    if (this.candidateTest) {
      return (this.candidateTest.testCompleted || this.progress == 100);
    }
    return false;
  }

  get progress(): number {
    let p = 0;
    if (this.candidateTest) {
      p = this.candidateTest.percentComplete;
    }
    return p;
  }

  get status(): string {
    let status = this.reportType != 'COMP' ? 'Not started' : 'One or more of the required tests (Personality, Motivation, Cognition) assessments have not been completed';
    if (this.candidateTest) {
      let p = this.candidateTest.percentComplete;
      if (this.candidateTest.testCompleted) {
        status = this.reportType != 'COMP' ? 'Completed' : 'Report available';
      } else if (p > 0) {
        status = 'Started, but not completed';
      }
    }
    return status;
  }

  makeCopy() {
    return new CandidateAssessment(this.typeCode, this.reportType, this.longName, this.imageSrc, this.measure, this.feedBack, this.cost, this.time, this.visible, this.redoAllowed, this.infoURL);
  }

  constructor(typeCode ?: string, reportType ?: string, longName ?: string, imageSrc ?: string, measure ?: string, feedBack ?: string, cost ?: string, time ?: string, visible?: boolean, redoAllowed?: boolean, infoURL ?: string, candidateTest ?: TestChemJson, action?: string) {
    this.typeCode = typeCode;
    this.reportType = reportType;
    this.longName = longName;
    this.imageSrc = imageSrc;
    this.measure = measure;
    this.feedBack = feedBack;
    this.cost = cost;
    this.time = time;
    this.visible = visible;
    this.redoAllowed = redoAllowed;
    this.infoURL = infoURL;
    this.candidateTest = candidateTest;
    this.action = action;
  }

}
