export class ValidationResult {

  statusCode: string;

  status: string;

  resultCode: string;

  detailMessage: string;

  valid: boolean = true;

  invalid: boolean = false;

  setValid() {
    this.invalid = false;
    this.valid = true;
    this.detailMessage = '';
    return this;
  }

  setValidIf(valid: boolean, invalidMessage: string) {
    if (!valid) {
      this.setInvalid(invalidMessage);
    } else {
      this.setValid();
    }
    return this;
  }

  setInvalid(detailMessage?: string) {
    this.invalid = true;
    this.valid = false;
    this.detailMessage = detailMessage;
    return this;
  }

  addInvalid(detailMessage?: string) {
    this.invalid = true;
    this.valid = false;
    this.detailMessage += detailMessage + '<br/>';
  }

  clear() {
    this.invalid = false;
    this.valid = !this.invalid;
  }

  setFrom(other) {
    if (other != null) {
      this.statusCode = other.statusCode;
      this.status = other.status;
      this.resultCode = other.resultCode;
      this.detailMessage = other.detailMessage;
      this.invalid = (this.statusCode == 'E');
      this.valid = !this.invalid;
    }
  }

  constructor(other?) {
    this.setFrom(other);
  }

}
