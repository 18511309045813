import {map} from 'rxjs/operators';
import {AppConfig} from "../../admin/app.config";
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";

export class ApiBase {

  url = AppConfig.links.appUrl + '/admin2-api/';
  adminApi = AppConfig.links.appUrl + '/admin/admin-api/';
  cmdurl = this.url + 'cmd/';
  apiUrl = AppConfig.links.appUrl + '/api/';

  headers = new HttpHeaders({
    // 'Accept': 'application/json',
    // 'Content-Type': 'application/json'
  });

  requestOptions = {
    params: new HttpParams(),
    headers: this.headers,
    withCredentials: true
  };

  get(url: string, payload: any = {}): Observable<any> {
    this.requestOptions.params = new HttpParams({fromObject: payload});
    return this.http.get(url, this.requestOptions).pipe(map(result => {
      return result;
    }));
  }

  post(url: string, payload: any): Observable<any> {
    return this.http.post(url, JSON.stringify(payload), this.requestOptions).pipe(map(result => {
      return result;
    }));
  }

  postBlob(url: string, blob: any): Observable<any> {
    return this.http.post(url, blob, this.requestOptions).pipe(map(result => {
      return result;
    }));
  }

  constructor(public http: HttpClient) {
  }

}
