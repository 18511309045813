export class TranslationResult {

  transFound: boolean = false;

  // may contain additional html if debugging is enabled
  translated: string;

  //for debugging
  tooltip: string;

  constructor(transFound: boolean, translated: string, tooltip: string) {
    this.transFound = transFound;
    this.translated = translated;
    this.tooltip = tooltip;
  }

}
