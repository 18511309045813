import {Component} from '@angular/core';
import {BusinessBaseComponent} from "../business.base.component";
import {SearchDataAndCriteria} from "../../model/search/search.data.and.criteria.model";

@Component({
  selector: 'search-page',
  templateUrl: 'search.page.html'
})

export class SearchPageComponent extends BusinessBaseComponent {

  searchResult = null;     // BigQueryResult.java->results
  sdc: SearchDataAndCriteria = null;

  search(sdc: SearchDataAndCriteria) {
    this.sdc = sdc;
    let sd = sdc.searchData;
    let sc = sdc.searchCriteria;
    this.loading = true;
    this.api.batterySearch(sd).subscribe((result) => {
      this.searchResult = result.responseObject.results;
      this.loading = false;
    });
  }

  clearSearch() {
    this.searchResult = null;
  }

  ngOnInit() {
    this.loading = false;
  }

}
