import {InvitesApiResource} from "../api/api.invites.service";
import {Inject, Injectable} from "../../../node_modules/@angular/core";
import {AppConfig} from "../../admin/app.config";

@Injectable()
@Inject(InvitesApiResource)
export class InvitesService {

  alertPendingInvites: boolean = false;

  public checkForPendingInvites() {

    this.getAllInvites().then((result) => {
      if (result && result.pending) {
        let pendingCompanies = Object.keys(result.pending).length;
        if (pendingCompanies > 0) {
          this.alertPendingInvites = true;
        }
      }
    });
  }

  public acceptInvite(inviteKey: string) {
    return this.updateInvite(inviteKey, AppConfig.invites.INVITE_STATUS_ACCEPTED);
  }

  public declineInvite(inviteKey: string) {
    return this.updateInvite(inviteKey, AppConfig.invites.INVITE_STATUS_DECLINED);
  }

  public getAllInvites() {
    return new Promise<any>((resolve, reject) => {
      this.api._getInvitesExtended(AppConfig.invites.LIST_TYPE_ALL_COMPANIES, AppConfig.invites.CONNECTION_TYPE_BATTERY, AppConfig.invites.CONNECTION_TYPE_BATTERY).subscribe((result) => {
        resolve(result.responseObject);
      });
    });
  }

  private updateInvite(inviteKey, inviteAction) {
    return new Promise<any>((resolve, reject) => {
      this.api._updateInvite(inviteKey, inviteAction).subscribe((result) => {
        resolve(result);
      });
    });
  }

  constructor(public api: InvitesApiResource) {
  }

}
