import {Component, EventEmitter, HostListener, Input, Output} from "@angular/core";
import {BusinessRoutes} from "../../business.routes";
import {BusinessBaseComponent} from "../business.base.component";
import {Company} from "../../../shared/model/company.model";
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'business-menu',
  templateUrl: './business.menu.html',
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})

export class BusinessMenuComponent extends BusinessBaseComponent {

  get routes() {
    return BusinessRoutes;
  }

  innerWidth: any;
  showActions: boolean = true;

  @Output() showMobileMenu = new EventEmitter();

  @Input() set companySelected(val: Company) {
    if (val) {
      this.cs.company = val;
    }
  }

  loginClick() {
    this.bls.loginClick();
  }

  logoutClick() {
    this.bls.logoutClick();
  }

  ngOnInit() {
    this.cs.OnLoaded().subscribe(companies => {
      this.detectChanges();
    });
    this.innerWidth = window.innerWidth;
    this.showActions = this.checkActionSize();
  }

  openMobileMenu() {
    this.showActions = !this.showActions;
    this.showMobileMenu.next(this.showActions);
  }

  openHelp() {
    window.open(this.sess.info.LURL + '/help/')
  }

  checkActionSize() {
    return this.innerWidth > 700;
  }

  getGridArea(section, number) {
    let text = section + number;
    return ({'grid-area': text});
  }

  @HostListener('window:resize', ['$event'])

  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.showActions = this.checkActionSize();
    this.showMobileMenu.next((this.showActions && !this.checkActionSize()));
  }

  hideMobileMenu() {
    if (!this.checkActionSize()) {
      this.showActions = false;
      this.showMobileMenu.next(false);
    }
  }


}
