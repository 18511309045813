import {Component} from '@angular/core';
import {Battery} from "../../model/battery.model";
import {Candidate} from "../../model/candidate.model";
import {GenericList} from 'business/model/list.generic.model';
import {ValidationResult} from "../../../shared/service/validation/validation.result.model";
import {ModalService} from "../../../shared/modal/modal.service";
import {BusinessApiResource} from "../../api/api.business.service";
import {CompanyService} from "../service/company.service";
import {ApiResult} from "../../../shared/model/api.result.model";

@Component({
  templateUrl: './battery.add.modal.template.html'
})

export class BatteryAddModalComponent extends ModalService implements IModalService {

  newCandidatesBattery: Battery = new Battery();
  candidateValidator: ValidationResult = new ValidationResult().setValid();
  bapi: BusinessApiResource = this.injector.get(BusinessApiResource);
  cs: CompanyService = this.injector.get(CompanyService);
  battery: Battery;

  ngOnInit() {
    this.newCandidatesBattery.candidates = new GenericList<Candidate>();
    this.battery = new Battery().fromOther(this.data);
  }

  addCandidates() {
    this.loading = true;

    this.battery.candidates = new GenericList<Candidate>();
    for (let i = 0; i < this.newCandidatesBattery.candidates.length; i++) {
    }

    let batteryJson = JSON.stringify(this.newCandidatesBattery);
    batteryJson = batteryJson.replace(/\+/g, "%2B");
    let payload = {
      battery: JSON.parse(batteryJson),
      batteryId: this.battery.id
    };
    this.bapi.batteryPostAction('addcandidates', payload).subscribe((result) => {
      this.loading = false;
      let apiResult = new ApiResult(result);
      if (apiResult.valid) {
        let newCandidates = apiResult.responseObject;
        super.notify(newCandidates);
        super.close();
      } else {
        alert(`${apiResult.status}`);
      }
    })
  }

  onValidate(event) {
    this.candidateValidator = event;
  }

}
