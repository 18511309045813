import {ChangeDetectorRef, EventEmitter, Injectable, Injector, Input, NgZone, Output} from '@angular/core';
import {AppConfig} from "../../admin/app.config";
import {FormBuilder} from '@angular/forms';
import {WindowRef} from "../../shared/window.ref";

@Injectable()
export class BaseBaseComponent {

  /**
   * Allows for retrieving singletons using `AppModule.injector.get(MyService)`
   * This is good to prevent injecting the service as constructor parameter.
   */
  static injector: Injector;

  @Output() onRowSelect = new EventEmitter();
  @Input() loading: boolean = true;

  rowsSelected = [];
  config = AppConfig;

  rowSelect({selected}) {
  }

  detectChanges() {
    setTimeout(() => {
      if (!this.ref['destroyed']) {
        this.ref.detectChanges();
      }
    }, 100);
  }

  scrollTo(elementId: string) {
    let el = $('#' + elementId);
    if (el.length) {
      $('html, body').animate({
        scrollTop: el.offset().top
      }, 200, 'linear')
    }
  }

  stopPropagation(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  constructor(
    public fb: FormBuilder,
    public zone: NgZone,
    public ref: ChangeDetectorRef,
    public winRef: WindowRef,
    public injector: Injector
  ) {
    BaseBaseComponent.injector = injector;
  }

}
