import {Inject, Injectable} from "@angular/core";
import {ApiBase} from "../../shared/api/api.base.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
@Inject(HttpClient)
export class AssessmentApiResource extends ApiBase {

  _getDashboardSection(section: string): Observable<any> {
    return this.get(this.apiUrl + 'session/dashboard/' + section + '/');
  }

  //see RestListTests->ACTION_LIST_COMPLETED_TESTS
  _getCandidateLatestTestsCompleted(payload: any): Observable<any> {
    return this.get(this.apiUrl + 'session/tests/allcompleted', payload);
  }

  //see RestListTests->ACTION_LIST_TESTS
  _getCandidateTests(): Observable<any> {
    return this.get(this.apiUrl + 'session/tests/all');
  }

  //see RestListTests->ACTION_LIST_COMPLETED_VERSIONS
  _getAllVersionsForCandidate(): Observable<any> {
    return this.get(this.apiUrl + 'session/tests/allversions');
  }

  getRPGameAction(action: string): Observable<any> {
    return this.get(this.apiUrl + "session/epgame/" + action);
  }

  constructor(http: HttpClient) {
    super(http);
  }

}
