export class ApiResultModel {

  endpointCreate: any = {statusCode: '', loading: false};
  endpointAdd: any = {statusCode: '', loading: false};
  endpointResults: any = {statusCode: '', loading: false};

  command: any = {};

  create: any = {loading: false};

  reset: any = {loading: false};

  workflow: any = {
    session: {
      test: {gameLink: '', id: 0}, cand: {id: 0}, job: {id: 0}
    },
    initAssessment: {returnUrl: ''},
    candidateProfile: {friendly: {}},
    jobProfile: {
      assessmentsFriendly: {}, entityUrl: '', folder: {friendly: ''}
    },
    entityUrl: ''
  };

  person: any = {};

  job: any = {};

  assessresults: any = {};

  assessresultscore: any = {};

}
