import {CandidateAssessment} from "./candidate.assessment.model";
import {CandidateTests} from "./candidate.tests.model";
import {GenericList} from "../../business/model/list.generic.model";

export class CandidateAssessments {

  assessments: GenericList<CandidateAssessment>;

  tests: CandidateTests;

  chem1: CandidateAssessment;
  chem2: CandidateAssessment;
  cara: CandidateAssessment;
  vi: CandidateAssessment;
  rp: CandidateAssessment;
  nr: CandidateAssessment;
  epg: CandidateAssessment;
  comp: CandidateAssessment;

}
