import {BusinessBaseComponent} from "../business.base.component";
import {Component, EventEmitter, Input, Output} from "@angular/core";
import {GridOptionsExtended} from "../../../shared/comp/ag-grid/grid.options.extended";
import {ColumnBuilder} from "../../../shared/comp/ag-grid/grid.columndef.builder";
import {AppConfig} from "../../../admin/app.config";
import * as moment from "moment";
import {SearchCandidateDetailsModal} from "./search.candidate.details.modal";
import {SearchDataAndCriteria} from "../../model/search/search.data.and.criteria.model";
import {AgGridColumn} from "ag-grid-angular";
import {CellProgressbarComponent} from "../../../shared/comp/ag-grid/grid.progressbar.component";
import {SearchInviteCandidatesModal} from "./search.invite.candidates.modal";
import {Battery} from "../../model/battery.model";
import {GenericList} from "../../model/list.generic.model";
import {Group} from "../../../shared/model/group.model";
import {Observable} from "rxjs";
import {CellButtonComponent} from "../../../shared/comp/ag-grid/grid.cellbutton.component";
import {CellSearchResultIconsComponent} from "../../../shared/comp/ag-grid/grid.cell.searchresult.icons.component";

@Component({
  selector: 'search-result-grid',
  templateUrl: 'search.result.grid.html'
})

export class SearchResultGridComponent extends BusinessBaseComponent {

  _data: [];
  grid: GridOptionsExtended;
  columnDefs;
  matchColumns: any[] = [];
  rowData: any[];
  selectedRows: any[] = [];
  addingToPool: boolean = false;
  addingToFavorites: boolean = false;

  @Input() sdc: SearchDataAndCriteria;
  @Input() poolSelected: Group = null;
  @Input() showAddToPool: boolean = false;
  @Input() showRemove: boolean = false;
  @Input() showInvite: boolean = false;
  @Input() showAddFavorites: boolean = false;

  @Input() set data(val: []) {
    this._data = val;
    this.setGridData();
  }

  @Input() refreshGrid = new EventEmitter();
  @Input() onAddedToPool = new EventEmitter();

  @Output() onAddToPool = new EventEmitter();
  @Output() onRemove = new EventEmitter();

  setGridData() {

    if (!this.grid) {
      this.grid = new GridOptionsExtended(this);
      this.grid.rowSelection = 'multiple';
      this.grid.onSelectionChangedCallback = function ($event) {
        this.onRowSelectionChanged($event);
      }.bind(this)
    } else {
      this.grid.columnDefs = [];
    }

    this.matchColumns = [];

    if (this._data.length > 0) {
      //set column names from first row in results
      let valueItem = this._data['0'].value;
      for (let [fieldName, value] of Object.entries(valueItem)) {
        if (fieldName.startsWith('match_')) {
          let fieldCode = fieldName.replace('match_', '');
          this.matchColumns.push({fieldCode: fieldCode});
        }
      }
    }

    let rowData: any[] = [];
    Object.keys(this._data).forEach((key) => {
      let id = this._data[key].key;
      let valueItem = this._data[key].value;
      let row = [];
      row['member_id'] = valueItem['member_id'];
      row['member_alias'] = valueItem['member_alias'];
      row['rank_percent'] = Number(valueItem['rank_percent']);
      row['last_login'] = valueItem['last_login'];
      row['isinvited'] = valueItem['isinvited'];
      row['isaccepted'] = valueItem['isaccepted'];
      row['isfavorite'] = valueItem['isfavorite'];
      row['istalentpool'] = valueItem['istalentpool'];
      row['detailsallowed'] = valueItem['detailsallowed'];
      for (let [fieldName, value] of Object.entries(valueItem)) {
        if (fieldName.startsWith('match_')) {
          let fieldCode = fieldName.replace('match_', '');
          row[fieldCode] = value;
        }
      }
      rowData.push(row);
    })

    let _this = this;

    let defs1 = [
      {
        headerName: '',
        children: [

          new ColumnBuilder()
            .make("Candidate", "member_alias")
            .setPinned("left")
            .setWidth(210)
            .setCheckboxSelect()
            .setCellRendererFramework(CellSearchResultIconsComponent),

          new ColumnBuilder()
            .make("", "")
            .setPinned("left")
            .setWidth(80)
            .setCellRendererFramework(CellButtonComponent)
            .setCellRendererParams({
              getlabel: function (data) {
                return 'Profile';
              },
              callback: function (data) {
                _this.viewProfile(data.member_id);
              }
            }),

          new ColumnBuilder()
            .make("Match %", "rank_percent")
            .setPinned("left")
            .setWidth(120)
            .sortOrder('desc')
            .setCellRendererFramework(CellProgressbarComponent).setCellRendererParams({
            getValue: function (data) {
              return data.rank_percent;
            }
          }),

          new ColumnBuilder()
            .make("Last Active", "last_login")
            .setWidth(130)
            .setCellRenderer(function (params) {
              if (params.value) {
                return moment.unix(params.value / 1000).format(AppConfig.admin.dateFormat);
              }
            })
        ]
      }
    ]

    let defs2 = [];
    let columnGroups = new Map<string, Array<AgGridColumn>>();

    this.sdc.searchCriteria.getSearchData().competencies.forEach(comp => {
      let matchCol = this.matchColumns.find(mc => mc.fieldCode.toUpperCase() == comp.codeList.replace('-', '_').toUpperCase());

      if (!matchCol) {
        //for eg fieldCode = chem1_SE_DE
        matchCol = this.matchColumns.find(mc => mc.fieldCode.toUpperCase() == comp.categoryCode + '_' + comp.codeList.replace('-', '_').toUpperCase());
      }

      if (matchCol) {
        let fieldCode = matchCol.fieldCode;
        let headerName = (comp ? comp.description : fieldCode);
        if (columnGroups.get(comp.categoryCode) === undefined) {
          columnGroups.set(comp.categoryCode, new Array());
        }

        columnGroups.get(comp.categoryCode).push(new ColumnBuilder()
          .make(headerName, fieldCode)
          .setHeaderClass('match-column')
          .setCellClass('match-column')
          .setWidth(150)
          .setCellRenderer(function (params) {
            let iconClass = (params.value == '1' ? 'fas fa-check-circle fa-lg match' : 'no-match');
            let html = `<div class="icon ${iconClass}"></div>`;
            return html;
          })
          .setCellClassRules({
              'match': function (params) {
                return params.value == '1';
              },
              'no-match': function (params) {
                return params.value == '0';
              }
            }
          ))

      }

    });

    columnGroups.forEach((value, key) => {
      defs2.push(
        {
          headerName: this.config.admin.searchCodelistNames[key],
          children: columnGroups.get(key)
        }
      )
    });

    this.columnDefs = defs1.concat(defs2);
    this.rowData = rowData;

  }

  viewProfile(memberId) {
    let promise = this.api.batteryAction("searchgetcandidatedetails", {
      companyKey: this.cs.company.companyKey,
      id: memberId
    });
    this.modal.openModal(SearchCandidateDetailsModal, {
      promise: promise,
      memberId: memberId
    }, {class: 'modal-lg'}).subscribe(result => {
    });
  }

  exportToCsv() {
    this.grid.api.exportDataAsCsv(this.getCSVExportParams());
  }

  addSelectedToPool() {
    this.addingToPool = true;
    this.onAddToPool.emit(this.selectedRows);
    this.grid.api.deselectAll();
  }

  addSelectedToFavorites() {
    this.addingToFavorites = true;

    let candidateIds = this.selectedRows.map(function (item) {
      return item['member_id'];
    });

    this.api.groupPostAction('addcandidatesfavorites', {
      companyId: this.cs.company.companyId,
      candidateIds: candidateIds
    }).subscribe((result) => {
      this.addingToFavorites = false;
    });

  }

  removeSelected() {
    this.onRemove.emit(this.selectedRows);
    this.grid.api.deselectAll();
  }

  inviteSelectedToProject() {

    let projects = new GenericList<Battery>();

    if (this.cs.company) {
      this.api.batteryAction("list", {companyKey: this.cs.company.companyKey}).subscribe((result) => {
        projects.set(<Array<Battery>>result.responseObject);
        this.modal.openModal(SearchInviteCandidatesModal, {
          company: this.cs.company,
          projects: projects,
          selectedRows: this.selectedRows,
          inviteCandidates: (companyKey, batteryId, candidateIds) => {
            return new Observable(observer => {
              this.inviteCandidates(companyKey, batteryId, candidateIds).subscribe((result) => {
                observer.next(result);
                observer.complete();
              });
            })
          }
        }, {}).subscribe(result => {
        });
      });
    }

  }

  inviteCandidates(companyKey, batteryId, candidateIds) {
    return new Observable(observer => {
      this.api.batteryPostAction("addsearchresultcandidates", {
        companyKey: companyKey,
        batteryId: batteryId,
        candidateIds: candidateIds
      }).subscribe((result) => {
        observer.next(result);
        observer.complete();
      });
    })
  }

  private getCSVExportParams() {
    let params = {
      skipHeader: false,
      allColumns: true,
      fileName: "search_result_export.csv",
      processCellCallback: function (params) {
        if (params.column.colId == 'last_login') {
          if (params.value) {
            return moment.unix(params.value / 1000).format(AppConfig.admin.dateFormat);
          } else {
            return '';
          }
        }
        return params.value;
      }
    };
    return params;
  }

  private onRowSelectionChanged($event: any) {
    this.selectedRows = this.grid.api ? this.grid.api.getSelectedRows() : 0;
  }

  ngOnInit() {

    if (this.onAddedToPool) {
      this.onAddedToPool.subscribe(() => {
        this.addingToPool = false;
      })
    }

    if (this.refreshGrid) {
      this.refreshGrid.subscribe(() => {
        this.setGridData();
      });
    }
  }

}
