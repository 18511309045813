import {Component} from "@angular/core";
import {ModalService} from "../../../shared/modal/modal.service";

@Component({
  templateUrl: 'search.candidate.details.modal.html'
})

export class SearchCandidateDetailsModal extends ModalService implements IModalService {

  res: any; // BigQueryMemberResults.java

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.data.promise.subscribe((result) => {
      this.res = result.responseObject;
      this.loading = false;
    })
  }

}
