import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";

//https://embed.plnkr.co/J04rdB/

@Component({
  template: `
    <button class="btn btn-info cell-button" (click)="callback()">{{this.label}}</button>
  `
})

export class CellButtonComponent implements ICellRendererAngularComp {

  refresh(params: any): boolean {
    return undefined;
  }

  public params: any;
  public label: string;

  agInit(params: any): void {
    this.params = params;

    this.label = params.label;
    if (params.labelField) {
      this.label = this.params.data[params.labelField];
    }

    this.getlabel();
  }

  public getlabel() {
    if (typeof this.params.getlabel === 'function') {
      this.label = this.params.getlabel(this.params.data);
    }
  }

  public callback() {
    this.params.callback(this.params.data);
  }

}
