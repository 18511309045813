import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'wizard-step',
  template:
    `
      <div [hidden]="!isActive">
        <ng-content></ng-content>
      </div>
    `
})
export class WizardStepComponent {
  @Input() title: string;
  @Input() name: string;
  @Input() hidden: boolean = false;
  @Input() isValid: boolean = true;
  @Input() showNext: boolean = true;
  @Input() showPrev: boolean = true;

  @Output() onNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPrev: EventEmitter<any> = new EventEmitter<any>();
  @Output() onComplete: EventEmitter<any> = new EventEmitter<any>();

  _isActive: boolean = false;
  isDisabled: boolean = true;

  constructor() {
  }

  @Input('isActive')
  set isActive(isActive: boolean) {
    this._isActive = isActive;
    this.isDisabled = false;
  }

  get isActive(): boolean {
    return this._isActive;
  }

}
