import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Injectable} from '@angular/core';
import {SessionService} from "../shared/service/session/session.service";
import {Observable} from "rxjs";

@Injectable()
export class RoutesCanAccess implements CanActivate {

  constructor(private router: Router, private sess: SessionService) {
  }

  checkCanActivate(loggedIn: boolean, hasPermission: boolean, isBrowserSupported: boolean, route: ActivatedRouteSnapshot): boolean {
    let currentPath = route.routeConfig.path;
    let result: boolean = false;

    if (hasPermission) {
      result = true;
    } else {
      if (this.sess.pendingUrl) {
        this.router.navigate([this.sess.pendingUrl]);
        this.sess.pendingUrl = null;
      } else if (currentPath != 'login') {
        this.router.navigate(['/login']);
        result = false;
      } else {
        result = true;
      }
    }

    return result;

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      if (!this.sess.hasPermission) {
        this.sess.OnAuthResult().subscribe((result: any) => {
          resolve(this.checkCanActivate(result.loggedIn, result.hasPermission, result.isBrowserSupported, route));
        });
      } else {
        resolve(this.checkCanActivate(this.sess.loggedIn, this.sess.hasPermission, this.sess.isBrowserSupported, route));
      }
    });
  }

}
