// see https://scotch.io/tutorials/angular-2-form-validation

import {Component} from "@angular/core";
import {ModalService} from "../../../shared/modal/modal.service";
import {FormGroup, Validators} from "@angular/forms";
import {ValidateEmailTrim} from "../../../shared/validators/validate.email.trim";

@Component({
  templateUrl: './candidate.modal.template.html'
})

export class CandidateModalComponent extends ModalService implements IModalService {

  form: FormGroup;

  ngOnInit() {
    this.form = this.fb.group({
      'email': [null, [ValidateEmailTrim.bind(this)]],
      'firstName': [null, [Validators.required, Validators.minLength(2)]],
      'surName': [null, [Validators.required, Validators.minLength(2)]]
    })
  }

  save() {
    if (this.form.valid) {
      this.data['email'] = this.data['email'].trim().toLowerCase();
      super.notify(this.data);
      super.close();
    }
  }

}
