import {Component} from "@angular/core";
import {ModalService} from "../../../shared/modal/modal.service";

@Component({
  templateUrl: './battery.details.modal.template.html'
})

export class BatteryDetailsModalComponent extends ModalService implements IModalService {

  close() {
    super.notify(this.data);
    super.close();
  }
}
