import {Component, Input} from '@angular/core';
import {DownloadService} from "../../service/download.service";
import {ServiceLocator} from "../../../shared/service/native/service.locator";
import {BusinessBaseComponent} from "../business.base.component";

@Component({
  selector: 'battery-reports',
  templateUrl: 'battery.reports.html'
})

export class BatteryReports extends BusinessBaseComponent {

  @Input() readOnly: boolean;
  _session: any;
  testProfiles = {};
  memberTests = {}; //TestJson.java

  ds: DownloadService = ServiceLocator.injector.get(DownloadService);

  @Input() set session(val: any) {
    if (val) {
      this._session = val;
      this.setTestProfiles();
    }
  }

  setTestProfiles() {

    this.loading = true;

    this.testProfiles = {};
    this.memberTests = {};

    if (this._session.tests) {
      for (let test1 of this._session.tests) {
        this.memberTests[test1.testType] = test1;
      }
    }
    if (this._session.battery && this._session.battery.tests) {
      for (let test of this._session.battery.tests) {
        let testType = test.testType;
        let mt = this.memberTests[testType];
        let tp = {profile: test, memberTest: (mt ? mt : null), ngclass: ''};
        if (mt) {
          let sc = mt.statusCode; // see MemberInvite.java->INVITE_STATUS_*
          tp.ngclass = sc;
        }

        if (!this.cs.hasPrefHideTest(testType)) {
          this.testProfiles[testType] = tp;
        }

      }
    }

    this.loading = false;

  }

}
