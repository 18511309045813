export class CandidateItemCard {
  k?: string; //Key

  d?: string; // Title

  so?: number; //sort order

  makeCopy() {
    return (new CandidateItemCard(this.k, this.d, this.so));
  }

  constructor(k: string, d?: string, so?: number) {
    this.k = k;
    this.d = d;
    this.so = so;
  }
}
