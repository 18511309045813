import {Component, Input} from '@angular/core';
import {Subscription} from 'rxjs';
import {BusinessBaseComponent} from "../business.base.component";
import {Battery} from "../../model/battery.model";
import {ModalConfirmComponent} from "../../../shared/modal/modal.confirm";
import {Session} from "../../../shared/model/session.model";

@Component({
  selector: 'invite-details',
  templateUrl: 'invite.details.html'
})

export class InviteDetailsComponent extends BusinessBaseComponent {

  _battery: Battery;
  _session: Session = null;

  inviteAction: any;
  inviteActionResult: any;
  isDebug: boolean = false;
  inviteKey: string;
  apiSubscription: Subscription;

  @Input() set invite(val) {
    if (this.apiSubscription) {
      this.loading = false;
      this.apiSubscription.unsubscribe();
    }
    if (val) {
      this.inviteKey = val.i.key;
      this.getInviteDetails();
    } else {
      this._session = null;
    }
  }

  @Input() set battery(val) {
    this._battery = val;
  }

  sendReminder() {
    this.modal.openModal(ModalConfirmComponent, {
      title: 'Confirm',
      yes: 'Continue',
      message: `We will resend the invitation to ${this._session.cand.name}. Continue ?`
    }, {}).subscribe(result => {
      if (result.data == true) {
        this.api.batteryAction("resendinvite", {
          candidateKey: this._session.cand.key,
          companyKey: this.cs.company.companyKey,
          batteryId: this._battery.id
        }).subscribe((result) => {
          alert(result.detailMessage);
        });
      }
    });

  }

  setInviteAction() {
    this.loading = true;
    this.api.inviteAction(this._session.inv.key, this.inviteAction.key).subscribe((result) => {
      this.inviteActionResult = result.responseObject;
      this.loading = false;
    });
  }

  getInviteDetails() {
    this.loading = true;
    this.apiSubscription = this.api.batteryAction("session", {
      key: this.inviteKey,
      id: this._battery.id
    }).subscribe((result) => {
      this._session = result.responseObject;

      this._session.tests.sort((t1, t2) => {
        let k1 = t1.inviteStatusCode + t1.statusCode;
        let k2 = t2.inviteStatusCode + t2.statusCode;
        return (k1 < k2 ? -1 : 1);
      });

      this.loading = false;
      this.detectChanges();
    });
  }

  ngOnInit() {
    this.loading = false;
  }

}
