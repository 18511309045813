import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiBase} from "../../shared/api/api.base.service";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class AdminApiResource extends ApiBase {

  callClientAPiEndpoint(apiKey: string, action: string, payload: any): Observable<any> {
    return this.post(this.apiUrl + "external/client/v1/" + apiKey + '/' + action, payload);
  }

  getLog(filter: any): Observable<any> {
    return this.get(this.cmdurl, {command: 'getlog', filer: filter});
  }

  searchMember(payload: any): Observable<any> {
    return this.post(this.adminApi + "action/searchMember/", payload);
  }

  getMember(id: string): Observable<any> {
    return this.get(this.url + "member/" + id, {});
  }

  getJob(id: string): Observable<any> {
    return this.get(this.url + "job/:id", {id: id});
  }

  getTest(id: number, testType: string): Observable<any> {
    return this.get(this.url + "test/" + id, {type: testType});
  }

  getResults(id: string): Observable<any> {
    return this.get(this.url + "results/:id", {id: id});
  }

  getSession(inviteId: string): Observable<any> {
    return this.get(this.url + "session/" + inviteId, {});
  }

  commandWithPayload(cmd: any, payload: any): Observable<any> {
    return this.get(this.cmdurl, Object.assign({command: cmd, params: []}, payload));
  }

  command(cmd: any, params: any): Observable<any> {
    return this.get(this.cmdurl, {command: cmd, params: params});
  }

  getCompany(companyId: string): Observable<any> {
    return this.get(this.apiUrl + "companies/get/", {id: companyId});
  }

  getCompanies(): Observable<any> {
    return this.get(this.cmdurl, {command: 'allcompanies'});
  }

  getJobs(): Observable<any> {
    return this.get(this.cmdurl, {command: 'alljobs'});
  }

  getUsers(filterParams: any): Observable<any> {
    return this.get(this.cmdurl, {command: 'allusers', filterParams: filterParams});
  }

  getSessions(filterParams: any): Observable<any> {
    return this.get(this.cmdurl, {command: 'latestsessions', filterParams: filterParams});
  }

  getTests(filterParams: any): Observable<any> {
    return this.get(this.cmdurl, {command: 'alltests', filterParams: filterParams});
  }

  getInvites(filterParams: any): Observable<any> {
    return this.get(this.cmdurl, {command: 'allinvites', filterParams: filterParams});
  }

  getEmailLogs(): Observable<any> {
    return this.get(this.cmdurl, {command: 'allemaillogs'});
  }

  getEmailLog(logId: number): Observable<any> {
    return this.get(this.cmdurl, {command: 'emaillog', id: logId});
  }

  getSupportContactList(typeCodeFilter: string, statusCodeFilter: string): Observable<any> {
    return this.get(this.cmdurl, {
      command: 'supportfiltered',
      typeCodeFilter: typeCodeFilter,
      statusCodeFilter: statusCodeFilter
    });
  }

  resetAll(): Observable<any> {
    return this.get(this.cmdurl, {command: 'resettestdata'});
  }

  constructor(http: HttpClient) {
    super(http);
  }

}
