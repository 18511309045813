import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SharedApiResource} from "../../shared/api/api.shared.service";

@Injectable()
@Inject(HttpClient)
export class ProfileApiResource extends SharedApiResource {

  _getUserProfile(action: string): Observable<any> {
    return this.get(this.apiUrl + "session/prefs/" + action);
  }

  _saveUserProfile(section: string, payload: any): Observable<any> {
    return this.post(this.apiUrl + "session/prefs/" + section, payload);
  }

  getProfileImageUploadUrl(): Observable<any> {
    return new Observable(observer => {
      this.smallActionGet('upload-url', 'image').subscribe((result) => {
        observer.next(result.responseObject);
        observer.complete();
      })
    });
  }

  uploadProfileImage(file, uploadUrl) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("uploadFrom", 'angular');
    return this.postBlob(uploadUrl, formData);
  }

  resizeAndSaveProfileImage() {
    return new Observable(observer => {
      this.smallActionPost('resize', null, null).subscribe((result) => {
        observer.next(result.responseObject);
        observer.complete();
      })
    });
  }

  constructor(http: HttpClient) {
    super(http);
  }

}
