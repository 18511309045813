import {SessionService} from "./session/session.service";
import {Injectable} from "@angular/core";
import { HttpClient } from '@angular/common/http';
import {sprintf} from "sprintf-js";
import {TranslationResult} from "../model/translation.result.model";
import {AppConfig} from "../../admin/app.config";
import {map} from "rxjs/operators";

@Injectable()
export class TranslationService {

  debugShowTranslations = false;

  codeList = '';
  stringsCodeShared = 'TEST_ALL';
  stringsCodeWeb = 'SITE';
  translationFileData: any;

  public trans(transKey: string, currentText: string, transArg: any = null, web?: boolean): TranslationResult {
    let transFound = false;

    this.codeList = 'TEST_' + AppConfig.appCode;
    let lookupKey = '';
    if (web) {
      lookupKey = this.stringsCodeWeb + '-' + transKey;
    } else {
      lookupKey = this.codeList + '-' + transKey;
    }

    let translated = currentText;

    let lookup = this.sess.TRANS[lookupKey];
    if (!lookup) {
      lookup = this.sess.TRANS[this.stringsCodeShared + '-' + transKey];
    }

    if (lookup) {
      translated = lookup;
      transFound = true;
    }

    let tooltip = `[${transKey}] ${currentText}`;
    let cssClass = transFound ? 'trans-green' : 'trans-red';

    if (transArg) {
      translated = sprintf(translated, transArg);
    }

    if (this.debugShowTranslations) {
      translated = `<span class="${cssClass}" title="${tooltip}">${translated}</span>`
    }
    // else {
    //   translated = `<span>${translated}</span>`
    // }

    return new TranslationResult(transFound, translated, tooltip);

  }

  public update() {

  }

  // Translations 3
  getInnerHTMLFromHTML(html: string): string {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(html, 'text/html');
    const element = parsedHtml.documentElement.querySelector('span');

    if (element) {
      return element.innerHTML;
    }

    return '';
  }

  transByKey(key: string, translationFile: any) {
    return translationFile ? translationFile[key] : '';
  }

  getTranslationFile() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let lang = urlParams.get('lang');
    if (lang === undefined || lang === null || lang === 'null'){
      lang = 'ENG';
    }
    const filePath = AppConfig.links.appUrl + '/landing/assets/landing/i18n/' + lang + '.json';
    return this.http.get(filePath).pipe(
      map((data: any) => data)
    );
  }

  constructor(public sess: SessionService, private http: HttpClient) {

  }

}
