import {Component} from "@angular/core";
import {BusinessBaseComponent} from "./comp/business.base.component";
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: 'business.component.html',
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      state('*', style({
        opacity: 0.5
      })),
      transition('void <=> *', animate('200ms')),
    ])
  ]
})

export class BusinessComponent extends BusinessBaseComponent {

  greyOut: boolean = false;

  setGreyOut(event) {
    this.greyOut = event;
  }

  ngOnInit() {
    this.titleService.setTitle("Cliquidity Business Area");
  }

}
