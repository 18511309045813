import {Component, Input} from '@angular/core';
import {GenericList} from "../../model/list.generic.model";
import {ModalConfirmComponent} from "../../../shared/modal/modal.confirm";
import {BusinessBaseComponent} from "../business.base.component";
import {MemberBasicJson} from "../../../shared/model/memberinvitesextendedjson.model";
import {Group} from "../../../shared/model/group.model";

@Component({
  selector: 'group-candidates',
  templateUrl: 'group.candidates.html'
})

export class GroupCandidatesComponent extends BusinessBaseComponent {

  _group: Group;
  candidates: GenericList<MemberBasicJson> = new GenericList<MemberBasicJson>();

  @Input() set group(val: Group) {
    this.onRowSelect.emit(null);
    if (val) {
      this._group = val;
      this.getCandidates();
    }
  }

  ngOnInit() {
    this.cs.OnChange().subscribe((company) => {
      this._group = new Group()  //load favorites by default, see RestGroups.java
      this.getCandidates();
    });
  }

  removeCandidate(candidate: MemberBasicJson) {
    this.modal.openModal(ModalConfirmComponent, {
      yes: 'Proceed',
      message: 'Remove candidate ?'
    }, {}).subscribe(result => {
      this.api.groupAction("removecand", {
        companyKey: this.cs.company.companyKey,
        group: JSON.stringify(this._group || {}),
        candidateId: candidate.id
      }).subscribe((result) => {
        this.candidates.remove(candidate);
      });
    });
  }

  getCandidates() {
    if (this.cs.company) {
      this.loading = true;
      this.api.groupAction("listcand", {
        companyKey: this.cs.company.companyKey,
        group: JSON.stringify(this._group)
      }).subscribe((result) => {
        this.candidates.clear();
        this.candidates.set(<Array<MemberBasicJson>>result.responseObject);
        this.loading = false;
      });
    }
  }

}
