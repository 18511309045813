import {GenericList} from "../../business/model/list.generic.model";
import {CandidateItemCard} from "./candidate.itemcard.model";

export class CandidateEducation {

  code: string;               // Education code
  name?: string;              // Education name
  skill?: CandidateItemCard;  // Skill level for eg Degree
  institutionName?: string;   // Institution  name
  year: number;               // Year completed
  usage?: GenericList<CandidateItemCard>;

  makeCopy() {
    return new CandidateEducation(this.code, this.name, this.skill, this.institutionName, this.year, this.usage.clone());
  }

  constructor(code: string, name: string, skill: CandidateItemCard, institutionName: string, year: number, usage: GenericList<CandidateItemCard>) {
    this.code = code;
    this.name = name;
    this.skill = skill;
    this.institutionName = institutionName;
    this.year = year;
    this.usage = usage;
  }
}
