import {Inject, Injectable} from "@angular/core";
import {ApiBase} from "../../shared/api/api.base.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
@Inject(HttpClient)
export class InvitesApiResource extends ApiBase {

  getInvite(inviteKey: string): Observable<any> {
    return this.get(this.apiUrl + "session/invite/get/" + inviteKey, {});
  }

  dbgaction(action: string, payload: any): Observable<any> {
    return this.get(this.apiUrl + "session/invite/dbg/" + action, payload);
  }

  _updateInvite(key: string, action: string): Observable<any> {
    return this.post(this.apiUrl + "session/invite/update/internal/" + key + '/' + action, {});
  }

  // see InviteLogicImpl.getInviteList
  _getInvitesExtended(listType: string, inviteType: string, typeKey: string): Observable<any> {
    let p1 = '';
    let p2 = '';
    if (listType) {
      p1 = '/' + listType;
    }
    if (typeKey) {
      p2 = '/' + typeKey;
    }
    let p3 = '?invitetype=' + inviteType;
    return this.get(this.apiUrl + "session/invite/list" + p1 + p2 + p3);
  }

  constructor(http: HttpClient) {
    super(http);
  }

}
