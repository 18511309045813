import {Observable} from "rxjs";
import {EventEmitter} from '@angular/core';
import {SelectItem} from "./select.item.model";
import {SelectProvider} from "../service/select.provider";


export class Select {

  categoryCode: string = '';

  codeList: string = '';                    // must match up with Construct.java->code

  description?: string = '';

  related?: any;                            // see BatteryScorer#batteryScoreCodes

  selectedItems?: SelectItem[];             // picked dropdown items, for angular use, to used on backend

  defaultSelectItem?: SelectItem;           // if defined, pre-select this item

  provider?: Observable<any>;               // provider for dropdown items

  itemsProvider?: SelectProvider;           // provider for dropdown items

  clearSelected = new EventEmitter();       // we can emit this to ask multi-select to clear its selected items

  setSelectedItems = new EventEmitter();    // we can emit this to select specific items using an string array of item codes

  constructor(categoryCode: string, codeList: string, description: string, related: any, defaultSelectItem, provider: Observable<any>, itemsProvider: SelectProvider) {
    this.categoryCode = categoryCode;
    this.codeList = codeList;
    this.description = description;
    this.related = related;
    this.provider = provider;
    this.itemsProvider = itemsProvider;
    this.defaultSelectItem = defaultSelectItem;
    this.selectedItems = new Array<SelectItem>();
  }

}
