import {Component} from "@angular/core";
import {FormGroup, Validators} from "@angular/forms";
import {BusinessBaseComponent} from "../business.base.component";
import {Company} from "../../../shared/model/company.model";

@Component({
  selector: 'company-profile',
  templateUrl: 'company.profile.html'
})

export class CompanyProfileComponent extends BusinessBaseComponent {

  company: Company = null;
  form: FormGroup;
  saved: boolean = false;

  save() {
    if (this.form.valid) {
      this.api.updateCompany({company: JSON.stringify(this.company)}).subscribe((result) => {
        this.cs.companies[this.cs.selectedIndex].companyName = result.responseObject.companyName;
        this.saved = true;
      })
    }
  }

  ngOnInit() {
    this.company = new Company().setFromNative(this.cs.company);
    this.cs.OnChange().subscribe((company) => {
      this.company = new Company().setFromNative(company);
    });

    this.form = this.fb.group({
      'name': [null, [Validators.required, Validators.minLength(3)]]
    })
  }

}
