import {Component, Input} from '@angular/core';
import {BusinessBaseComponent} from "../business.base.component";

@Component({
  selector: 'battery-test-badge',
  template: `
      <span class="badge test-badge disabled">{{config.admin.testShortNames[testType]}}</span>`
})

export class BatteryTestBadgeComponent extends BusinessBaseComponent {
  @Input() testType: string;
}
