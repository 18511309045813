import {Component} from '@angular/core';
import {SearchData} from "../../model/search/search.data.model";
import {BusinessBaseComponent} from "../business.base.component";
import {Company} from "../../../shared/model/company.model";

@Component({
  selector: 'search-favorites',
  templateUrl: 'search.favorites.html'
})

export class SearchFavoritesComponent extends BusinessBaseComponent {

  _companySelected: Company;
  searched: boolean = false;    // true when a search was done
  resultsMap = {};              // BigQueryResult.java
  results = [];

  GROUP_NAME_FAVORITES = "Favorites";

  clearSearch() {
    this.clearSearchResults();
  }

  clearSearchResults() {
    this.searched = false;
    this.results = [];
  }

  setResults(resultsMap: any) {
    this.resultsMap = resultsMap;
    for (let entryKey in this.resultsMap) {
      let entryVal = this.resultsMap[entryKey];
      this.results.push({
        key: entryKey,
        value: entryVal
      });
    }
  }

  getFavorites() {
    this.loading = true;
    this.clearSearchResults();
    let searchData: SearchData = new SearchData()
    searchData.companyKey = this.cs.company.companyKey;
    searchData.groupKey = this.GROUP_NAME_FAVORITES;
    this.api.batterySearch(searchData).subscribe((result) => {
      if (!this.isEmptyObject(result.responseObject.results)) {
        this.setResults(result.responseObject.results);
      }
      this.searched = true;
      this.loading = false;
    });
  }

  get hasResults() {
    return (this.results.length > 0);
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }


  ngOnInit() {
    this.loading = false;
    this.cs.OnChange().subscribe((company) => {
      if (this._companySelected && this._companySelected.companyId == company.companyId) {
        return;
      }
      this._companySelected = company;
      this.getFavorites();
    });
  }

}
