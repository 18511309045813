import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Candidate} from "../../model/candidate.model";
import {CandidateModalComponent} from "../candidate/candidate.modal.component";
import {Battery} from "../../model/battery.model";
import {ServiceLocator} from "../../../shared/service/native/service.locator";
import {BusinessBaseComponent} from "../business.base.component";
import {PapaParseService} from 'ngx-papaparse';
import {ValidationResult} from "../../../shared/service/validation/validation.result.model";
import {BusinessApiResource} from "../../api/api.business.service";
import {ApiResult} from "../../../shared/model/api.result.model";
import {ColumnMode} from '@swimlane/ngx-datatable';

@Component({
  selector: 'battery-candidates',
  templateUrl: 'battery.candidates.html'
})

export class BatteryCandidatesComponent extends BusinessBaseComponent {

  @Input() battery: Battery;
  @Input() existingBattery: Battery;
  @Output() onValidate: EventEmitter<ValidationResult> = new EventEmitter<ValidationResult>();
  candidates: Candidate[];
  papa: PapaParseService = ServiceLocator.injector.get(PapaParseService);
  candidateValidator: ValidationResult = new ValidationResult().setValid();
  bapi: BusinessApiResource = this.injector.get(BusinessApiResource);
  validating: boolean = false;
  importing: boolean = false;
  invalidRowsMap;                 // see TaskResult.java->results
  ColumnMode = ColumnMode;
  validationMessage: string = null;

  removeCandidate(candidate: Candidate) {
    this.battery.candidates.remove(candidate);
    this.setCandidates();
    this.validateCandidates();
    this.onValidate.emit(this.candidateValidator);
  }

  editCandidate(row) {
    this.modal.openModal(CandidateModalComponent, row).subscribe(result => {
      this.validateCandidates();
      this.onValidate.emit(this.candidateValidator);
    });
  }

  addCandidate() {
    let newCandidate: Candidate = {
      rank: this.battery.candidates.length + 1,
      id: new Date().valueOf(),
      firstName: '',
      surName: '',
      email: ''
    };
    this.addCandidateModal(newCandidate);
  }

  clearCandidates() {
    this.battery.candidates.clear();
    this.setCandidates();
    this.validateCandidates();
    this.onValidate.emit(this.candidateValidator);
  }

  public addCandidateModal(newCandidate: Candidate) {
    this.modal.openModal(CandidateModalComponent, newCandidate).subscribe(result => {
      this.battery.candidates.add(result.data);
      this.detectChanges();
      this.updateRanks();
      this.setCandidates();
      this.validateCandidates();
      this.onValidate.emit(this.candidateValidator);
    });
  }

  fileChangeListener($event): void {
    this.readCsvFile($event.target);
  }

  readCsvFile(inputValue: any): void {
    this.importing = true;
    let file: File = inputValue.files[0];
    let reader: FileReader = new FileReader();

    reader.onloadend = (e) => {
      let csvData = reader.result.toString();
      this.papa.parse(csvData, {
        header: false,
        delimiter: ",",
        newline: "\r\n",
        complete: (results, file) => {
          for (let i = 0; i < results.data.length - 1; i++) {
            let row = results.data[i];
            let newCandidate: Candidate = {
              rank: 0,
              firstName: row[0].trim(),
              surName: row[1].trim(),
              email: row[2].trim().toLowerCase()
            };
            this.battery.candidates.add(newCandidate);
          }
          this.updateRanks();
          this.validateCandidates();
          this.importing = false;
          this.onValidate.emit(this.candidateValidator);
        }
      });

    };

    reader.readAsText(file);

  }

  updateRanks() {
    for (let i = 0; i < this.battery.candidates.length; i++) {
      this.battery.candidates[i].rank = i + 1;
    }
  }

  setCandidates() {
    this.candidates = this.battery.candidates;
    this.candidates = [...this.candidates];
  }

  validateCandidates(): boolean {

    this.validating = true;

    this.battery.candidates.forEach(c => {
      c.validation = null;
    })

    this.candidateValidator.setValid();
    let batteryJson = JSON.stringify(this.battery);
    batteryJson = batteryJson.replace(/\+/g, "%2B");
    let payload = {
      battery: JSON.parse(batteryJson),
      batteryId: this.existingBattery ? this.existingBattery.id : 0
    };

    this.bapi.batteryPostAction('validateaddcandidates', payload).subscribe((result) => {
      let apiResult = new ApiResult(result);
      if (apiResult.invalid) {
        alert(apiResult.status);
      } else {
        this.invalidRowsMap = apiResult.responseObject['results']
        if (this.invalidRowsMap['0']) {
          //no candidates to import, see BatteryLogicImpl->validateAddCandidatesToBattery
          this.invalidRowsMap = {};
          this.candidateValidator.setInvalid('');
          this.validationMessage = `Add at least one candidate.`;
        } else {
          Object.keys(this.invalidRowsMap).map(key => {
            this.battery.candidates[(Number(key) - 1)].validation = this.invalidRowsMap[key];
          });
        }
        this.setCandidates();
        if (Object.keys(this.invalidRowsMap).length > 0) {
          this.candidateValidator.setInvalid('');
          this.validationMessage = `We found some issues with ${this.invalidRowsCount} rows during the import, please fix them above as indicated in red. If you have too many validation issues, it might be better to first fix them in the csv file.`;
        }
      }

      this.validating = false;

    });

    return this.candidateValidator.valid;
  }

  get invalidRowsCount(): number {
    return Object.keys(this.invalidRowsMap).length;
  }

  getCellClass({row, column, value}): any {
    return {
      'validation': column.prop === 'validation'
    };
  }

}
