import {Component} from '@angular/core';
import {Battery} from "../../model/battery.model";
import {AppConfig} from "../../../admin/app.config";
import {GenericList} from "../../model/list.generic.model";
import {Candidate} from "../../model/candidate.model";
import {ValidationResult} from "../../../shared/service/validation/validation.result.model";
import {BusinessBaseComponent} from "../business.base.component";
import {Company} from "../../../shared/model/company.model";

@Component({
  selector: 'battery-create',
  templateUrl: 'battery.create.html'
})

export class BatteryCreateComponent extends BusinessBaseComponent {

  appConfig = AppConfig;
  battery: Battery;
  company: Company;
  selectedMailerCode: string = 'INT';
  creating: boolean = false;
  created: boolean = false;
  isBatteryValid: boolean = false;
  testsValidator: ValidationResult = new ValidationResult().setInvalid();
  candidateValidator: ValidationResult = new ValidationResult().setInvalid();

  ngOnInit() {
    this.loading = true;
    this.company = this.cs.company;
    this.cs.OnChange().subscribe((company) => {
      this.company = this.cs.company;
    });
    this.newBattery();
  }

  newBattery() {
    this.api.batteryAction("new", {}).subscribe((result) => {
      let _battery = result.responseObject;
      _battery.inviteMessage = '';
      this.battery = _battery;
      this.battery.candidates = new GenericList<Candidate>();
      this.loading = false;
    });
  }

  validateTests(): boolean {
    this.testsValidator.setValid();
    let selected = this.battery.tests.filter(test => test.selected == true).length;
    this.testsValidator.setValidIf((selected > 0), "Select at least one test");
    return this.testsValidator.valid;
  }

  createBattery(event: any) {
    this.creating = true;
    this.battery.companyId = this.company.companyId;
    this.battery.useExternalMailer = (this.selectedMailerCode == 'EXT');
    if (this.validateTests()) {
      let batteryJson = JSON.stringify(this.battery);
      batteryJson = batteryJson.replace(/\+/g, "%2B").replace(/\&/g, "%26").replace(/\@/g, "%40");
      let payload = {
        battery: JSON.parse(batteryJson),
        companyKey: this.cs.company.companyKey
      };
      this.api.batterySave(payload).subscribe((result) => {
        this.creating = false;
        this.created = true;
      })
    }
  }

  onValidate(event) {
    this.candidateValidator = event;
  }

  redirectProjects() {
    this.router.navigate(['projects']);
  }

}
