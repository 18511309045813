import {AbstractControl} from '@angular/forms';

export function ValidateEmailTrim(control: AbstractControl) {
  if (control.value) {
    let result = ValidateEmailStringTrim(control.value);
    return result;
  }
}

/**
 * Basic Validation. Corresponds with javascript validator in UtilityImpl.java->isValidEmailAddressBasic
 * @param email
 * @constructor
 */
export function ValidateEmailStringTrim(email: string): any {
  email = email.trim();

  let invalidEmail: boolean = (email.match(new RegExp('^[^@]+@[^\.@]([^@]+\\.[^\.@]+)+$')) || []).length == 0;
  let hasSpaces: boolean = (email.match(new RegExp('[^\\s]+\\s+[^\\s]+')) || []).length > 0;
  if (!hasSpaces && !invalidEmail) return null;
  if (hasSpaces) return {space: true};
  if (invalidEmail) return {email: true};

}
