/**
 * See BatterySummary.java
 */
export class BatterySummary {

  testCount: number;

  candidates: number;

  pending: number;

  accepted: number;

  declined: number;

  inProgress: number;

  completed: number;

  testsCompleted: number;

  chem1: number;

  chem2: number;

  vi: number;

  rp: number;

  cara: number;

  nr: number;

  //percentages
  get p(): any {
    return {
      pending: Math.round((this.candidates > 0 ? this.pending / this.candidates * 100 : 0)),
      accepted: Math.round((this.candidates > 0 ? this.accepted / this.candidates * 100 : 0)),
      declined: Math.round((this.candidates > 0 ? this.declined / this.candidates * 100 : 0)),
      inProgress: Math.round((this.candidates > 0 ? this.inProgress / this.candidates * 100 : 0)),
      completed: Math.round((this.candidates > 0 ? this.completed / this.candidates * 100 : 0)),
      testsCompleted: Math.round((this.candidates > 0 ? this.testsCompleted / this.candidates * 100 : 0)),

      chem1: Math.round((this.candidates > 0 ? this.chem1 / this.candidates * 100 : 0)),
      chem2: Math.round((this.candidates > 0 ? this.chem2 / this.candidates * 100 : 0)),
      vi: Math.round((this.candidates > 0 ? this.vi / this.candidates * 100 : 0)),
      rp: Math.round((this.candidates > 0 ? this.rp / this.candidates * 100 : 0)),
      cara: Math.round((this.candidates > 0 ? this.cara / this.candidates * 100 : 0)),
      nr: Math.round((this.candidates > 0 ? this.nr / this.candidates * 100 : 0))
    }
  }

  setFromNative(from: any) {
    if (from) {
      this.candidates = from.candidates;
      this.pending = from.pending;
      this.accepted = from.accepted;
      this.declined = from.declined;
      this.completed = from.completed;
      this.inProgress = from.inProgress;
      this.testsCompleted = from.testsCompleted;
      this.chem1 = from.chem1;
      this.chem2 = from.chem2;
      this.vi = from.vi;
      this.rp = from.rp;
      this.cara = from.cara;
      this.nr = from.nr;

    }
    return this;
  }

}
