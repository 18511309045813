import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'modal-template',
  templateUrl: 'modal.template.html'
})

export class ModalTemplateComponent {

  @Input() title: string;
  @Input() disableClose: boolean = false;
  @Input() className: string;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  close() {
    this.onClose.emit();
  }
}
