import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'form-group',
  templateUrl: 'formgroup.template.html'
})

export class FormGroupComponent {

  @Input() form: FormGroup;
  @Input() label: string;
  @Input() name: string;
  @Input() class: string;
  @Input() inputMask: string = '.*';
  @Input() isReadonly: boolean = false;
  @Input() setFocus: boolean = false;
  @Output() modelChange = new EventEmitter();

  isRequired: boolean = false;
  hasModel: boolean = false;
  modelValue: any;
  link: string;
  show: boolean = false;

  ngOnInit() {
    this.show = true;
  }

  @Input() get model() {
    return this.modelValue;
  }

  set model(val) {
    this.modelValue = val;
    this.hasModel = true;
    this.modelChange.emit(this.modelValue);
  }

  //when type='label'
  @Input() set value(value: string) {
    this.isReadonly = true;
    this.modelValue = value;
    this.hasModel = true;
  }

  //create a link
  @Input() set url(value: string) {
    this.isReadonly = true;
    this.link = value;
    this.hasModel = true;
  }

  constructor(private _ref: ElementRef) {
    let el = _ref.nativeElement;
    this.isRequired = el.hasAttribute('required');
  }
}
