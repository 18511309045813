import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ApiTestService} from "./api.test.service";

@Injectable()
export class VersionApiResource extends ApiTestService {


  constructor(http: HttpClient) {
    super(http);
  }

}
