import {Component} from "@angular/core";
import {ModalService} from "../../../shared/modal/modal.service";
import {BusinessApiResource} from "../../api/api.business.service";

@Component({
  templateUrl: './battery.embed.button.modal.template.html'
})

export class BatteryEmbedButtonModalComponent extends ModalService implements IModalService {

  //the full block of javascript plus html tags to be embedded on a website
  script: string = '';
  api: BusinessApiResource = this.injector.get(BusinessApiResource);

  ngOnInit() {
    this.api.batteryAction("getembedbuttonscript", {id: this.data.id}).subscribe((result) => {
      this.script = result.responseObject;
    });
  }

  copyToClipboard() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', this.script);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    alert('copied');
  }
}
