import {BusinessBaseComponent} from "../business.base.component";
import {Component, EventEmitter, ViewChild} from "@angular/core";
import {GenericList} from "../../model/list.generic.model";
import {Group} from "../../../shared/model/group.model";
import {SearchData} from "../../model/search/search.data.model";
import {SearchCriteriaComponent} from "../search/search.criteria.component";
import {Subject} from "rxjs";
import {debounceTime, switchMap} from "rxjs/operators";
import {TalentPoolListComponent} from "./talent.pool.list";
import {Company} from "../../../shared/model/company.model";
import {SearchDataAndCriteria} from "../../model/search/search.data.and.criteria.model";
import {SearchCriteria} from "../search/search.criteria";
import {SearchResultsComponent} from "../search/search.results.component";

@Component({
  selector: 'talent-pool-page',
  templateUrl: 'talent.pool.page.html'
})

export class TalentPageComponent extends BusinessBaseComponent {

  pools: GenericList<Group> = new GenericList<Group>();
  poolSelected: Group = null;
  companySelected: Company;
  candidatesResult = null;              // load pool candidates using BigQuerySqlImpl.searchBatteryScores received as BigQueryResult.java->results
  searchResult = null;                  // BigQueryResult.java->results
  sdc: SearchDataAndCriteria = null;    // results when search button was clicked
  sdc2: SearchDataAndCriteria = null;
  searching: boolean;
  loadingPools: boolean;
  loadingCandidates: boolean;
  candidatesLoaded: boolean;
  refreshGrid = new EventEmitter();

  onAddedToPool = new EventEmitter();
  criteriaChanged: Subject<Group> = new Subject<Group>();
  loadCandidatesSubject = new Subject<any>();

  @ViewChild('sr2') srComponent: SearchResultsComponent;
  @ViewChild('poolsComponent') poolsComponent: TalentPoolListComponent;
  @ViewChild('scComponent') scComponent: SearchCriteriaComponent;

  onCriteriaChange(searchData: SearchData) {
    this.poolSelected.searchCriteria = searchData;
    this.criteriaChanged.next(this.poolSelected);
  }

  onPoolSelect(group: Group) {
    let findPool = this.pools.filter(p => p.id == group.id);
    this.poolSelected = findPool[0];
    this.searchResult = null;
    this.sdc = null;
    this.candidatesResult = null;
    if (!this.poolSelected.searchCriteria) {
      this.poolSelected.searchCriteria = new SearchData();
    }
    this.populateCriteria(this.poolSelected.searchCriteria);
    this.loadCandidates(true);

  }

  search(sdc: SearchDataAndCriteria) {
    this.sdc = sdc;
    let sd = sdc.searchData;
    let sc = sdc.searchCriteria;
    this.loading = true;
    this.searching = true;
    this.loading = true;
    this.api.batterySearch(sd).subscribe((result) => {
      let searchResult = result.responseObject.results;
      this.filterSearchResult(searchResult);
      this.searchResult = searchResult;
      this.loading = false;
      this.searching = false;
    });
  }

  //remove candidates form search result that are already in pool
  filterSearchResult(searchResult: any) {

    let poolIds = [];
    if (this.candidatesResult) {
      // string[] of candidate ids
      poolIds = Object.keys(this.candidatesResult);
    }

    Object.keys(searchResult).forEach((id) => {
      if (poolIds.indexOf(id.toString()) >= 0) {
        delete searchResult[id];
      }
    });

  }

  clearSearch() {
    this.poolSelected.searchCriteria = new SearchData();
    this.criteriaChanged.next(this.poolSelected);
    this.searchResult = null;
  }

  loadCandidates(useCache: boolean) {
    this.loadCandidatesSubject.next(useCache);
  }

  loadPools(selectedPool: Group) {
    this.loadingPools = true;
    this.poolSelected = null;
    this.candidatesResult = null;
    this.searchResult = null;
    this.api.batteryAction("listtalentpools", {companyKey: this.companySelected.companyKey}).subscribe((result) => {
      this.pools = new GenericList<Group>();
      for (let i = 0; i < result.responseObject.length; i++) {
        this.pools.add(new Group().fromOther(result.responseObject[i]));
      }
      if (selectedPool) {
        this.selectPoolRow(selectedPool.id);
      } else {
        this.poolSelected = null;
      }
      this.loadingPools = false;
      this.detectChanges();
    });
  }

  savePool(group: Group) {
    this.api.batteryPostAction("upserttalentpool", group).subscribe((result) => {
    });
  }

  populateCriteria(searchData: SearchData) {
    this.scComponent.populate(searchData);
  }

  addCandidatesToPool(selectedRows: any[]) {
    let candidateIds = selectedRows.map(function (item) {
      return item['member_id'];
    });

    this.api.batteryPostAction("addcandidatestalentpool", {
      groupId: this.poolSelected.id,
      candidateIds: candidateIds
    }).subscribe((result) => {
      this.loadCandidates(false);
      this.onAddedToPool.emit();
    })
  }

  removeCandidatesFromPool(selectedRows: any[]) {
    let candidateIds = selectedRows.map(function (item) {
      return item['member_id'];
    });

    this.api.batteryPostAction("removecandidatestalentpool", {
      groupId: this.poolSelected.id,
      candidateIds: candidateIds
    }).subscribe((result) => {
      this.loadCandidates(false);
    })
  }

  removeCandidateFromPool(item: any) {
    delete this.candidatesResult[Number(item.member_id)];
    this.api.batteryAction("removecandidatetalentpool", {
      groupId: this.poolSelected.id,
      candidateId: item.member_id
    }).subscribe((result) => {
    })
    this.candidatesResult = [...this.candidatesResult];
  }

  get hasPoolSelected(): boolean {
    return (this.poolSelected != null);
  }

  get hasCandidateResults(): boolean {
    return (this.candidatesResult != null);
  }

  get hasCandidates(): boolean {
    return (this.candidatesResult != null && Object.keys(this.candidatesResult).length > 0);
  }

  selectPoolRow(groupId: number) {
    let prevSelectedRows = this.pools.filter(p => p.id == groupId);
    if (prevSelectedRows) {
      this.poolsComponent.rowsSelected = prevSelectedRows;
      this.onPoolSelect(prevSelectedRows[0]);
    }
  }

  setDebounceCriteriaChange() {
    this.criteriaChanged.pipe(
      debounceTime(1000))
      .subscribe(pool => {
          this.savePool(pool);
        }
      );
  }

  loadCandidatesDebounced() {
    this.refreshGrid.emit();
    this.loadCandidatesSubject.pipe(debounceTime(10),
      switchMap((useCache) => {
        this.loadingCandidates = true;
        this.candidatesLoaded = false;
        this.poolSelected.searchCriteria.groupId = this.poolSelected.id;
        let searchCriteria = this.poolSelected.searchCriteria;
        return this.api.batteryPostActionWithCache('listcandidatestalentpool', searchCriteria, useCache);

      })).subscribe(result => {

      this.candidatesResult = result.responseObject.results;

      let sc: SearchCriteria = new SearchCriteria(this.api, this.cs);
      sc.populateFromCriteria(this.poolSelected.searchCriteria);

      this.sdc2 = {searchData: this.candidatesResult, searchCriteria: sc};
      this.loadingCandidates = false;
      this.candidatesLoaded = true;

    });

  }

  preventToggle(event: any): boolean {
    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  ngOnInit() {
    this.loading = false;

    this.cs.OnChange().subscribe((company) => {
      if (this.companySelected && this.companySelected.companyId == company.companyId) {
        return;
      }
      this.companySelected = company;
      this.loadPools(null);
    });

    this.loadPools(null);
    this.setDebounceCriteriaChange();
    this.loadCandidatesDebounced();
  }

}
