import {Directive, ElementRef, Input, KeyValueDiffer, KeyValueDiffers, Renderer2} from "@angular/core";
import {SessionService} from "../service/session/session.service";
import {TranslationService} from "../service/translation.service";

/**
 * See ReportTranslationParser.java
 */
@Directive({
  selector: '[trans]'
})
export class TranslationDirective {
  transKey: string;
  transArg: string;       // sprintf-js argument
  currentText: string;
  transModel: any         // trigger a translation when this model changed

  private modelDiffer: KeyValueDiffer<any, any>;

  @Input('transModelChange')
  public set _transModelChange(val: any) {
    this.transModel = val;
    this.modelDiffer = this.differs.find(this.transModel).create();
  }

  @Input('transArg')
  public set _transArg(val: string) {
    this.transArg = val;
  }

  @Input('trans')
  public set _translate(val: string) {
    this.transKey = val;
  }

  ngAfterViewInit() {
    this.translate(this.transKey);
  }

  private translate(transKey: string) {
    if (!this.currentText) {
      this.currentText = this.el.nativeElement.innerHTML;
    }
    this.el.nativeElement.innerHTML = this.ts.trans(transKey, this.currentText, this.transArg).translated
  }

  ngDoCheck(): void {
    if (this.modelDiffer) {
      const modelChanged = this.modelDiffer.diff(this.transModel);
      if (modelChanged) {
        this.translate(this.transKey);
      }
    }

  }

  constructor(
    public sess: SessionService,
    public el: ElementRef,
    public rend: Renderer2,
    public ts: TranslationService,
    private differs: KeyValueDiffers) {
  }

}
